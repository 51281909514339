import React, { Component } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const StyledLink = styled.a`
  font-family: "Be Vietnam", sans-serif;
  font-size: 1.4rem;
  color: #00ab55;
  letter-spacing: 0.51px;
  cursor: pointer;
  transition: all 0.3s;
  padding: 1.2rem 2rem;
  border-radius: 0.8rem;
  width: 100%;
  display: block;

  &:hover {
    color: #027b55;
    background-color: ${({ bg }) => (bg ? "rgba(0, 171, 85, 0.1)" : "default")};
  }
`;

class SecondaryButton extends Component {
  render() {
    if (!this.props.bg) {
      return (
        <StyledLink
          onClick={function () {
            if (this.props.onClick) this.props.onClick();
          }.bind(this)}
          {...this.props}
        >
          {this.props.children}
        </StyledLink>
      );
    }

    return (
      <Button
        style={{
          padding: "0",
          textTransform: "capitalize",
          ...(this.props.style || {}),
        }}
      >
        <StyledLink
          onClick={function () {
            if (this.props.onClick) this.props.onClick();
          }.bind(this)}
          {...this.props}
        >
          {this.props.children}
        </StyledLink>
      </Button>
    );
  }
}

export default SecondaryButton;
