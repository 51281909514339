import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import DataCard from "../../components/DataCard";

const DataCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 15rem;
  height: 15rem;
`;

const Wrapper = styled.div`
  margin: 0 1rem 1rem 0;
  cursor: pointer;

  transition: transform 0.4s;

  &:hover {
    transform: translateY(-0.5rem);
  }
`;

const VendorCard = ({
  vendorID,
  totalAmount,
  totalTransactions,
  vendorUserID,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/ledgers/${vendorUserID}`);
  };

  return (
    <Wrapper onClick={handleClick}>
      <DataCard>
        <DataCardContainer>
          <h3
            style={{
              fontFamily: "Be Vietnam, sans-serif",
              fontSize: "1.5rem",
              color: "#00AB55",
              fontWeight: 300,
              textAlign: "center",
            }}
          >
            #{vendorID}
          </h3>
          <span
            style={{
              fontFamily: "Be Vietnam, sans-serif",
              fontSize: "3rem",
              color: "#333F4C",
              textAlign: "center",
              fontWeight: "600",
              opacity: "85%",
            }}
          >
            ₹{" "}
            {String(totalAmount)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
          </span>
          <h3
            style={{
              fontFamily: "Be Vietnam, sans-serif",
              fontSize: "1.5rem",
              color: "#212B36",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            {totalTransactions} transactions
          </h3>
        </DataCardContainer>
      </DataCard>
    </Wrapper>
  );
};

export default VendorCard;
