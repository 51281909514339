import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import Protect from "../auth";
import ContentWrapper from "../layout/ContentWrapper";

import {
  Dashboard,
  Login,
  NotFound404,
  CreditLines,
  CreditLine,
  ManageCreditLines,
  ManageCreditLine,
  Sidebar,
  Logout,
  Ledgers,
  VendorLedger,
  ShortlistedCreditLines,
} from "./components";
// import { messaging, saveToken } from "../server";
import { Snackbar, Alert, AlertTitle } from "@material-ui/core";

import "./routes.css";

const asPage = (component) => {
  return () => {
    return (
      <Protect>
        <Sidebar />
        <ContentWrapper>{component}</ContentWrapper>
      </Protect>
    );
  };
};

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationPermissionDenied: false,
      notifications: [],
    };
  }

  componentDidMount() {
    // messaging
    //   .requestPermission()
    //   .then(() => {
    //     return messaging.getToken();
    //   })
    //   .then(async (token) => {
    //     console.log(token);
    //     await saveToken(token);
    //   })
    //   .catch((e) => {
    //     this.setState({
    //       notificationPermissionDenied: true,
    //     });
    //     console.log("Permission Denied!");
    //   });
    // messaging.onMessage(
    //   function (payload) {
    //     this.setState((curState) => ({
    //       ...curState,
    //       notifications: [
    //         ...curState.notifications,
    //         {
    //           ...payload,
    //           notificationId: uuid(),
    //         },
    //       ],
    //     }));
    //   }.bind(this)
    // );
  }

  render() {
    return (
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="push-left"
              timeout={1000}
            >
              <>
                {this.state.notificationPermissionDenied && (
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={true}
                    autoHideDuration={1000}
                    message="NoPushNotification"
                    key="NoPushNotification"
                    id="NoPushNotification"
                    className="NoPushNotification"
                    style={{
                      fontFamily: "'Be Vietnam', sans-serif",
                    }}
                  >
                    <Alert
                      severity={"warning"}
                      onClose={function () {
                        this.setState({
                          notificationPermissionDenied: false,
                        });
                      }.bind(this)}
                    >
                      We cannot send you push notifications since permissions
                      were denied!
                    </Alert>
                  </Snackbar>
                )}
                {this.state.notifications.map((notification) => (
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={true}
                    autoHideDuration={1000}
                    message={notification.notification.title}
                    key={notification.notificationId}
                    style={{
                      fontFamily: "'Be Vietnam', sans-serif",
                    }}
                  >
                    <Alert
                      severity="info"
                      onClose={function () {
                        this.setState((curState) => {
                          let notIndex;
                          for (
                            let i = 0;
                            i < curState.notifications.length;
                            i++
                          ) {
                            console.log(curState.notifications[i]);
                            if (
                              curState.notifications[i].notificationId ===
                              notification.notificationId
                            )
                              notIndex = i;
                          }
                          const newNotifications = [...curState.notifications];
                          newNotifications.splice(notIndex, 1);
                          return {
                            ...curState,
                            notifications: newNotifications,
                          };
                        });
                      }.bind(this)}
                    >
                      <AlertTitle>{notification.notification.title}</AlertTitle>
                      {notification.notification.body}
                    </Alert>
                  </Snackbar>
                ))}
                <Switch>
                  <Route path="/login" exact component={() => <Login />} />
                  <Route path="/account/logout" exact component={Logout} />
                  <Route
                    path="/creditlines/:creditLineId"
                    exact
                    component={() => (
                      <div>
                        <CreditLine />
                      </div>
                    )}
                  />
                  <Route
                    path="/shortlisted/creditlines/"
                    exact
                    component={() => (
                      <div>
                        <ShortlistedCreditLines />
                      </div>
                    )}
                  />
                  <Route
                    path="/manage/creditlines/:creditLineId"
                    exact
                    component={() => (
                      <div>
                        <ManageCreditLine />
                      </div>
                    )}
                  />
                  <Route
                    path="/manage/creditlines"
                    exact
                    component={() => (
                      <div>
                        <ManageCreditLines />
                      </div>
                    )}
                  />
                  <Route
                    path="/creditlines"
                    exact
                    component={() => (
                      <div>
                        <CreditLines />
                      </div>
                    )}
                  />
                  <Route
                    path="/ledgers/:vendorID"
                    exact
                    component={() => {
                      const VendorPage = asPage(<VendorLedger />);
                      return (
                        <div>
                          <VendorPage />
                        </div>
                      );
                    }}
                  />
                  <Route
                    path="/ledgers"
                    exact
                    component={() => (
                      <div>
                        <Ledgers />
                      </div>
                    )}
                  />
                  <Route
                    path="/"
                    exact
                    component={() => (
                      <div>
                        <Dashboard />
                      </div>
                    )}
                  />
                  <Route
                    exact
                    component={() => (
                      <div>
                        <Dashboard />
                      </div>
                    )}
                  />
                </Switch>
              </>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    );
  }
}

export default Routes;
