import ReduxAction from "../ReduxAction";
import constructRequest from "../../api/constructRequest";
import constructUrl from "../../api/constructUrl";

export const setLoans = (loans = []) => ({
  type: "SET_LOANS",
  payload: loans,
});

export const removeLoanByIndex = (index) => ({
  type: "REMOVE_LOAN_BY_INDEX",
  payload: index,
});

export const removeLoanById = (id) => ({
  type: "REMOVE_LOAN_BY_ID",
  payload: id,
});

export const fetchLoans = (query = {}) => ({
  type: "FETCH_LOANS",
  payload: query,
});

export const setActiveLoan = (loan) => ({
  type: "SET_ACTIVE_LOAN",
  payload: loan,
});

export const fetchLoanDetails = (id) => ({
  type: "FETCH_LOAN_DETAILS",
  payload: id,
});

export const setStatistics = (statistics) => ({
  type: "SET_STATISTICS",
  payload: statistics,
});

export const fetchStatistics = () => ({
  type: "FETCH_STATISTICS",
});

export const setShortlistedLoans = (loans) => ({
  type: "SET_SHORTLISTED_LOANS",
  payload: loans,
});

export const setSentLoans = (loans) => ({
  type: "SET_SENT_LOANS",
  payload: loans,
});

export const setApprovedLoans = (loans) => ({
  type: "SET_APPROVED_LOANS",
  payload: loans,
});

export const moveToApproved = (id) => ({
  type: "MOVE_TO_APPROVED",
  payload: id,
});

export const moveToShortlisted = (id) => ({
  type: "MOVE_TO_SHORTLISTED",
  payload: id,
});

export const moveToRejected = (id) => ({
  type: "MOVE_TO_REJECTED",
  payload: id,
});

export const shortlistLoan = (id) => ({
  type: "SHORTLIST_LOAN",
  payload: id,
});

export const approveLoan = (id) => ({
  type: "APPROVE_LOAN",
  payload: id,
});

export const rejectLoan = (id) => ({
  type: "REJECT_LOAN",
  payload: id,
});

export const addApprovedLoan = (loan) => ({
  type: "ADD_APPROVED_LOAN",
  payload: loan,
});

export const addShortlistedLoan = (loan) => ({
  type: "ADD_SHORTLISTED_LOAN",
  payload: loan,
});

export const addLoan = (loan) => ({
  type: "ADD_LOAN",
  payload: loan,
});

export const showSuccessMessage = (message) => ({
  type: "SHOW_SUCCESS_MESSAGE",
  payload: message,
});

export const setActionSucceeded = (value) => ({
  type: "SET_ACTION_SUCCEEDED",
  payload: value,
});

export const sentLoan = (id, sentToBanks) => ({
  type: "SENT_LOAN",
  payload: { id, sentToBanks },
});

export const moveToSent = (id) => ({
  type: "MOVE_TO_SENT",
  payload: id,
});

export const addRemark = (id, remark) => ({
  type: "ADD_REMARK",
  payload: { id, remark },
});

export const updateDetail = (key, value) => ({
  type: "UPDATE_DETAIL",
  payload: { key, value },
});

export const updateActiveLoan = (loan) => ({
  type: "UPDATE_ACTIVE_LOAN",
  payload: loan,
});

class Loans extends ReduxAction {
  fetchLoans(query) {
    this.store.dispatch(fetchLoans(query));
  }

  fetchLoanDetails(id) {
    this.store.dispatch(fetchLoanDetails(id));
  }

  setLoans(loans) {
    this.store.dispatch(setLoans(loans));
  }

  removeLoanByIndex(index) {
    this.store.dispatch(removeLoanByIndex(index));
  }

  removeLoanById(id) {
    this.store.dispatch(removeLoanById(id));
  }

  setActiveLoan(id) {
    this.store.dispatch(setActiveLoan(id));
  }

  fetchStatistics() {
    this.store.dispatch(fetchStatistics());
  }

  shortlistLoan(id) {
    this.store.dispatch(shortlistLoan(id));
  }

  approveLoan(id) {
    this.store.dispatch(approveLoan(id));
  }

  rejectLoan(id) {
    this.store.dispatch(rejectLoan(id));
  }

  setActionSucceeded(value) {
    this.store.dispatch(setActionSucceeded(value));
  }

  sentLoan(id, sentToBanks) {
    this.store.dispatch(sentLoan(id, sentToBanks));
  }

  addRemark(id, remark) {
    this.store.dispatch(addRemark(id, remark));
  }

  updateDetail(key, value) {
    this.store.dispatch(updateDetail(key, value));
  }

  updateActiveLoan(loan) {
    this.store.dispatch(updateActiveLoan(loan));
  }
}

export default Loans;
