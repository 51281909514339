const downloadLink = (link, filename) => {
  const a = document.createElement("a");
  a.href = link;
  a.download = filename;
  a.target = "_blank";
  a.rel = "noopener";

  a.click();
};

export default downloadLink;
