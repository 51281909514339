import React, { Component } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  position: relative;
  background-color: ${({ primary }) => (primary ? "#C8FACD" : "#fff")};
  border-radius: 1.6rem;
  box-shadow: ${({ primary }) =>
    primary ? "none" : "rgba(217, 217, 217, 0.64) 0 2px 1.1rem"};
  grid-column: ${({ gridColumn }) => gridColumn};
  padding: 3.2rem 3.3rem;
  height: fit-content;

  & h3 {
    font-family: "Be Vietnam", sans-serif;
    color: #212b36;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.58px;
  }

  & .value-change-container {
    display: flex;
    align-items: center;

    margin-top: 1.3rem;
  }

  & .value-change-container > img {
    width: 2.4rem;
  }

  & .value-change-container > h4 {
    color: #00aa55;
    font-size: 0.9rem;
    font-family: "Be Vietnam", sans-serif;
    font-weight: 500;
    letter-spacing: 0.35px;

    margin-left: 1.5rem;
  }

  & .card-value {
    font-family: "Be Vietnam", sans-serif;
    font-size: 2.7rem;
    color: #212b36;
    font-weight: 500;
    letter-spacing: 0.93px;
    margin-top: 1.3rem;
  }
`;

class DataCard extends Component {
  render() {
    return (
      <StyledDiv
        {...this.props}
        className={`${this.props.className || ""} Data-Card`}
      >
        {this.props.title ? (
          <h3 style={{ textAlign: this.props.center ? "center" : "left" }}>
            {this.props.title}
          </h3>
        ) : (
          ""
        )}
        {this.props.increase ? (
          <div className="value-change-container">
            <img
              src={window.getImage("increase_icon.svg")}
              alt="Increase Icon"
            />
            <h4>+{this.props.increase}% / last month</h4>
          </div>
        ) : (
          ""
        )}
        {this.props.value ? (
          <h2
            className="card-value"
            style={{ textAlign: this.props.center ? "center" : "left" }}
          >
            {this.props.value}
          </h2>
        ) : (
          ""
        )}
        {this.props.children}
      </StyledDiv>
    );
  }
}

export default DataCard;
