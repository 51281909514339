import React, { Component, useEffect, useState } from "react";
import Scrollbars from "./Scrollbars";
import { useHistory } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  Autocomplete,
  TableFooter,
} from "@material-ui/core";
import algoliasearch from "algoliasearch";
import flattenObject from "../utils/flattenObject";

import "./DataTable.css";

const algoliaClient = algoliasearch(
  "BYZKE8SG4B",
  "d66051b43a915e1855ab07880e4972cc"
);
const algoliaIndex = algoliaClient.initIndex("villages");
algoliaIndex.setSettings({
  searchableAttributes: ["district"],
});

// ----------------------------------------------------------------------

function createData(name, phoneNumber, loanAmount) {
  return { name, phoneNumber, loanAmount };
}

const BASIC_TABLE = [
  createData("Aarush Yadav", "+91 7710000481", "200, 000"),
  createData("Krishna Ghosh", "+91 1234567890", "195, 000"),
  // createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  // createData("Eclair", 262, 16.0, 24, 6.0),
  // createData("Cupcake", 305, 3.7, 67, 4.3),
  // createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledInput = styled.input`
  padding: 1rem 2rem;
  outline: none;
`;

const useStyles = makeStyles({
  root: {},
});

const StyledTableCell = withStyles((theme) => {
  return {
    root: {
      borderBottom: "none !important",
      "&:nth-child(1)": {
        borderRadius: "0.7rem 0 0 0.7rem",
      },
      "&:last-child": {
        borderRadius: "0 0.7rem 0.7rem 0",
      },
    },
    head: {
      backgroundColor: "#F3F6F8",
      color: "#637381",
      fontFamily: "'Be Vietnam', sans-serif",
      fontSize: "1.1rem",
      letterSpacing: "0.42px",
      fontWeight: "400",
    },
    body: {
      fontSize: 14,
    },
  };
})(TableCell);

const StyledTableTD = withStyles((theme) => {
  return {
    root: {
      borderBottom: "1px dashed #eee !important",
      margin: "0 2rem !important",
    },
    head: {},
    body: {
      fontSize: "1.1rem",
      fontFamily: "'Be Vietnam', sans-serif",
      color: "#212B36",
      letterSpacing: "0.42px",
    },
  };
})(TableCell);

const StyledTableRow = withStyles((theme) => {
  return {
    root: {
      padding: "5rem !important",
      cursor: "pointer",
    },
  };
})(TableRow);

// ----------------------------------------------------------------------

export default function DataTable({
  tableData,
  onClick,
  loadMore,
  forwarder = false,
}) {
  const classes = useStyles();

  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");
  const [villageSearchQuery, setVillageSearchQuery] = useState("");
  const [villageHits, setVillageHits] = useState([]);
  const [districts, setDistricts] = useState({});
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [matchingLoans, setMatchingLoans] = useState([]);

  useEffect(() => {
    if (villageSearchQuery.length <= 2) {
      setVillageHits([]);
      setMatchingLoans(tableData);
    } else {
      const matchingLoans = tableData.filter((loan) => {
        if (!loan.village?.district) return false;

        return loan.village.district
          .toLowerCase()
          .includes(villageSearchQuery.toLowerCase());
      });
      setMatchingLoans(matchingLoans);
    }
  }, [tableData, villageSearchQuery]);

  useEffect(() => {
    if (searchQuery.length <= 2) {
      setMatchingLoans(tableData);
    } else {
      const matchingLoans = tableData.filter((loan) => {
        const flattenedLoan = flattenObject(loan);
        console.log(flattenedLoan);

        for (const value of Object.values(flattenedLoan)) {
          if (String(value).toLowerCase().includes(searchQuery.toLowerCase())) {
            return true;
          }
        }

        return false;
      });

      setMatchingLoans(matchingLoans);
    }
  }, [tableData, searchQuery]);

  tableData = tableData || [];

  const updateSearchQuery = (evt) => {
    setSearchQuery(evt.target.value);
  };

  return (
    <div className={classes.root} id="DataTable-container">
      <Scrollbars>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 0",
          }}
        >
          <TextField
            id="search"
            label="Search"
            variant="outlined"
            color="primary"
            className="Search"
            onChange={updateSearchQuery}
            value={searchQuery}
            classes={{
              root: "Search",
            }}
            type="text"
            style={{
              marginRight: 20,
            }}
            required
          />
          <Autocomplete
            multiple
            id="villageSearch"
            style={{
              width: "fit-content",
            }}
            options={villageHits}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            onChange={(_, value) => {
              setSelectedDistricts(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select District(s)"
                placeholder="District(s)"
                onChange={(event) => setVillageSearchQuery(event.target.value)}
                style={{
                  width: "400px",
                  // transform: "translateX(-50%)",
                }}
              />
            )}
          />
        </div>
        <TableContainer sx={{ width: "100%", mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
                <StyledTableCell>Village</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="right">Loan Amount (₹)</StyledTableCell>
                <StyledTableCell>Sent To</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matchingLoans.map((row) => {
                const nDaysAgo = Math.floor(
                  (new Date() - new Date(row.timestamp)) / 1000 / 60 / 60 / 24
                );

                return (
                  <StyledTableRow
                    key={row._id}
                    className={classes.hideLastBorder}
                    onClick={() => {
                      if (onClick) return onClick(row._id);
                      history.push(
                        `/creditlines/${row._id}${
                          row.status === "shortlisted"
                            ? "?shortlisted=true"
                            : ""
                        }`
                      );
                    }}
                    style={{
                      backgroundColor: forwarder
                        ? nDaysAgo < 2
                          ? "green"
                          : nDaysAgo < 4
                          ? "yellow"
                          : "red"
                        : "transparent",
                    }}
                  >
                    <StyledTableTD component="th" scope="row">
                      {row.farmerName}
                    </StyledTableTD>
                    <StyledTableTD>{row.user?.phone}</StyledTableTD>
                    <StyledTableTD>{row.village?.name}</StyledTableTD>
                    <StyledTableTD>
                      {nDaysAgo} (
                      {Math.floor(
                        (new Date() - new Date(row.timestamp)) /
                          1000 /
                          60 /
                          60 /
                          24
                      )}{" "}
                      days ago)
                    </StyledTableTD>
                    <StyledTableTD align="right">
                      {row.loanAmount
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ", ") || "NA"}
                    </StyledTableTD>
                    <StyledTableTD>
                      {row.sentTo?.length
                        ? row.sentTo.map((sent) => sent.name).join(", ")
                        : "NA"}
                    </StyledTableTD>
                  </StyledTableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell variant="footer">
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: loadMore ? "pointer" : "not-allowed",
                      fontFamily: '"Be Vietnam", sans-serif',
                      fontSize: "1.4rem",
                      color: "#00ab55",
                    }}
                    onClick={loadMore || (() => null)}
                    disabled={!loadMore}
                  >
                    Load More
                  </button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Scrollbars>
    </div>
  );
}

export function DataTableCustomisable({
  tableData,
  columns,
  onClick,
  allData = false,
}) {
  const classes = useStyles();

  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");

  tableData = tableData || [];

  const updateSearchQuery = (evt) => {
    setSearchQuery(evt.target.value);
  };

  return (
    <div className={classes.root} id="DataTable-container">
      <Scrollbars>
        <TextField
          id="search"
          label="Search"
          variant="outlined"
          color="primary"
          className="Search"
          onChange={updateSearchQuery}
          value={searchQuery}
          classes={{
            root: "Search",
          }}
          type="text"
          required
        />
        <TableContainer sx={{ width: "100%", mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <StyledTableCell key={i}>{column.label}</StyledTableCell>
                ))}
                {/* <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone Number</StyledTableCell>
                <StyledTableCell align="right">Loan Amount (₹)</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, i) => {
                if (allData) {
                  const values = [];
                  for (const column of columns) {
                    values.push(row[column.key]);
                  }
                  return values.map((value) => (
                    <StyledTableTD component="th" scope="row" key={row.key}>
                      {value}
                    </StyledTableTD>
                  ));
                }

                for (const [, value] of Object.entries(row)) {
                  if (
                    String(value)
                      ?.toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ) {
                    return (
                      <StyledTableRow
                        key={row.id || row.credit_line_number}
                        className={classes.hideLastBorder}
                        onClick={() =>
                          onClick(row.id || row.credit_line_number)
                        }
                      >
                        {/* {Object.entries(value).map((key, value) => (
                          <StyledTableTD component="th" scope="row">
                            {value}
                          </StyledTableTD>
                        ))} */}
                        {allData ? (
                          <StyledTableTD component="th" scope="row">
                            {value}
                          </StyledTableTD>
                        ) : (
                          <>
                            <StyledTableTD component="th" scope="row">
                              {row.user?.phone}
                            </StyledTableTD>
                            <StyledTableTD>
                              {row.balance
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ", ") || "NA"}
                            </StyledTableTD>
                            <StyledTableTD>
                              {new Date(
                                row.end_date?.seconds * 1000
                              )?.toDateString()}
                            </StyledTableTD>
                          </>
                        )}
                      </StyledTableRow>
                    );
                  }
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbars>
    </div>
  );
}
