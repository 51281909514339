import React from "react";
import {Icon} from "@iconify/react";
import arrowIosDownwardFill from "@iconify-icons/eva/arrow-ios-downward-fill";
import {makeStyles} from "@material-ui/core/styles";
import {
    Accordion as MaterialAccordian,
    Typography,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core";

import "./Accordion.css";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    heading: {},
}));

// ----------------------------------------------------------------------

function Accordion({accordions = []}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {accordions.map((accordion, index) => (
                <MaterialAccordian className="Accordion" key={accordion.value}>
                    <AccordionSummary
                        expandIcon={
                            <Icon icon={arrowIosDownwardFill} width={20} height={20}/>
                        }
                    >
                        <Typography variant="subtitle1">{accordion.heading}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{accordion.detail}</Typography>
                    </AccordionDetails>
                </MaterialAccordian>
            ))}
        </div>
    );
}

export default Accordion;
