const formatNumber = (number, options = { currency: true }) => {
  const strNumberReversed = String(number || "0")
    .split("")
    .reverse()
    .join("");
  let formattedNumber = "";

  // 300,000
  for (let i = 0; i < strNumberReversed.length; i++) {
    if (i === 3 || (i !== 1 && (i + 1) % 2 === 0)) {
      formattedNumber += ",";
    }

    formattedNumber += strNumberReversed[i];
  }

  return (
    (options?.currency ? "Rs. " : "") +
    formattedNumber.split("").reverse().join("")
  );
};

export default formatNumber;
