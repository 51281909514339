import React, { Component } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ cols }) => cols}, 1fr);
  grid-template-rows: repeat(${({ rows }) => rows}, min-content);
  grid-gap: ${({ gridGap }) => gridGap};
  width: 100%;
`;

class Grid extends Component {
  render() {
    return <StyledDiv {...this.props}>{this.props.children}</StyledDiv>;
  }
}

export default Grid;
