export const FIREBASE_API_KEY = "AIzaSyDRU0Q-Ii-ITRtI9jGkyR2D_N5foMpEoFA";
export const FIREBASE_APP_ID = "1:905326805668:web:d768d5f47dda4a9b7adc31";
export const FIREBASE_MESSAGING_SENDER_ID = "905326805668";

export const FIREBASE_CONFIG = {
  apiKey: FIREBASE_API_KEY,
  authDomain: "greensat-9087a.firebaseapp.com",
  databaseURL: "https://greensat-9087a.firebaseio.com",
  projectId: "greensat-9087a",
  storageBucket: "greensat-9087a.appspot.com",
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: "G-THR76WFHYC",
};
