import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loader from "@material-ui/core/CircularProgress";

import Protect from "../../../auth";

import { db } from "../../../server";

import Sidebar from "../../../layout/sidebar";
import ContentWraper from "../../../layout/ContentWrapper";
import DataCard from "../../../components/DataCard";
import DataTable from "../../../components/DataTable";

import PrimaryButton from "../../../components/PrimaryButton";

import "../vendors.scss";
import { Loans } from "../../../redux";

class CreditLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditLines: JSON.parse(
        localStorage.getItem("shortlistedCreditLines") || "[]"
      ),

      loading: false,
    };
    this.loadCreditLines = this.loadCreditLines.bind(this);
    this.creditLinesPage = 1;
    this.loadMore = this.loadMore.bind(this);
    this.fetchingNewPage = false;
    this.limit = 10;
  }

  loadMore() {
    this.creditLinesPage++;
    this.fetchingNewPage = true;
    // console.log(this.creditLinesPage);

    this.loadCreditLines();
  }

  async loadCreditLines() {
    this.setState({
      loading: true,
    });

    Loans.fetchLoans({
      sort: "-timestamp",
      limit: this.limit,
      populate: true,
      fields: "user,farmerName,village,timestamp,loanAmount,status",
      status: "shortlisted",
      page: this.creditLinesPage,
      fetchNewPage: this.fetchingNewPage,
    });

    if (this.fetchingNewPage) {
      this.fetchingNewPage = false;
    }

    // const applications = await db
    //   .collectionGroup("applications")
    //   .where("shortlisted", "==", true)
    //   .get();
    // const creditLines = await Promise.all(
    //   applications.docs.map(async (doc) => {
    //     if (doc.data().user_id) {
    //       const userRef = db.collection("users").doc(doc.data().user_id);
    //       const user = await userRef.get();
    //       return {
    //         ...doc.data(),
    //         ...(user.exists ? user.data() : {}),
    //       };
    //     } else {
    //       return doc.data();
    //     }
    //   })
    // );
    // console.log(creditLines);

    // localStorage.setItem("shortlistedCreditLines", JSON.stringify(creditLines));

    // this.setState({
    //   creditLines,
    //   loading: false,
    // });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  async componentDidMount() {
    this.unsubscribe = Loans.store.subscribe(() => {
      const state = Loans.store.getState().loans;

      if (Array.isArray(state.shortlistedLoans)) {
        this.setState({
          creditLines: state.shortlistedLoans,
          loading: false,
        });
      }
    });

    this.loadCreditLines();
  }

  render() {
    return (
      <Protect>
        <Sidebar />
        <ContentWraper>
          <div className="Vendors">
            <DataCard
              title="Shortlisted Credit Lines"
              className="Vendors__table-card"
            >
              <p className="Dashboard__table-text">
                Please review each loan proposal and either approve or deny it.
                Click on an application to view all details.
              </p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PrimaryButton
                  onClick={async () => {
                    this.limit *= this.page;
                    this.page = 1;
                    this.fetchingNewPage = false;
                    await this.loadCreditLines();
                  }}
                  disabled={this.state.loading}
                >
                  {this.state.loading ? (
                    <Loader color="inherit" size="25px" />
                  ) : (
                    <img
                      src={window.getImage("reload-icon.svg")}
                      alt="Reload Icon"
                    />
                  )}
                </PrimaryButton>
              </div>
              {this.state.creditLines ? (
                <DataTable
                  tableData={this.state.creditLines}
                  loadMore={this.loadMore}
                />
              ) : (
                <Loader />
              )}
            </DataCard>
          </div>
        </ContentWraper>
      </Protect>
    );
  }
}

export default withRouter(CreditLines);
