import { useSelector } from "react-redux";

class ReduxAction {
  constructor(store, storeName) {
    this.store = store;
    this.storeName = storeName;
  }

  get state() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSelector((state) => state[this.storeName]);
  }
}

export default ReduxAction;
