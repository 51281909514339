import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";

import loansReducer from "./loans/loans.reducer";
import LoansActions from "./loans/loans.actions";
import loansEpic from "./loans/loans.epic";

const rootEpic = combineEpics(loansEpic);

const rootReducer = combineReducers({
  loans: loansReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const middleware = createEpicMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(middleware))
);
middleware.run(rootEpic);

const Loans = new LoansActions(store, "loans");

export default store;
export { Loans };
