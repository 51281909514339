/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useState } from "react";
import ReactMapboxGl from "!react-mapbox-gl";
import { useHistory } from "react-router-dom";
import { ReactMapboxGlCluster } from "!react-mapbox-gl-cluster";
import MapGL, { Marker } from "@urbica/react-map-gl";
import Cluster from "@urbica/react-map-gl-cluster";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

import { db } from "../../server";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1Ijoic3Vubnlyb2NrOCIsImEiOiJja2ZyOHJ6enkxaTR5MnhzNWJhbWdkeWJhIn0.OFi-bi2pObp6prX0yz4glQ",
});

const CustomSpiralComponent = ({ properties, ...restProps }) => {
  const onClick = (e) => {
    console.log(
      `Receive event onClick in spiral at properties: ${JSON.stringify(
        properties
      )}`
    );
  };
  return <div className="spiderifier-marker-content" onClick={onClick}></div>;
};

const CustomeMarkerComponent = ({ properties, className, cssObject, goTo }) => {
  const data =
    typeof properties === "string" ? JSON.parse(properties) : properties;

  const onClick = (e) => {
    console.log(typeof goTo);
    if (typeof goTo === "function") {
      goTo(`/creditlines/${data.credit_line_id}`);
    }
  };
  return <div className={className} style={cssObject} />;
};

function getEventHandlers() {
  return {
    // onClick: (properties, coords, offset) =>
    //   console.log(
    //     `Receive event onClick at properties: ${properties}, coords: ${coords}, offset: ${offset}`
    //   ),
    onClusterClick: (properties, coords, offset) =>
      console.log(
        `Receive event onClusterClick at properties: ${properties}, coords: ${coords}, offset: ${offset}`
      ),
  };
}

const MapView = ({ creditLines }) => {
  const [clusterData, setClusterData] = useState(null);
  const history = useHistory();

  console.log(creditLines);

  useEffect(() => {
    const data = {
      type: "FeatureCollection",
      features: creditLines.map((creditLine) => ({
        type: "Feature",
        properties: creditLine,
        geometry: creditLine.village.position,
      })),
    };

    setClusterData(data);
    // (async function () {
    //   const creditLinesWithLocation = [];
    //   const villageLocations = {};
    //   for (const creditLine of creditLines) {
    //     if (!creditLine.village_id) continue;
    //     if (creditLine.village_id in villageLocations) {
    //       creditLinesWithLocation.push({
    //         ...creditLine,
    //         location: villageLocations[creditLine.village_id],
    //       });
    //       continue;
    //     }
    //     const villageRef = db.collection("villages").doc(creditLine.village_id);
    //     const village = await villageRef.get();
    //     if (!Number(village.data().lon) || !Number(village.data().lat))
    //       continue;
    //     creditLinesWithLocation.push({
    //       ...creditLine,
    //       location: [
    //         Number(village.data().lon) + Math.random(),
    //         Number(Number(village.data().lat)),
    //       ],
    //     });
    //     villageLocations[creditLine.village_id] = [
    //       Number(village.data().lon),
    //       Number(Number(village.data().lat)),
    //     ];
    //   }

    //   const data = {
    //     type: "FeatureCollection",
    //     features: creditLinesWithLocation.map((creditLine) => ({
    //       type: "Feature",
    //       properties: creditLine,
    //       geometry: {
    //         type: "Point",
    //         coordinates: creditLine.location,
    //       },
    //     })),
    //   };

    //   setClusterData(data);
    // })();
  }, [creditLines]);

  console.log(clusterData);

  return (
    <Map
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={{
        height: "40vh",
        width: "100%",
        borderRadius: "2rem",
        marginBottom: "2rem",
      }}
      center={[78.9629, 20.5937]}
      zoom={[4]}
    >
      {clusterData && (
        <ReactMapboxGlCluster
          data={clusterData}
          {...getEventHandlers()}
          spiralComponent={CustomSpiralComponent}
          markerComponent={(props) => {
            return <CustomeMarkerComponent goTo={history.push} {...props} />;
          }}
          clusterClickEnabled={false}
        />
      )}
    </Map>
  );
};

export default MapView;
