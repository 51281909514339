import React from "react";

import CreditReportMarkup from "./CreditReportMarkup";
import SecondaryButton from "../../components/SecondaryButton";
// import CreditReportXML from "./CreditReportXML";

const renderReport = (CreditReportXML) => {
  CreditReportXML = CreditReportXML.replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"');

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(CreditReportXML, "text/xml");

  // Changes XML to JSON
  function xmlToJson(xml) {
    // Create the return object
    var obj = {};

    if (xml.nodeType == 1) {
      // element
      // do attributes
      if (xml.attributes.length > 0) {
        obj["@attributes"] = {};
        for (var j = 0; j < xml.attributes.length; j++) {
          var attribute = xml.attributes.item(j);
          obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType == 3) {
      // text
      obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
      for (var i = 0; i < xml.childNodes.length; i++) {
        var item = xml.childNodes.item(i);
        var nodeName = item.nodeName;
        if (typeof obj[nodeName] == "undefined") {
          obj[nodeName] = xmlToJson(item);
        } else {
          if (typeof obj[nodeName].push == "undefined") {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xmlToJson(item));
        }
      }
    }
    return obj;
  }

  const CreditReportData = xmlToJson(xmlDoc);
  const {
    INProfileResponse: {
      Header: { SystemCode, ReportDate, ReportTime },
      CreditProfileHeader: { ReportNumber },
      CAIS_Account: {
        CAIS_Account_DETAILS = [],
        CAIS_Summary: {
          Credit_Account: {
            CreditAccountTotal,
            CreditAccountActive,
            CreditAccountClosed,
          },
          Total_Outstanding_Balance: {
            Outstanding_Balance_All,
            Outstanding_Balance_Secured,
            Outstanding_Balance_UnSecured,
          },
        },
      },
      CAPS: {
        CAPS_Summary: {
          CAPSLast7Days,
          CAPSLast30Days,
          CAPSLast90Days,
          CAPSLast180Days,
        },
      },
      NonCreditCAPS: {
        NonCreditCAPS_Summary: {
          NonCreditCAPSLast7Days,
          NonCreditCAPSLast30Days,
          NonCreditCAPSLast90Days,
          NonCreditCAPSLast180Days,
        },
      },
      Current_Application: {
        Current_Application_Details: {
          Current_Applicant_Details: {
            First_Name,
            Last_Name,
            MobilePhoneNumber,
            EMailId: EmailId,
            IncomeTaxPan: PanNumber,
          },
        },
      },
      SCORE: { BureauScore: CreditScore },
    },
  } = CreditReportData;

  const value = (key) => (key && key["#text"]) || "";

  const replace = (key, value, all = true) =>
    (finalMarkup = all
      ? finalMarkup.replaceAll(key, value)
      : finalMarkup.replace(key, value));

  let finalMarkup = CreditReportMarkup;
  replace("{{REPORT_NUMBER}}", value(ReportNumber));
  replace(
    "{{REPORT_CREATED}}",
    `${value(ReportDate).slice(0, 4)}-${value(ReportDate).slice(4, 6)}-${value(
      ReportDate
    ).slice(6)} at ${value(ReportTime).slice(0, 2)}:${value(ReportTime).slice(
      2,
      4
    )}:${value(ReportTime).slice(4)}`
  );
  replace(
    "{{FULL_NAME}}",
    `${
      value(First_Name).slice(0, 1).toUpperCase() + value(First_Name).slice(1)
    } ${value(Last_Name).slice(0, 1).toUpperCase() + value(Last_Name).slice(1)}`
  );
  replace(
    "{{FIRST_NAME}}",
    value(First_Name).slice(0, 1).toUpperCase() + value(First_Name).slice(1)
  );
  replace(
    "{{LAST_NAME}}",
    value(Last_Name).slice(0, 1).toUpperCase() + value(Last_Name).slice(1)
  );
  replace("{{MOBILE_NO}}", `${value(MobilePhoneNumber)}`);
  replace("{{EMAIL}}", value(EmailId));
  replace("{{PAN_NUMBER}}", value(PanNumber));
  replace("{{CREDIT_SCORE}}", value(CreditScore));
  replace(
    "{{CREDIT_SCORE_PERCENTAGE}}",
    Math.round(((value(CreditScore) - 300) / 600) * 100)
  );
  replace("{{TOTAL_ACCOUNTS}}", value(CreditAccountTotal));
  replace("{{ACTIVE_ACCOUNTS}}", value(CreditAccountActive));
  replace("{{CLOSED_ACCOUNTS}}", value(CreditAccountClosed));

  replace("{{TOTAL_CURRENT_BALANCE}}", value(Outstanding_Balance_All));
  replace("{{SECURED_ACCOUNTS_AMOUNT}}", value(Outstanding_Balance_Secured));
  replace(
    "{{UNSECURED_ACCOUNTS_AMOUNTS}}",
    value(Outstanding_Balance_UnSecured)
  );

  replace("{{LAST_7_DAYS_ENQUIRIES}}", value(CAPSLast7Days));
  replace("{{LAST_30_DAYS_ENQUIRIES}}", value(CAPSLast30Days));
  replace("{{LAST_90_DAYS_ENQUIRIES}}", value(CAPSLast90Days));
  replace("{{LAST_180_DAYS_ENQUIRIES}}", value(CAPSLast180Days));

  replace(
    "{{LAST_7_DAYS_NON_CREDIT_ENQUIRIES}}",
    value(NonCreditCAPSLast7Days)
  );
  replace(
    "{{LAST_30_DAYS_NON_CREDIT_ENQUIRIES}}",
    value(NonCreditCAPSLast30Days)
  );
  replace(
    "{{LAST_90_DAYS_NON_CREDIT_ENQUIRIES}}",
    value(NonCreditCAPSLast90Days)
  );
  replace(
    "{{LAST_180_DAYS_NON_CREDIT_ENQUIRIES}}",
    value(NonCreditCAPSLast180Days)
  );

  let i = 0;

  const stateCodes = [
    "Jammu and Kashmir",
    "Himachal Pradesh",
    "Punjab",
    "Chandigarh",
    "Uttranchal",
    "Harayana",
    "Delhi",
    "Rajasthan",
    "Uttar Pradesh",
    "Bihar",
    "Sikkim",
    "Arunachal Pradesh",
    "Nagaland",
    "Manipur",
    "Mizoram",
    "Tripura",
    "Meghalaya",
    "Assam",
    "West Bengal",
    "Jharkhand",
    "Orrisa",
    "Chhattisgarh",
    "Madhya Pradesh",
    "Gujrat",
    "Daman and Diu",
    "Dadara and Nagar Haveli",
    "Maharashtra",
    "Andhra Pradesh",
    "Karnataka",
    "Goa",
    "Lakshwadeep",
    "Kerala",
    "Tamil Nadu",
    "Pondicherry",
    "Andaman and Nicobar Islands",
    "Telangana",
  ];

  for (const CAIS_Account of Array.isArray(CAIS_Account_DETAILS)
    ? CAIS_Account_DETAILS
    : [CAIS_Account_DETAILS]) {
    const {
      Subscriber_Name: lender,
      Account_Type: accountTypeCode,
      Account_Number: accountNo,
      Date_Reported: dateReported,
      Account_Status: accountStatus,
      Open_Date: dateOpened,
      Date_Closed: dateClosed,
      Rate_of_Interest: rateOfInterest,
      Value_of_Collateral: collateralValue,
      Type_of_Collateral: collateralType,
      LitigationStatusDate: litigationStatusDate,
      WriteOffStatusDate: writeOffStatusDate,
      SuitFiledWillfulDefaultWrittenOffStatus: suitFiledWilfulDefaultStatus,
      SuitFiled_WilfulDefault: suitFiledWilfulDefault,
      Written_off_Settled_Status: writtenOffSettledStatus,
      Highest_Credit_or_Original_Loan_Amount: highestCredit,
      Date_of_Last_Payment: lastPaymentDate,
      Repayment_Tenure: repaymentTenure,
      Written_Off_Amt_Total: totalWriteOffAmount,
      Written_Off_Amt_Principal: principalWriteOffAmount,
      Value_of_Collateral: valueOfCollateral,
      Settlement_Amount: settlementAmount,
      Scheduled_Monthly_Payment_Amount: emiAmount,
      Current_Balance: currentBalance,
      Amount_Past_Due: amountOverdue,
      Type_of_Collateral: typeOfCollateral,
      AccountHoldertypeCode: accountHolderTypeCode,
      CAIS_Holder_Details: {
        First_Name_Non_Normalized: firstName,
        Surname_Non_Normalized: secondName,
      } = {},
      CAIS_Holder_Address_Details: {
        First_Line_Of_Address_non_normalized: address1 = "",
        Second_Line_Of_Address_non_normalized: address2 = "",
        Third_Line_Of_Address_non_normalized: address3 = "",
        City_non_normalized: city = "",
        Fifth_Line_Of_Address_non_normalized: address5 = "",
        State_non_normalized: state = "",
        ZIP_Postal_Code_non_normalized: zipCode = "",
      } = {},
      CAIS_Account_History,
    } = CAIS_Account;
    i++;
    const paymentHistory = Array.isArray(CAIS_Account_History)
      ? CAIS_Account_History
      : [CAIS_Account_History];

    const accountTypeN = Number(value(accountTypeCode));
    let accountType;
    const accountTypes = [
      { code: 1, name: "AUTO LOAN" },
      { code: 2, name: "HOUSING LOAN" },
      { code: 3, name: "PROPERTY LOAN" },
      { code: 4, name: "LOAN AGAINST SHARES/SECURITIES" },
      { code: 5, name: "PERSONAL LOAN" },
      { code: 6, name: "CONSUMER LOAN" },
      { code: 7, name: "GOLD LOAN" },
      { code: 8, name: "EDUCATIONAL LOAN" },
      { code: 9, name: "LOAN TO PROFESSIONAL" },
      { code: 10, name: "CREDIT CARD" },
      { code: 11, name: "LEASING" },
      { code: 12, name: "OVERDRAFT" },
      { code: 13, name: "TWO-WHEELER LOAN" },
      { code: 14, name: "NON-FUNDED CREDIT FACILITY" },
      { code: 15, name: "LOAN AGAINST BANK DEPOSITS" },
      { code: 16, name: "FLEET CARD" },
      { code: 17, name: "Commercial Vehicle Loan" },
      { code: 18, name: "Telco – Wireless" },
      { code: 19, name: "Telco – Broadband" },
      { code: 20, name: "Telco – Landline" },
      { code: 31, name: "Secured Credit Card" },
      { code: 32, name: "Used Car Loan" },
      { code: 33, name: "Construction Equipment Loan" },
      { code: 34, name: "Tractor Loan" },
      { code: 35, name: "Corporate Credit Card" },
      { code: 36, name: "Kisan Credit Card" },
      { code: 37, name: "Loan on Credit Card" },
      { code: 38, name: "Prime Minister Jaan Dhan Yojana - Overdraft" },
      { code: 39, name: "Mudra Loans – Shishu / Kishor / Tarun" },
      { code: 43, name: "Microfinance – Others" },
      { code: 51, name: "BUSINESS LOAN - GENERAL" },
      { code: 52, name: "BUSINESSS LOAN - PRIORITY SECTOR - SMALL BUSINESS" },
      { code: 53, name: "BUSINESSS LOAN - PRIORITY SECTOR - AGRICULTURE" },
      { code: 54, name: "BUSINESSS LOAN - PRIORITY SECTOR - OTHERS" },
      { code: 55, name: "BUSINESS NON-FUNDED CREDIT FACILITY – GENERAL" },
      {
        code: 56,
        name: "BUSINESS NON-FUNDED CREDIT FACILITY – PRIORITY SECTOR – SMALL BUSINESS",
      },
      {
        code: 57,
        name: "BUSINESS NON-FUNDED CREDIT FACILITY – PRIORITY SECTOR – AGRICULTURE",
      },
      {
        code: 58,
        name: "BUSINESS NON-FUNDED CREDIT FACILITY – PRIORITY SECTOR – OTHERS",
      },
      { code: 59, name: "BUSINESS LOANS AGAINST BANK DEPOSITS" },
      { code: 60, name: "Staff Loan" },
      { code: 61, name: "Business Loan - Unsecured" },
      { code: 0, name: "Other" },
    ];

    console.log(accountTypeN);

    accountType = accountTypes.find(
      (accountType) => accountType.code === accountTypeN
    );

    const accountHolderTypeN = Number(value(accountHolderTypeCode));
    let accountHolderType;
    switch (accountHolderTypeN) {
      case 1:
        accountHolderType = "Individual";
        break;
      case 2:
        accountHolderType = "Joint";
        break;
      case 3:
        accountHolderType = "Authorized User";
        break;
      case 7:
        accountHolderType = "Guarantor";
        break;
      default:
        accountHolderType = "Individual";
        break;
    }

    const settledAccountCodes = [
      "00",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
      "60",
      "61",
      "62",
      "63",
      "64",
      "65",
      "66",
      "67",
      "68",
      "69",
      "70",
      "72",
      "73",
      "74",
      "75",
      "76",
      "77",
      "79",
      "81",
      "85",
      "86",
      "87",
      "88",
      "90",
      "91",
      "93",
      "97",
    ];
    const activeAccountCodes = [
      11, 21, 22, 23, 24, 25, 71, 78, 80, 82, 83, 84,
    ].map((num) => String(num));
    const closedAccountCodes = [13, 14, 15, 16, 17].map((num) => String(num));

    const addLeadingZero = (num) => {
      if (String(num).length < 2) {
        return `0${num}`;
      } else {
        return num;
      }
    };

    const formatDate = (date) => {
      return (
        date.slice(0, 4) +
        "-" +
        addLeadingZero(date.slice(4, 6)) +
        "-" +
        addLeadingZero(date.slice(6))
      )
        .split("-")
        .reverse()
        .join("-");
    };

    replace(
      "{{CREDIT_ACCOUNT_INFORMATION_HEADER}}",
      `
        <tr>
          <td bgcolor="#FFFFFF" style="border: 1px solid #e5e5e5">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td height="5" valign="top"></td>
              </tr>
              <tr>
                <td height="123" valign="top" bgcolor="#FFFFFF" style="
                    border-bottom: 1px solid
                      rgb(204, 204, 204);
                  ">
                  <table width="99%" border="0" align="center" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="30%" height="30" class="subHeding" style="
                            border-bottom: 2px solid
                              #e5cfb9;
                          ">
                          ${accountType.name}
                        </td>
                        <td width="30%" class="subHeding" style="
                            border-bottom: 2px solid
                              #e5cfb9;
                          ">
                          ${value(lender)}
                        </td>
                        <td width="27%">&nbsp;</td>
                        <td width="12%" class="sectionDataElementValues" style="font-weight: bold">
                          <span>&rarr;</span>&nbsp;Acct 1
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <table width="100%" border="0" align="center" cellpadding="3" cellspacing="0">
                            <tbody>
                              <tr>
                                <td height="30" colspan="2" class="creditScoreTableHeading">
                                  ${value(secondName)?.trim()}
                                  ${value(firstName)?.trim()}
                                </td>
                              </tr>
                              <tr>
                                <td width="12%" class="subHeding">
                                  Address 1
                                </td>
                                <td width="88%" class="sectionDataElementValues">
                                  ${
                                    value(address1)[0] +
                                    value(address1).slice(1).toLowerCase()
                                  } ${value(address2)
        .split(" ")
        .map((el) => el[0] + el.slice(1).toLowerCase())
        .join(" ")} ${value(address3)
        .split(" ")
        .map((el) => el[0] + el.slice(1).toLowerCase())
        .join(" ")} ${value(city)
        .split(" ")
        .map((el) => el[0] + el.slice(1).toLowerCase())
        .join(" ")} ${
        value(address5)
          ? value(address5)
              .split(" ")
              .map((el) => el[0] + el.slice(1).toLowerCase())
              .join(" ")
          : ""
      } ${stateCodes[Number(value(state) || 0) - 1]} ${value(zipCode)}
                                </td>
                              </tr>
                              <tr>
                                <td height="10"></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        </tr>
        <tr>
          <td valign="top" bgcolor="#FFFFFF" style="
              border: 1px solid #e5e5e5;
              border-top: 0px;
            ">
            <table width="99%" border="0" align="center" cellpadding="5" cellspacing="0">
              <tbody>
                <tr>
                  <td width="33%" class="subHeding" style="
                      border-right: 1px solid #e5e5e5;
                      border-bottom: 1px solid #e5e5e5;
                    ">
                    Account terms
                  </td>
                  <td width="33%" class="subHeding" style="
                      border-right: 1px solid #e5e5e5;
                      border-bottom: 1px solid #e5e5e5;
                    ">
                    Account description
                  </td>
                  <td width="34%" class="subHeding" style="
                      border-bottom: 1px solid #e5e5e5;
                    ">
                    Account details
                  </td>
                </tr>
                <tr>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Account Number
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(accountNo)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Date Reported
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${formatDate(value(dateReported))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Credit Limit Amt
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            -
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Date Opened
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${formatDate(value(dateOpened))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Loan Type
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${accountType.name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            EMI
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(emiAmount) || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Date Closed
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(dateClosed) || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Account Status
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${(() => {
                              const accountStatusCode = value(accountStatus);
                              return activeAccountCodes.includes(
                                accountStatusCode
                              )
                                ? "ACTIVE"
                                : closedAccountCodes.includes(accountStatusCode)
                                ? "CLOSED"
                                : "SF/WD/WO/SETTLED";
                            })()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Repayment Tenure
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(repaymentTenure)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Ownership
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${accountHolderType}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Sanctioned Amt
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(highestCredit)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Total Write-off Amt
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(totalWriteOffAmount) || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Rate of Interest
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(rateOfInterest) || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Current Balance
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(currentBalance)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Principal Write-off
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(principalWriteOffAmount) || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Value of Collateral
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(valueOfCollateral) || ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Amount Overdue
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(amountOverdue)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Settlement Amt
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(settlementAmount) || ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Type of Collateral
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(typeOfCollateral) || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Last Payment Date
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(lastPaymentDate) || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            &nbsp;
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            SuitFiled Willful Default
                            WrittenOff Status
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(suitFiledWilfulDefaultStatus) || ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            SuitFiled Willful Default
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(suitFiledWilfulDefault) || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="
                      border-right: 1px solid #e5e5e5;
                    ">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td width="70%" class="sectionDataElementsmallHed">
                            Written off Settled Status
                          </td>
                          <td width="30%" class="sectionDataElementValues">
                            ${value(writtenOffSettledStatus) || ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td width="33%" class="subHeding" style="border-bottom: 1px solid #e5e5e5">
            Payment History
          </td>
        </tr>
        <tr>
        <td height="" valign="top">
        <table width="55%" border="0" cellspacing="0" cellpadding="0" style="border-collapse: collapse">
          <tbody>
            <tr>
              <td width="5%" height="35" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  border-bottom: 1px solid #e5e5e5;
                  text-align: center;
                ">
                DPD
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Dec
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Nov
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Oct
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Sep
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Aug
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Jul
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Jun
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                May
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Apr
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Mar
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Feb
              </td>
              <td width="6%" bgcolor="#F3F3F3" class="subHeding" style="
                  border-top: 1px solid #e5e5e5;
                  border-left: 1px solid #e5e5e5;
                  border-right: 1px solid #e5e5e5;
                  text-align: center;
                  border-bottom: 1px solid #e5e5e5;
                ">
                Jan
              </td>
            </tr>
            <tr>
              <td width="5%" height="35" bgcolor="#F3F3F3" class="subHeding" style="
                  border-left: 1px solid #e5e5e5;
                  border-bottom: 1px solid #e5e5e5;
                  border-right: 1px solid #e5e5e5;
                  text-align: center;
                ">
                ${value(paymentHistory[0].Year)}
              </td>
              ${Array(12)
                .fill(null)
                .map((_, month) => {
                  let active = false,
                    activePayment;
                  paymentHistory.forEach((payment) => {
                    if (13 - Number(value(payment.Month)) === month + 1) {
                      active = true;
                      activePayment = payment;
                    }
                  });
                  return active
                    ? `<td width="6%" valign="middle" bgcolor="#FFFFFF" class="circleText circleOrangeBackground" style="
                    border-right: 1px solid #e5e5e5;
                    border-bottom: 1px solid #e5e5e5;
                  ">
                  ${value(activePayment.Days_Past_Due)}
                </td>`
                    : `<td width="6%" valign="middle" bgcolor="#FFFFFF" class="circleText" style="
                  border-right: 1px solid #e5e5e5;
                  border-bottom: 1px solid #e5e5e5;
                ">
                &nbsp;
              </td>`;
                })
                .join("")}
            </tr>
            <tr>
              <td height="10" colspan="13" bgcolor="#FFFFFF"></td>
            </tr>
          </tbody>
        </table>
      </td>
        </tr>
        <tr>
          <td height="25" bgcolor="#FFFFFF" class="subHeding" style="
              border: 1px solid #e5e5e5;
              border-top: 0px;
            ">
            &nbsp;&nbsp;&nbsp;Consumer Personal
            details on the Credit Account
          </td>
        </tr>
        <tr>
          <td valign="top" bgcolor="#FFFFFF" style="
            border: 1px solid #e5e5e5;
            border-top: 0px;
          ">
          <table width="99%" border="0" align="center" cellpadding="5" cellspacing="0">
            <tbody>
              <tr>
                <td width="33%" class="subHeding" style="
                    border-right: 1px solid #e5e5e5;
                    border-bottom: 1px solid #e5e5e5;
                  ">
                  Account terms
                </td>
                <td width="33%" class="subHeding" style="
                    border-right: 1px solid #e5e5e5;
                    border-bottom: 1px solid #e5e5e5;
                  ">
                  Account description
                </td>
                <td width="34%" class="subHeding" style="
                    border-bottom: 1px solid #e5e5e5;
                  ">
                  Account details
                </td>
              </tr>
              <tr>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Account Number
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(accountNo)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Date Reported
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${formatDate(value(dateReported))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Credit Limit Amt
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(highestCredit)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Date Opened
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${formatDate(value(dateOpened))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Loan Type
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${accountType.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          EMI
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(emiAmount) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Date Closed
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${
                            value(dateClosed)
                              ? formatDate(value(dateClosed))
                              : "-"
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Account Status
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${
                            settledAccountCodes.includes(value(accountStatus))
                              ? "SF/WD/WO/SETTLED"
                              : activeAccountCodes.includes(
                                  value(accountStatus)
                                )
                              ? "ACTIVE"
                              : "CLOSED"
                          }*
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Repayment Tenure
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(repaymentTenure)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Ownership
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${accountHolderType}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Sanctioned Amt
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(highestCredit)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Total Write-off Amt
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(totalWriteOffAmount) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Rate of Interest
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(rateOfInterest) || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Current Balance
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(currentBalance)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Principal Write-off
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(principalWriteOffAmount) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Value of Collateral
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(valueOfCollateral) || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Amount Overdue
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(amountOverdue) || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Settlement Amt
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(settlementAmount) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Type of Collateral
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(typeOfCollateral)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Last Payment Date
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(lastPaymentDate) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          &nbsp;
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          &nbsp;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          SuitFiled Willful Default
                          WrittenOff Status
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(suitFiledWilfulDefaultStatus) || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          SuitFiled Willful Default
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(suitFiledWilfulDefault) || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style="
                    border-right: 1px solid #e5e5e5;
                  ">
                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td width="70%" class="sectionDataElementsmallHed">
                          Written off Settled Status
                        </td>
                        <td width="30%" class="sectionDataElementValues">
                          ${value(writtenOffSettledStatus) || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        </tr>
        <tr>
          {{CREDIT_ACCOUNT_INFORMATION_HEADER}}
        </tr>
      `
    );

    replace(
      "{{CREDIT_ACCOUNT_SUMMARY}}",
      `<tr>
    <td class="subHeding summaryDetailColumn" style="padding:0px">Acct ${i}</td>
    <td class="sectionDataElementValues" style="width:200px">${value(
      lender
    )} </td>
    <td class="sectionDataElementValues summaryDetailColumn">${
      accountType.name
    }</td>
    <td class="sectionDataElementValues summaryDetailColumn">${value(
      accountNo
    )} </td>
    <td class="sectionDataElementValues summaryDetailColumn">${accountHolderType} </td>
    <td class="sectionDataElementValues summaryDetailColumn">${value(
      dateReported
    ).slice(-2)}-${value(dateReported).slice(-4, -2)}-${value(
        dateReported
      ).slice(0, 4)} </td>
    <td class="sectionDataElementValues summaryDetailColumn">${
      settledAccountCodes.includes(value(accountStatus))
        ? "SF/WD/WO/SETTLED"
        : activeAccountCodes.includes(value(accountStatus))
        ? "ACTIVE"
        : "CLOSED"
    }* </td>
    <td class="sectionDataElementValues summaryDetailColumn">${value(
      dateOpened
    ).slice(-2)}-${value(dateOpened).slice(-4, -2)}-${value(dateOpened).slice(
        0,
        4
      )}</td>
    <td class="sectionDataElementValues summaryDetailColumn">${value(
      highestCredit
    )} </td>
    <td class="sectionDataElementValues summaryDetailColumn">${value(
      currentBalance
    )} </td>
    <td class="sectionDataElementValues summaryDetailColumn">${value(
      amountOverdue
    )} </td>
    </tr>
    {{CREDIT_ACCOUNT_SUMMARY}}`,
      false
    );
  }

  replace("{{CREDIT_ACCOUNT_SUMMARY}}", "");
  replace("{{CREDIT_ACCOUNT_INFORMATION_HEADER}}", "");

  return finalMarkup;
};

const FinancialDetails = ({ aadhaarNumber, creditReportXML }) => {
  if (!creditReportXML || creditReportXML === "NA") return null;

  let finalMarkup = renderReport(
    creditReportXML
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">")
      .replaceAll("&quot;", '"')
  );
  const replace = (key, value, all = true) =>
    (finalMarkup = all
      ? finalMarkup.replaceAll(key, value)
      : finalMarkup.replace(key, value));
  replace("{{AADHAR_NUMBER}}", aadhaarNumber);

  return (
    // <div
    //   style={{ display: active ? "block" : "none" }}
    //   dangerouslySetInnerHTML={{ __html: CreditReportMarkup }}
    // ></div>
    <iframe
      title="Credit Report"
      id="CreditReport"
      srcDoc={finalMarkup}
      style={{
        width: "100%",
        height: "100vh",
        marginTop: "20rem",
        border: "none",
      }}
    ></iframe>
  );
};
export default FinancialDetails;
