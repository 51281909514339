import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "save-as";
import JSZipUtils from "jszip-utils";

import { db } from "../../server";
import Protect from "../../auth";
import Sidebar from "../../layout/sidebar";
import ContentWrapper from "../../layout/ContentWrapper";

import DataCard from "../../components/DataCard";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";

import VendorCard from "./VendorCard";

import "./Ledgers.scss";
import { CircularProgress } from "@material-ui/core";

const zip = new JSZip();

const Ledgers = () => {
  const [vendors, setVendors] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [totalDueAmount, setTotalDueAmount] = useState(null);

  useEffect(() => {
    db.collection("vendors").onSnapshot((querySnapshot) => {
      const vendors = querySnapshot.docs.map((doc) => doc.data());
      let totalDueAmount = 0;
      // vendors.forEach(
      //   (vendor) => (totalDueAmount += vendor.total_receivables || 0)
      // );
      console.log(vendors);
      totalDueAmount = vendors.reduce((a, b) => a + b.total_receivables, 0);
      setVendors(vendors);
      setTotalDueAmount(totalDueAmount);
    });

    db.collectionGroup("transactions").onSnapshot((querySnapshot) => {
      const transactions = querySnapshot.docs.map((doc) => doc.data());
      setTransactions(transactions);
    });
  }, []);

  return (
    <Protect>
      <Sidebar />
      <ContentWrapper>
        <div className="Ledger__container">
          <div
            style={{
              display: "flex",
              alignItems: "stretch",
              marginBottom: "3rem",
            }}
          >
            <div className="Ledger__main-card">
              <DataCard className="Dashboard__due-amount-container" primary>
                <div className="Dashboard__due-amount-content">
                  <h1 className="Dashboard__due-amount">
                    ₹{" "}
                    {String(totalDueAmount || 0).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ", "
                    )}
                  </h1>
                  <h3 className="Dashboard__due-amount-text">
                    Please clear out your dues to vendors.
                  </h3>
                  <PrimaryButton>Clear Dues</PrimaryButton>
                </div>
                <img
                  className="Dashboard__due-payment-icon"
                  src={window.getImage("due_payment_illustration.svg")}
                  alt="Due Payment Icon"
                />
              </DataCard>
            </div>
            <div style={{ height: "100%", flex: 0.33 }}>
              <DataCard
                title="Total vendors"
                increase={100}
                value={vendors.length}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2
              className="Ledger__subheading"
              style={{ marginBottom: "3.6rem" }}
            >
              Vendors' Receivables
            </h2>
            <div>
              <SecondaryButton
                onClick={() => {
                  var count = 0;
                  var zipFilename = "zipFilename.zip";
                  const urls = transactions.map((transaction) => ({
                    url: transaction.invoice,
                    name: `${transaction.transaction_short_id}__${new Date(
                      transaction.date.seconds * 1000
                    ).toDateString()}.pdf`,
                  }));

                  if (window.mainProcess?.downloadInvoices) {
                    window.mainProcess.downloadInvoices(urls, "");
                  } else {
                    urls.forEach(function (url) {
                      // loading a file and add it in a zip file
                      JSZipUtils.getBinaryContent(
                        url.url,
                        function (err, data) {
                          if (err) {
                            throw err; // or handle the error
                          }
                          zip.file(url.name, data, { binary: true });
                          count++;
                          if (count === urls.length) {
                            zip
                              .generateAsync({ type: "blob" })
                              .then(function (content) {
                                saveAs(content, zipFilename);
                              });
                          }
                        }
                      );
                    });
                  }
                }}
              >
                Download All Invoices
              </SecondaryButton>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {vendors.length ? (
              vendors.map((vendor) => (
                <VendorCard
                  vendorID={vendor.vendor_short_id}
                  totalAmount={vendor.total_receivables}
                  totalTransactions={vendor.total_transactions}
                  key={vendor.vendor_id}
                  vendorUserID={vendor.vendor_id}
                />
              ))
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      </ContentWrapper>
    </Protect>
  );
};

export default Ledgers;
