import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DataCard from "../../components/DataCard";

const TransactionCardContainer = styled.div`
  width: 31.5rem;
  min-height: 13.3rem;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 1rem;
  /* transition: height 0.4s; */
`;

const TransactionID = styled.span`
  font-size: 1.6rem;
  color: #00ab55;
  font-weight: 500;
  font-family: "Be Vietnam", sans-serif;
  text-align: center;
`;

const TransactionAmount = styled.h3`
  font-size: 2.7rem;
  color: #212b36;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const DetailsText = styled.p`
  font-family: "Be Vietnam", sans-serif;
  font-weight: 500;
  width: 100%;
  font-size: 1.6rem;
  text-align: left;
`;

const SeeMoreLink = styled.h3`
  font-size: 1.6rem;
  color: #00ab55;
  font-family: "Be Vietnam", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-transform: lowercase;
  transition: color 0.3s;

  &:hover {
    color: #027b55;
  }
`;

const TransactionCard = ({
  transactionID,
  transactionAmount,
  name,
  date,
  category,
  creditLineID,
  invoice,
  seeMore = true,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => setExpanded((curExpanded) => !curExpanded);

  return (
    <TransactionCardContainer onClick={handleClick}>
      <DataCard
        style={{
          transition: "height 300ms ease-out",
          height: expanded ? "26rem" : "14rem",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TransactionID>#{transactionID}</TransactionID>
          <TransactionAmount style={{ fontSize: "2.7rem" }}>
            ₹ {String(transactionAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
          </TransactionAmount>
          <div
            style={{
              visibility: expanded ? "visible" : "hidden",
              transition: "opacity 0.4s",
              opacity: expanded ? 1 : 0,
            }}
          >
            <DetailsText>
              <b>Name - </b>
              {name}
            </DetailsText>
            <DetailsText>
              <b>Date - </b>
              {(() => {
                const dateFormatted = date
                  .toLocaleDateString("en-IN", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })
                  .split(" ");

                console.log(dateFormatted);

                return `${dateFormatted[0]}th ${dateFormatted[1]}, ${dateFormatted[2]}`;
              })()}
            </DetailsText>
            <DetailsText>
              <b>Category - </b>
              {category}
            </DetailsText>

            {seeMore && (
              // <Link
              //   style={{ textDecoration: "none" }}
              //   to={`/manage/creditlines/${creditLineID}`}
              // >
              <SeeMoreLink
                style={{ color: "#00ab55" }}
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = invoice;
                  link.target = "_blank";
                  link.rel = "noopener";
                  link.click();
                }}
              >
                See More
              </SeeMoreLink>
              // </Link>
            )}
          </div>

          <span
            style={{
              position: "absolute",
              bottom: "1rem",
              left: "50%",
              transform: `translateX(-50%) rotate(${expanded ? 180 : 0}deg)`,
              marginLeft: "-5px",
              borderWidth: "5px",
              borderStyle: "solid",
              borderColor: "#212B36 transparent transparent transparent",
              borderRadius: "3px",
              opacity: 0.64,
              transition: "transform 300ms",
            }}
          />
        </div>
      </DataCard>
    </TransactionCardContainer>
  );
};

export default TransactionCard;
