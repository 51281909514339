import React from "react";
import styled from "styled-components";

import DataCard from "../../components/DataCard";

const ProductImage = styled.div`
  width: 9.33rem;
  height: 13.2rem;
  background-size: contain;
  background-repeat: no-repeat;
`;

const ProductName = styled.h3`
  font-family: "Be Vietnam", sans-serif;
  color: #212b36;
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 1.8rem;
`;

const Price = styled.h4`
  font-family: "Be Vietnam", sans-serif;
  font-size: 1.5rem;
  color: #00ab55;
  font-weight: 400;
`;

const ProductCard = ({ imageSrc, productName, pricePerUnit, unitName }) => {
  return (
    <DataCard style={{ width: "22.4rem" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ProductImage style={{ backgroundImage: `url(${imageSrc})` }} />
        <ProductName style={{ fontSize: "1.8rem" }}>{productName}</ProductName>
        <Price>
          ₹ {String(pricePerUnit).replace(/\B(?=(\d{3})+(?!\d))/g, ", ")} per{" "}
          {unitName}
        </Price>
      </div>
    </DataCard>
  );
};

export default ProductCard;
