import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00AB55",
    },
    secondary: {
      main: "#FDA78D",
    },
  },
  typography: {
    body1: {
      fontFamily: '"Be Vietnam", sans-serif',
      fontWeight: "500",
      fontSize: "1.6rem",
      color: "rgb(99, 115, 129)",
      opacity: "0.8",
      lineHeight: "1.5",
    },
    h6: {
      fontFamily: "'Be Vietnam', sans-serif",
      color: "rgb(33, 43, 54)",
      fontWeight: "600",
      lineHeight: "1.55556",
      fontSize: "1.8rem",
    },
  },
});

export default theme;
