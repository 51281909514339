import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { db } from "../../server";
import Protect, { getUser } from "../../auth";

import Sidebar from "../../layout/sidebar";
import ContentWraper from "../../layout/ContentWrapper";
import Grid from "../../layout/Grid";
import DataCard from "../../components/DataCard";
import PrimaryButton from "../../components/PrimaryButton";
import DataTable from "../../components/DataTable";
import { CircularProgress } from "@material-ui/core";

import formatNumber from "../../utils/formatNumber";

import Chart from "react-apexcharts";

import "./Dashboard.css";
import MapView from "./MapView";
import { Loans } from "../../redux";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalDueAmount: null,
      totalVendors: localStorage.getItem("totalVendors"),
      lastMonthVendors: localStorage.getItem("lastMonthVendors"),
      vendorsIncrease: localStorage.getItem("vendorsIncrease"),
      creditLines: JSON.parse(localStorage.getItem("creditLines")),
      totalCreditLines: localStorage.getItem("totalCreditLines"),
      creditLinesIncrease: localStorage.getItem("creditLinesIncrease"),
      approvedCreditLines: JSON.parse(
        localStorage.getItem("approvedCreditLines")
      ),
      shortlistedCreditLines: JSON.parse(
        localStorage.getItem("shortlistedCreditLines")
      ),
      statistics: JSON.parse(localStorage.getItem("loanStatistics")),
    };
    this.user = JSON.parse(localStorage.getItem("user")) || null;
    this.unsubscribe = null;
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  async componentDidMount() {
    Loans.fetchLoans({
      sort: "-timestamp",
      limit: 10,
      populate: true,
      fields: "user,farmerName,village,timestamp,loanAmount,status",
      status: "pending",
    });
    Loans.fetchStatistics();

    this.unsubscribe = Loans.store.subscribe(
      function () {
        console.log("State change");
        const state = Loans.store.getState()?.loans;
        const loans = state?.loans;
        if (loans && Array.isArray(loans)) {
          console.log("Setting loans", loans);
          this.setState({ creditLines: loans });
        }

        if (state.statistics) {
          this.setState({ statistics: state.statistics });
        }
      }.bind(this)
    );

    this.user = await getUser();
    const layout = this.user?.layout?.dashboard || null;

    this.setState({
      totalDueAmount: this.user.total_due_amount,
    });

    const vendorsRef = db.collection("vendors");
    const vendorsSnap = await vendorsRef.get();
    let totalVendors = 0,
      lastMonthVendors = 0;

    const today = new Date();
    const lastMonth = new Date(today.setMonth(today.getMonth() - 1));
    vendorsSnap.forEach((vendor) => {
      totalVendors++;
      if (
        vendor
          .data()
          .joining_date?.endsWith(
            `${lastMonth.getMonth() + 1}-${lastMonth.getFullYear()}`
          )
      ) {
        lastMonthVendors++;
      }
    });

    localStorage.setItem("totalVendors", "" + totalVendors);
    localStorage.setItem("lastMonthVendors", "" + lastMonthVendors);

    let vendorsIncrease = totalVendors - lastMonthVendors;

    vendorsIncrease =
      vendorsIncrease > 0 ? (vendorsIncrease / lastMonthVendors) * 100 : 0;
    localStorage.setItem("vendorsIncrease", "" + vendorsIncrease);

    // const creditLinesRef = db.collection("credit_lines");
    // let totalCreditLines = 0,
    //   lastMonthCreditLines = 0;
    // const creditLinesSnap = await creditLinesRef.get();

    // const creditLineUsers = [];

    // creditLinesSnap.forEach((creditLine) => {
    //   totalCreditLines += creditLine.data().active_credit_lines;
    //   creditLineUsers.push(creditLine.id);
    // });

    // let month = new Date().getMonth(),
    //   year = new Date().getFullYear();
    // month--;
    // if (month === 0) {
    //   month = 12;
    //   year--;
    // }

    // for (const user of creditLineUsers) {
    //   const creditLinesRef = db
    //     .collection("credit_lines")
    //     .doc(user)
    //     .collection("credit_lines");
    //   const creditLinesSnap = await creditLinesRef.get();
    //   for (const creditLine of creditLinesSnap.docs) {
    //     if (creditLine.data().issuing_date?.endsWith(`${month}-${year}`)) {
    //       lastMonthCreditLines++;
    //     }
    //   }
    // }

    // let creditLinesIncrease = totalCreditLines - lastMonthCreditLines;
    // creditLinesIncrease =
    //   creditLinesIncrease > 0
    //     ? (creditLinesIncrease / lastMonthCreditLines > 0
    //         ? lastMonthCreditLines
    //         : 1) * 100
    //     : 0;
    // localStorage.setItem("creditLinesIncrease", "" + creditLinesIncrease);
    // localStorage.setItem("totalCreditLines", "" + totalCreditLines);

    // const unsortedCreditLines = [];
    // const shortlistedCreditLines = [];
    // const creditLinesInReviewUsersRef = db.collection("credit_lines_in_review");

    // const creditLinesInReviewUsers = await creditLinesInReviewUsersRef.get();
    // const userIds = [];
    // creditLinesInReviewUsers.forEach((user) => {
    //   userIds.push({ userId: user.data().user_id });
    // });

    // for (const { userId } of userIds) {
    //   const userRef = db.collection("users").doc(userId);
    //   const user = await userRef.get();

    //   const userCreditLinesRef = db
    //     .collection("credit_lines_in_review")
    //     .doc(userId)
    //     .collection("applications");

    //   const userCreditLines = await userCreditLinesRef.get();
    //   userCreditLines.forEach((creditLine) => {
    //     if (layout.card_primary === "loan_applications") {
    //       if (
    //         !creditLine.data().rejected &&
    //         !creditLine.data().approved &&
    //         !creditLine.data().shortlisted
    //       )
    //         unsortedCreditLines.push({
    //           user_id: creditLine.data().user_id || userId,
    //           ...user.data(),
    //           ...creditLine.data(),
    //           ...(creditLine.data().village_id
    //             ? { village_id: creditLine.data().village_id }
    //             : {}),
    //         });
    //     } else {
    //       if (
    //         !creditLine.data().rejected &&
    //         (creditLine.data().approved || creditLine.data().always_show)
    //       )
    //         unsortedCreditLines.push({
    //           user_id: creditLine.data().user_id || userId,
    //           ...user.data(),
    //           ...creditLine.data(),
    //           ...(creditLine.data().village_id
    //             ? { village_id: creditLine.data().village_id }
    //             : {}),
    //         });
    //     }

    //     if (creditLine.data().shortlisted)
    //       shortlistedCreditLines.push({
    //         user_id: userId,
    //         ...user.data(),
    //         ...creditLine.data(),
    //         ...(creditLine.data().village_id
    //           ? { village_id: creditLine.data().village_id }
    //           : {}),
    //       });
    //   });
    // }

    // const creditLines = unsortedCreditLines.sort(
    //   (a, b) => b.application_timestamp - a.application_timestamp
    // );

    // localStorage.setItem("creditLines", JSON.stringify(creditLines));

    // const approvedCreditLinesRef = db.collectionGroup("credit_lines");
    // const approvedCreditLinesSnap = await approvedCreditLinesRef.get();
    // const approvedCreditLines = approvedCreditLinesSnap.docs
    //   .map((doc) => {
    //     if (!doc.data().credit_line_number) return null;

    //     const matchingApplication = creditLines.filter(
    //       (creditLine) =>
    //         creditLine.credit_line_id === doc.data().credit_line_number
    //     )[0];
    //     if (!matchingApplication) return null;

    //     return { ...doc.data(), ...matchingApplication };
    //   })
    //   .filter((doc) => doc !== null);

    // localStorage.setItem(
    //   "approvedCreditLines",
    //   JSON.stringify(approvedCreditLines)
    // );
    // localStorage.setItem(
    //   "shortlistedCreditLines",
    //   JSON.stringify(shortlistedCreditLines)
    // );

    // this.setState({
    //   totalVendors,
    //   lastMonthVendors,
    //   vendorsIncrease,
    //   creditLinesIncrease,
    //   creditLines,
    //   totalCreditLines,
    //   approvedCreditLines,
    //   shortlistedCreditLines,
    // });
  }

  render() {
    console.log(this.user);
    // alert(this.state.totalDueAmount);
    return (
      <Protect>
        <Sidebar />
        <ContentWraper>
          <div className="Dashboard__container">
            <Grid cols={3} rows={1} gridGap="2.4rem">
              <div
                className="Dashboard__main-cards"
                style={{
                  gridColumn: this.user?.layout?.dashboard?.details_top
                    ? "1 / span 3"
                    : "1 / span 2",
                }}
              >
                {!this.user?.layout?.dashboard?.details_top ?? true ? (
                  <DataCard className="Dashboard__due-amount-container" primary>
                    <>
                      <div className="Dashboard__due-amount-content">
                        <h1 className="Dashboard__due-amount">
                          ₹{" "}
                          {!Number.isNaN(Number(this.state.totalDueAmount))
                            ? this.state.totalDueAmount
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")
                            : "..."}
                        </h1>
                        <h3 className="Dashboard__due-amount-text">
                          Please clear out your dues to vendors.
                        </h3>
                        <PrimaryButton>Clear Dues</PrimaryButton>
                      </div>
                      <img
                        className="Dashboard__due-payment-icon"
                        src={window.getImage("due_payment_illustration.svg")}
                        alt="Due Payment Icon"
                      />
                    </>
                  </DataCard>
                ) : (
                  <>
                    {this.state.creditLines?.length && (
                      <>
                        <h1
                          style={{
                            fontFamily: '"Be Vietnam", sans-serif',
                            fontSize: "1.75rem",
                            marginBottom: "1.5rem",
                          }}
                        >
                          Geographic View of Loan Proposals
                        </h1>
                        {this.state.creditLines ? (
                          <MapView creditLines={this.state.creditLines} />
                        ) : (
                          <CircularProgress />
                        )}
                      </>
                    )}
                    <DataCard
                      title="Loan Application Statistics"
                      style={{ marginBottom: "2rem" }}
                    >
                      {this.state.statistics ? (
                        <Chart
                          options={{
                            chart: {
                              width: "1000px",
                              type: "pie",
                            },
                            labels: [
                              "Approved Loan Applications",
                              "Pending Loan Applications",
                              "Shortlisted Loan Applications",
                            ],
                            theme: {
                              // monochrome: {
                              //   enabled: true,
                              //   color: "#00AB55",
                              // },
                            },
                            colors: ["#00AB55", "#4198D7", "#fdc500"],
                            legend: {
                              show: true,
                              formatter: function (labelName, options) {
                                const labels = [
                                  "Approved Loan Applications",
                                  "Pending Loan Applications",
                                  "Shortlisted Loan Applications",
                                ];
                                const statisticsKeys = [
                                  "approved",
                                  "pending",
                                  "shortlisted",
                                ];
                                const label = labels[options.seriesIndex];
                                return formatChartLabel(
                                  label,
                                  this.state.statistics[
                                    statisticsKeys[options.seriesIndex]
                                  ]
                                );
                              }.bind(this),
                            },
                          }}
                          series={[
                            this.state.statistics.approved?.totalValue || 0,
                            this.state.statistics.pending?.totalValue || 0,
                            this.state.statistics.shortlisted?.totalValue || 0,
                          ]}
                          type="pie"
                          width="85%"
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </DataCard>
                    <div style={{ display: "flex" }}>
                      <DataCard
                        title="Number of Loan Applications"
                        value={this.state.creditLines?.length || ""}
                        style={{ marginRight: "2rem" }}
                        center
                      >
                        {!this.state.creditLines && <CircularProgress />}
                      </DataCard>
                      <DataCard
                        title="Value of Loan Applications"
                        value={formatNumber(
                          this.state.statistics
                            ? (this.state.statistics.pending?.totalValue || 0) +
                                (this.state.statistics.pending?.shortlisted ||
                                  0)
                            : ""
                        )}
                        style={{ marginRight: "2rem" }}
                        center
                      >
                        {!this.state.creditLines && <CircularProgress />}
                      </DataCard>
                      <DataCard
                        title="Number of Shortlisted Applications"
                        value={this.state.statistics?.shortlisted?.number || ""}
                        center
                      >
                        {!this.state.statistics && <CircularProgress />}
                      </DataCard>
                    </div>
                    <div style={{ display: "flex", marginTop: "2rem" }}>
                      <DataCard
                        title="Value of Shortlisted Applications"
                        value={formatNumber(
                          this.state.statistics
                            ? this.state.statistics.shortlisted?.totalValue
                            : this.state.shortlistedCreditLines
                            ? String(
                                this.state.shortlistedCreditLines?.reduce(
                                  (acc, b) => acc + Number(b.loan_amount) || 0,
                                  0
                                )
                              )
                            : ""
                        )}
                        style={{ marginRight: "2rem" }}
                        center
                      >
                        {!this.state.shortlistedCreditLines &&
                          !this.state.statistics && <CircularProgress />}
                      </DataCard>

                      <DataCard
                        title="Number of Pending Applications"
                        value={this.state.creditLines?.length || ""}
                        style={{ marginRight: "2rem" }}
                        center
                      >
                        {!this.state.creditLines && <CircularProgress />}
                      </DataCard>

                      <DataCard
                        title="Value of Pending Applications"
                        value={formatNumber(
                          this.state.statistics
                            ? this.state.statistics.pending?.totalValue
                            : this.state.creditLines
                            ? String(
                                this.state.creditLines?.reduce(
                                  (acc, b) => acc + Number(b.loan_amount) || 0,
                                  0
                                )
                              )
                            : ""
                        )}
                        center
                      >
                        {!this.state.creditLines && <CircularProgress />}
                      </DataCard>
                    </div>
                  </>
                )}
                )
                <DataCard
                  title={
                    this.user?.layout?.dashboard?.card_primary ===
                    "loan_applications"
                      ? "Recent Loan Proposals"
                      : "Approved Credit Lines"
                  }
                  className="Dashboard__table-card"
                >
                  <p className="Dashboard__table-text">
                    {this.user?.layout?.dashboard?.card_primary ===
                    "loan_applications"
                      ? "Please review each loan proposal and either approve or deny it. Click on an application to view all details."
                      : "This is a list of previously approved credit lines."}
                  </p>
                  <DataTable
                    tableData={
                      this.user?.layout?.dashboard?.card_primary !==
                      "approved_credit_lines"
                        ? this.state.creditLines
                        : this.state.approvedCreditLines
                    }
                    onClick={(id) =>
                      this.props.history.push(
                        `${
                          this.user?.layout.dashboard?.card_primary !==
                          "approved_credit_lines"
                            ? ""
                            : "/manage"
                        }/creditlines/${id}`
                      )
                    }
                  ></DataTable>
                </DataCard>
              </div>

              <div
                className="Dashboard__data-cards"
                style={{
                  display: this.user?.layout?.dashboard?.details_top
                    ? "none"
                    : "block",
                }}
              >
                <DataCard
                  title={
                    this.user?.customizations?.main_term === "Loan"
                      ? "Total value of loan proposals"
                      : "Total value of credit lines"
                  }
                  increase={"" + this.state.vendorsIncrease}
                  value={
                    // this.state.totalVendors
                    //   ?.toString()
                    //   .replace(/\B(?=(\d{3})+(?!\d))/g, ", ") || "..."
                    `₹ ${String(
                      this.state.approvedCreditLines?.reduce(
                        (acc, b) => acc + Number(b.initial_amount) || 0,
                        0
                      )
                    ).replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}`
                  }
                ></DataCard>
                {this.user?.layout?.dashboard?.card_secondary ===
                "loan_applications_count" ? (
                  <DataCard
                    title={"Pending Loan Applications"}
                    value={this.state.creditLines?.length}
                  ></DataCard>
                ) : (
                  <DataCard
                    title={`Total ${
                      this.user?.customizations?.main_term?.toLowerCase() ||
                      "credit line"
                    }s issued`}
                    increase={"" + this.state.creditLinesIncrease}
                    value={
                      this.state.totalCreditLines
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ", ") || "..."
                    }
                  ></DataCard>
                )}
              </div>
            </Grid>
          </div>
        </ContentWraper>
      </Protect>
    );
  }
}

const formatChartLabel = (label, statistics) => {
  return `${label} - ${formatNumber(statistics?.totalValue || 0)} (${
    statistics?.number || 0
  } application${
    statistics?.number > 1 && statistics?.number !== 0 ? "s" : ""
  })`;
};

export default withRouter(Dashboard);

// #003049
// #e4afff
