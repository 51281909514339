import React, { Component } from "react";

import Protect, { getUser } from "../../auth";
import { withRouter } from "react-router-dom";

import Sidebar from "../../layout/sidebar";
import ContentWraper from "../../layout/ContentWrapper";
import DataCard from "../../components/DataCard";
import DataTable from "../../components/DataTable";

import "./vendors.scss";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { DateRangePicker } from "react-date-range";
import PrimaryButton from "../../components/PrimaryButton";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangeSharp, DownloadRounded } from "@material-ui/icons";
import SecondaryButton from "../../components/SecondaryButton";
import { Loans } from "../../redux";
import constructRequest from "../../api/constructRequest";
import downloadLink from "../../utils/downloadLink";

class CreditLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditLines: [],
      reloading: false,
      selectedDateRange: {
        startDate: new Date(
          `${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`
        ),
        endDate: new Date(),
      },
      showDateRangeModal: false,
      dateRangeFilter: false,
      misLoading: false,
    };
    this.creditLinesPage = 1;
    this.loadCreditLines = this.loadCreditLines.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.fetchingNewPage = false;
    this.limit = 10;
    this.downloadLoansMis = this.downloadLoansMis.bind(this);
  }

  async loadCreditLines() {
    this.setState({
      reloading: true,
    });
    Loans.fetchLoans({
      sort: "-timestamp",
      limit: this.limit,
      populate: true,
      populateFields:
        '{"user": ["name"], "village": ["name"], "sentTo": ["name"]}',
      fields: "user,farmerName,village,timestamp,loanAmount,status,sentTo",
      status: this.loadSent ? "sent" : "pending",
      page: this.creditLinesPage,
      fetchNewPage: this.fetchingNewPage,
    });

    // const unsortedCreditLines = [];
    // const creditLinesInReviewUsersRef = db.collection("credit_lines_in_review");
    // const creditLinesInReviewUsers = await creditLinesInReviewUsersRef.get();
    // const userIds = [];
    // creditLinesInReviewUsers.forEach((user) => {
    //   userIds.push({ userId: user.data().user_id });
    // });

    // for (const { userId } of userIds) {
    //   const userRef = db.collection("users").doc(userId);
    //   const user = await userRef.get();

    //   const userCreditLinesRef = db
    //     .collection("credit_lines_in_review")
    //     .doc(userId)
    //     .collection("applications");
    //   const userCreditLines = await userCreditLinesRef.get();
    //   userCreditLines.forEach((creditLine) => {
    //     if (
    //       !creditLine.data().rejected &&
    //       !creditLine.data().approved &&
    //       !creditLine.data().shortlisted
    //     )
    //       unsortedCreditLines.push({
    //         user_id: creditLine.data().user_id || userId,
    //         ...user.data(),
    //         ...creditLine.data(),
    //         ...(creditLine.data().village_id
    //           ? { village_id: creditLine.data().village_id }
    //           : {}),
    //         ...(creditLine.data().phone_number
    //           ? { phone_number: creditLine.data().phone_number }
    //           : {}),
    //       });
    //   });
    // }
    // const creditLines = unsortedCreditLines.sort(
    //   (a, b) => b.application_timestamp - a.application_timestamp
    // );

    // localStorage.setItem("creditLines", JSON.stringify(creditLines));
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  async componentDidMount() {
    this.loadSent = !!new URLSearchParams(this.props.location?.search).get(
      "sent"
    );

    this.unsubscribe = Loans.store.subscribe(() => {
      // const loans = (Loans.store.getState().loans || {})[
      //   this.loadSent ? "sentLoans" : "loans"
      // ];
      const loans = Loans.store.getState().loans.loans;

      if (loans && Array.isArray(loans)) {
        this.setState({
          creditLines: loans,
          reloading: false,
        });
      }
    });

    this.loadCreditLines();

    this.user = await getUser();
  }

  async downloadLoansMis() {
    this.setState({
      misLoading: true,
    });

    try {
      const requestOptions = await constructRequest({
        method: "GET",
        authorized: true,
        resource: "analytics/loansStatusMis",
        query: {
          startDate: prompt("Enter start date (YYYY-mm-dd): "),
          endDate: prompt("Enter start date (YYYY-mm-dd): "),
        },
      });

      const response = await fetch(requestOptions.url, requestOptions);
      const result = await response.blob();
      const blobUrl = URL.createObjectURL(result);

      downloadLink(blobUrl, `${new Date().toISOString()}_Loans_MIS_Export.csv`);
    } catch (e) {
      alert(`There was an error: ${e.message}`);
    } finally {
      this.setState({ misLoading: false });
    }
  }

  loadMore() {
    this.creditLinesPage++;
    this.fetchingNewPage = true;
    // console.log(this.creditLinesPage);

    this.loadCreditLines();
  }

  render() {
    return (
      <Protect>
        <Sidebar />
        <ContentWraper>
          <div className="Vendors">
            <DataCard
              title="Recent loan proposals"
              className="Vendors__table-card"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <p className="Dashboard__table-text">
                  Please review each loan proposal and either approve or deny
                  it. Click on an application to view all details.
                </p>
                <IconButton
                  onClick={this.downloadLoansMis}
                  disabled={this.state.misLoading}
                >
                  {this.state.misLoading ? (
                    <CircularProgress />
                  ) : (
                    <DownloadRounded style={{ fontSize: "2.5rem" }} />
                  )}
                </IconButton>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: this.state.dateRangeFilter
                    ? "space-between"
                    : "flex-end",
                  alignItems: "center",
                }}
              >
                {this.state.dateRangeFilter && (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      outline: "none",
                      border: "none",
                      color: "orangered",
                      fontFamily: '"Be Vietnam", sans-serif',
                      fontSize: "1.4rem",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState({
                        selectedDateRange: {
                          startDate: new Date(
                            `${new Date().getFullYear()}-${
                              new Date().getMonth() + 1
                            }-1`
                          ),
                          endDate: new Date(),
                        },
                        showDateRangeModal: false,
                        dateRangeFilter: false,
                      })
                    }
                  >
                    Clear Date Filter
                  </button>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <PrimaryButton
                    onClick={async () => {
                      this.limit *= this.page;
                      this.page = 1;
                      this.fetchingNewPage = false;
                      await this.loadCreditLines();
                    }}
                    disabled={this.state.reloading}
                  >
                    {this.state.reloading ? (
                      <CircularProgress color="inherit" size="25px" />
                    ) : (
                      <img
                        src={window.getImage("reload-icon.svg")}
                        alt="Reload Icon"
                      />
                    )}
                  </PrimaryButton>
                  <IconButton
                    style={{
                      borderRadius: "0.5rem",
                      margin: "0 2rem",
                    }}
                    onClick={() => {
                      this.setState({
                        showDateRangeModal: true,
                      });
                    }}
                  >
                    <DateRangeSharp
                      style={{
                        fontSize: "2.5rem",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
              <Dialog
                open={this.state.showDateRangeModal}
                onClose={() => {
                  this.setState({
                    showDateRangeModal: false,
                  });
                }}
              >
                <DialogTitle>Choose Date Range</DialogTitle>
                <DialogContent>
                  <DateRangePicker
                    ranges={[
                      {
                        ...(this.state.selectedDateRangeTemp ||
                          this.state.selectedDateRange),
                        key: "selection",
                      },
                    ]}
                    onChange={(event) => {
                      this.setState({
                        selectedDateRangeTemp: {
                          startDate: event.selection.startDate,
                          endDate: event.selection.endDate,
                        },
                      });
                    }}
                    autoFocus={false}
                  />
                </DialogContent>
                <DialogActions>
                  <SecondaryButton
                    onClick={() => {
                      this.setState({
                        selectedDateRange: {
                          startDate: new Date(
                            `${new Date().getFullYear()}-${
                              new Date().getMonth() + 1
                            }-1`
                          ),
                          endDate: new Date(),
                        },
                        showDateRangeModal: false,
                        dateRangeFilter: false,
                      });
                    }}
                  >
                    Close
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => {
                      this.setState({
                        showDateRangeModal: false,
                        dateRangeFilter: true,
                        selectedDateRange:
                          this.state.selectedDateRangeTemp ||
                          this.state.selectedDateRange,
                      });
                    }}
                  >
                    Confirm
                  </PrimaryButton>
                </DialogActions>
              </Dialog>
              {this.state.creditLines ? (
                <DataTable
                  tableData={
                    this.state.dateRangeFilter
                      ? function () {
                          const startDate = Number(
                            this.state.selectedDateRange.startDate
                          );
                          const endDate = Number(
                            this.state.selectedDateRange.endDate
                          );
                          return this.state.creditLines.filter((creditLine) => {
                            const creditLineTime = Number(
                              new Date(creditLine.timestamp)
                            );

                            return (
                              creditLineTime >= startDate &&
                              creditLineTime <= endDate
                            );
                          });
                        }.bind(this)()
                      : this.state.creditLines
                  }
                  loadMore={this.loadMore}
                  forwarder={this.user?.loan_forwarder}
                ></DataTable>
              ) : (
                <CircularProgress />
              )}
            </DataCard>
          </div>
        </ContentWraper>
      </Protect>
    );
  }
}

export default withRouter(CreditLines);
