import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Chart from "react-apexcharts";

import Protect, { getUser } from "../../../auth";
import { auth, db, functions } from "../../../server";
import Sidebar from "../../../layout/sidebar";

import ContentWraper from "../../../layout/ContentWrapper";
import Accordion from "../../../components/Accordion";
import DataCard from "../../../components/DataCard";
import Grid from "../../../layout/Grid";
import PrimaryButton from "../../../components/PrimaryButton";
import SecondaryButton from "../../../components/SecondaryButton";
import {
  Tooltip,
  withStyles,
  Alert,
  Snackbar,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  LinearProgress,
  Box,
  Tabs,
  Tab,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from "@material-ui/core";

import "../creditLine.scss";
import TransactionCard from "../../ledgers/TransactionCard";
import {
  ImagesearchRoller,
  DashboardSharp,
  ListSharp,
} from "@material-ui/icons";

import AdvancedTable from "../../../components/AdvancedTable";
import PDFViewer from "./PDFViewer";

const DUMMY_YIELD_PREDICTIONS = [
  { x: "2021-06-01T00:00:00.000Z", y: 197 },
  { x: "2021-06-02T00:00:00.000Z", y: 198 },
  { x: "2021-06-03T00:00:00.000Z", y: 198 },
  { x: "2021-06-04T00:00:00.000Z", y: 200 },
  { x: "2021-06-05T00:00:00.000Z", y: 199 },
  { x: "2021-06-06T00:00:00.000Z", y: 199 },
  { x: "2021-06-07T00:00:00.000Z", y: 197 },
  { x: "2021-06-08T00:00:00.000Z", y: 197 },
  { x: "2021-06-09T00:00:00.000Z", y: 198 },
  { x: "2021-06-10T00:00:00.000Z", y: 197 },
  { x: "2021-06-11T00:00:00.000Z", y: 199 },
  { x: "2021-06-12T00:00:00.000Z", y: 199 },
  { x: "2021-06-13T00:00:00.000Z", y: 200 },
  { x: "2021-06-14T00:00:00.000Z", y: 198 },
  { x: "2021-06-15T00:00:00.000Z", y: 197 },
  { x: "2021-06-16T00:00:00.000Z", y: 199 },
  { x: "2021-06-17T00:00:00.000Z", y: 198 },
  { x: "2021-06-18T00:00:00.000Z", y: 198 },
  { x: "2021-06-19T00:00:00.000Z", y: 198 },
  { x: "2021-06-20T00:00:00.000Z", y: 197 },
  { x: "2021-06-21T00:00:00.000Z", y: 198 },
  { x: "2021-06-22T00:00:00.000Z", y: 199 },
  { x: "2021-06-23T00:00:00.000Z", y: 197 },
  { x: "2021-06-24T00:00:00.000Z", y: 197 },
  { x: "2021-06-25T00:00:00.000Z", y: 198 },
  { x: "2021-06-26T00:00:00.000Z", y: 198 },
  { x: "2021-06-27T00:00:00.000Z", y: 199 },
  { x: "2021-06-28T00:00:00.000Z", y: 198 },
  { x: "2021-06-29T00:00:00.000Z", y: 197 },
  { x: "2021-06-30T00:00:00.000Z", y: 199 },
].map((el) => ({ x: new Date(el.x), y: 200 - (200 - el.y) * 0.01 }));

const roundToDp = (num, dp = 0) => Math.round(num * 10 ** dp) / 10 ** dp;

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "1.4rem",
    fontFamily: "Be Vietnam, sans-serif",
  },
}))(Tooltip);

const StyledToggleButton = withStyles(() => ({
  root: {
    fontFamily: "Be Vietnam, sans-serif",
    fontSize: "1.2rem",
  },
}))(ToggleButton);

const labels = ["Working Capital", "Seeds", "Manure/Fertilizers", "Pesticides"];

const TabPanel = ({ children, index, value }) => {
  return (
    <div style={{ display: index !== value ? "none" : "block" }}>
      {children}
    </div>
  );
};

const FieldConditionCard = ({ variant, value }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "26px",
          boxShadow: "5px 5px 10px rgba(217, 217, 217, 0.31)",
          borderRadius: "50%",
          marginTop: "20px",
        }}
      >
        <img
          src={window.getImage(`${variant}-outline.svg`)}
          style={{
            width: "46px",
          }}
          alt={`${variant} Icon`}
        />
      </div>
      <h3
        style={{
          marginTop: "11px",
          textAlign: "center",
          color: "#212B36",
          fontFamily: "Be Vietnam",
          fontWeight: 400,
          fontSize: 16,
          marginLeft: "2px",
        }}
      >
        {value}
      </h3>
    </div>
  );
};

class ManageCreditLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditLine: null,
      creditLineRejected: false,
      creditLineApproved: false,
      creditLineApprovedLoan: false,
      alertMessage: "",
      modalOpen: false,
      additionalDetails: {
        expiryDate: null,
      },
      tab: 0,
      cropHealthMessage: "",
      waterMessage: "",
      nitrogenMessage: "",
      improvementScore: "",
      userFields: [],
      selectedField: null,
      idToken: "",
      transactions: null,
      viewType: 0,
    };
    this.handleDisable = this.handleDisable.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleApproveAsLoan = this.handleApproveAsLoan.bind(this);
    this.approvedCreditLine = this.props.location.search === "?active=true";
    this.user = JSON.parse(localStorage.getItem("bankerUser")) || null;
  }

  async handleDisable() {
    const creditLineId =
      this.props.match.params.creditLineId +
      (this.props.location?.search ? this.props.location.search : "");
    const creditLineRef = db
      .collection("credit_lines")
      .doc(this.state.creditLine.user_id)
      .collection("credit_lines")
      .doc(creditLineId);
    await creditLineRef.update({
      active: false,
    });

    this.setState({
      creditLineRejected: true,
      alertMessage: "This credit line has been successfully disabled!",
    });
  }

  async handleApprove() {
    const generateCreditLine = functions.httpsCallable("generateCreditLine");
    const { creditLine } = this.state;
    generateCreditLine({
      for: creditLine.user_id,
      ...creditLine,
      active: true,
      amount: creditLine.loan_amount,
      end_date: this.state.additionalDetails.expiryDate,
    })
      .then(
        async function () {
          const creditLineRef = db
            .collection("credit_lines")
            .doc(creditLine.user_id)
            .collection("credit_lines")
            .doc(creditLine.credit_line_id);
          await creditLineRef.update({
            approved: true,
          });
          this.setState((curState) => ({
            ...curState,
            modalOpen: false,
            creditLineApproved: {
              success: true,
            },
            alertMessage: "Credit line approved successfully!",
            alertSeverity: "success",
          }));
        }.bind(this)
      )
      .catch(
        function () {
          this.setState((curState) => ({
            ...curState,
            creditLineApproved: {
              success: false,
            },
            alertMessage:
              "There was an error approving the credit line! Please contact GreenSat!",
            alertSeverity: "error",
          }));
        }.bind(this)
      );
  }

  async handleApproveAsLoan() {
    const { creditLine } = this.state;

    const creditLineRef = db
      .collection("credit_lines")
      .doc(creditLine.user_id)
      .collection("credit_lines")
      .doc(creditLine.credit_line_id);
    await creditLineRef.update({
      approved: true,
    });

    this.setState((curState) => ({
      ...curState,
      modalOpen: false,
      creditLineApprovedLoan: true,
      alertMessage: "Credit line approved as a loan!",
      alertSeverity: "success",
    }));
  }

  handleInputChange(evt) {
    this.setState((curState) => ({
      ...curState,
      additionalDetails: {
        expiryDate: evt.target.value,
      },
    }));
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  handleModalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  async componentDidMount() {
    // const translateText = functions.httpsCallable("translateText");
    // const translationResult = await translateText({
    //   word: "HELLO",
    //   lang: "mr",
    // });

    // alert(JSON.stringify(translationResult));

    const getIdToken = function () {
      auth.onIdTokenChanged(
        function (user) {
          console.log(this);
          if (user) {
            user.getIdToken().then(
              function (idToken) {
                this.setState({
                  idToken,
                });
              }.bind(this)
            );
          }
        }.bind(this)
      );
      return new Promise((res, rej) => {
        auth.onAuthStateChanged(async (user) => {
          if (user) {
            const idToken = await user.getIdToken();
            res(idToken);
          } else {
            rej("");
          }
        });
      });
    }.bind(this);

    getUser().then((user) => {
      this.user = user;
    });

    let idToken = await getIdToken();

    let creditLineId = this.props.match.params.creditLineId;
    const hash = this.props.location?.hash;
    if (hash) {
      creditLineId = creditLineId + hash;
    }

    const creditLines = JSON.parse(localStorage.getItem("activeCreditLines"));
    console.log(creditLines);
    const creditLine = creditLines.find(
      (el) => el.credit_line_number === creditLineId
    );

    const creditLineRef = db
      .collection("credit_lines_in_review")
      .doc(creditLine.user_id)
      .collection("applications")
      .doc(creditLineId);
    let creditLineDoc = localStorage.getItem(
      `CreditLine__${creditLine.user_id}-${creditLineId}`
    );
    if (creditLineDoc) {
      creditLineDoc = { id: creditLineId, ...JSON.parse(creditLineDoc) };
    } else {
      creditLineDoc = (await creditLineRef.get()).data();
      creditLineDoc = { ...creditLineDoc, id: creditLineId };
      localStorage.setItem(
        `CreditLine__${creditLine.user_id}-${creditLineId}`,
        JSON.stringify(creditLineDoc)
      );
    }

    this.setState({
      creditLine: {
        ...creditLine,
        ...creditLineDoc,
      },
    });

    console.log(creditLine);

    const fieldsRef = db
      .collection("fields")
      .doc(creditLine.user_id)
      .collection("fields");
    const fieldsSnap = await fieldsRef.get();
    const userFields = [];
    fieldsSnap.forEach((field) => userFields.push(field.data()));
    const transactionsSnap = await db
      .collectionGroup("transactions")
      .where("credit_line_id", "==", creditLineDoc.id)
      .get();

    this.setState({
      userFields,
      idToken,
      transactions: transactionsSnap.docs.map((doc) => doc.data()),
    });
  }

  async setSelectedField(selectedField) {
    this.setState({
      selectedField,
      nitrogenMessage: null,
      waterMessage: null,
      cropHealthMessage: null,
      improvementScore: null,
    });
    let generateIndexAnalysisResponse;

    if (
      localStorage.getItem("generateIndexAnalysisResponseLastSet") ===
        new Date().toDateString() &&
      localStorage.getItem(`generateIndexAnalysisResponse-${selectedField}`)
    ) {
      generateIndexAnalysisResponse = JSON.parse(
        localStorage.getItem(`generateIndexAnalysisResponse-${selectedField}`)
      );
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const rawRequest = JSON.stringify({
        field_id: "gvrunhk23cfaopmp",
        user_id: "GaCxpjvGBgSR9zffGceu6XWEs2g1",
        index: ["NDRE", "NDVI", "NDMI"],
        token: this.state.idToken,
        password: "GenerateIndexAnalysis@GreenSat123$",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: rawRequest,
        redirect: "follow",
      };

      let result = await fetch(
        "https://us-central1-greensat-9087a.cloudfunctions.net/generateIndexAnalysisHTTP",
        requestOptions
      );

      result = await result.json();
      console.log(result);

      if (!String(result?.statusCode).startsWith("2")) return;
      generateIndexAnalysisResponse = result.data;

      localStorage.setItem(
        `generateIndexAnalysisResponse-${selectedField}`,
        JSON.stringify(result.data)
      );
      localStorage.setItem(
        "generateIndexAnalysisResponseLastSet",
        new Date().toDateString()
      );
    }

    (function () {
      if (
        !generateIndexAnalysisResponse ||
        !generateIndexAnalysisResponse.analyses
      )
        return;

      const interpretations = {};
      generateIndexAnalysisResponse.analyses.forEach((analysis) => {
        interpretations[analysis.index] = analysis.indexInterpretation;
      });

      console.log(interpretations);

      const waterMessage = interpretations.NDMI.message.includes("wet")
        ? "Wet Soil"
        : interpretations["NDMI"].message.includes("dry")
        ? "Dry Soil"
        : "Low water stress";
      const nitrogenMessage = interpretations.NDRE.message.includes(
        "slightly low"
      )
        ? "Low Soil Nitrogen"
        : "Optimum Soil Nitrogen";
      const cropHealthMessage = generateIndexAnalysisResponse.analyses.find(
        (analysis) => analysis.index === "NDVI"
      )?.analysis;
      let improvementScore = 10;

      interpretations.NDMI.improvement && improvementScore--;
      interpretations.NDRE.improvement && improvementScore--;

      generateIndexAnalysisResponse.values.indexes.NDVI.average < 0.33
        ? (improvementScore -= 8)
        : generateIndexAnalysisResponse.values.indexes.NDVI.average < 0.66 &&
          (improvementScore -= 3);
      // improvementScore = improvementScore;
      this.setState({
        waterMessage,
        nitrogenMessage,
        cropHealthMessage,
        improvementScore,
      });
    }.bind(this)());
  }

  render() {
    console.log(this.state.userFields[0]?.field_id);
    if (!this.state.creditLine) {
      return (
        <Protect>
          <Sidebar />
          <ContentWraper>
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </ContentWraper>
        </Protect>
      );
    }

    const { creditLine } = this.state;
    console.log(process.env);

    return (
      <Protect>
        <Sidebar />
        <ContentWraper>
          {(this.state.creditLineRejected ||
            (this.state.creditLineApproved &&
              this.state.creditLineApproved.success) ||
            this.state.creditLineApprovedLoan) && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              autoHideDuration={1000}
              message="Hello World!"
              key="CreditLineSnackbarError"
              id="CreditLineSnackbarError"
              className="LoginStatusSnackbar"
            >
              <Alert
                severity={this.state.alertSeverity || "error"}
                onClose={function () {
                  this.setState({
                    creditLineRejected: false,
                    creditLineApproved: false,
                    creditLineApprovedLoan: false,
                  });
                }.bind(this)}
              >
                {this.state.alertMessage}
              </Alert>
            </Snackbar>
          )}
          <Dialog
            open={this.state.modalOpen || false}
            onClose={this.handleModalClose}
          >
            <DialogTitle>Credit Line - Additional Details</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please fill in these additional details for approving the credit
                line.
              </DialogContentText>
              <form action="">
                <TextField
                  autoFocus
                  fullWidth
                  type="date"
                  margin="dense"
                  variant="outlined"
                  label="Credit Line Expiry Date"
                  required
                  name="expiryDate"
                  onChange={this.handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.additionalDetails.expiryDate}
                />
              </form>
            </DialogContent>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "0 1.7rem",
              }}
            >
              <DialogActions>
                <SecondaryButton
                  onClick={this.handleModalClose}
                  color="inherit"
                  bg
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={this.handleApprove}
                  variant="contained"
                  style={{
                    marginLeft: "2rem",
                  }}
                >
                  Approve
                </PrimaryButton>
              </DialogActions>
            </div>
          </Dialog>
          {!this.approvedCreditLine && (
            <div
              className="Credit-line__action-bar"
              style={{
                justifyContent: "space-between",
              }}
            >
              <Tabs
                value={this.state.tab}
                onChange={(_, newTab) => {
                  this.setState({
                    tab: newTab,
                  });
                }}
                aria-label="Credit Line Tabs"
              >
                <Tab
                  label="Crop Monitoring"
                  style={{
                    fontSize: "1.4rem",
                  }}
                />
                <Tab
                  label="Predictions"
                  style={{
                    fontSize: "1.4rem",
                    marginLeft: "3rem",
                  }}
                />
                <Tab
                  label="Application Details"
                  style={{
                    fontSize: "1.4rem",
                    marginLeft: "3rem",
                  }}
                />
              </Tabs>
              <StyledTooltip
                title="Disabling an active credit line prevents the farmer from using it for any purchases. For a complete deletion for any reason, please contact GreenSat."
                placement="right"
              >
                <div>
                  <PrimaryButton
                    id="reject"
                    secondary
                    className="Credit-line__reject"
                    onClick={this.handleDisable}
                  >
                    Disable
                  </PrimaryButton>
                </div>
              </StyledTooltip>
            </div>
          )}
          <div className="Credit-line">
            {this.user?.layout?.manage_credit_line?.used_amount !== false && (
              <DataCard
                title={`Loan Amount: ${this.state.creditLine.loan_amount}`}
                className="Credit-line__loan-amount"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Box width="50%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        ((this.state.creditLine.loan_amount -
                          this.state.creditLine.balance) /
                          this.state.creditLine.loan_amount) *
                        100
                      }
                      style={{
                        height: "20px",
                        borderRadius: "15px",
                      }}
                    />
                  </Box>
                  <Box minWidth={35} marginTop={-0.99} marginLeft={5}>
                    <h3
                      style={{
                        textAlign: "right",
                        fontSize: "13px",
                        opacity: 0.7,
                      }}
                    >
                      ₹{" "}
                      {this.state.creditLine.loan_amount -
                        this.state.creditLine.balance}{" "}
                      / {this.state.creditLine.loan_amount} used
                    </h3>
                  </Box>
                </Box>
              </DataCard>
            )}
            <TabPanel value={this.state.tab} index={0}>
              <DataCard
                title="Crop Health"
                value={`${this.state.improvementScore || "..."}/10`}
                style={{
                  marginTop: "2.5rem",
                  marginBottom: "2rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 32,
                    right: 40,
                  }}
                >
                  <InputLabel
                    id="field"
                    style={{
                      textAlign: "right",
                    }}
                  >
                    Field
                  </InputLabel>
                  <Select
                    id="field"
                    label="Field"
                    labelId="field"
                    value={this.state.selectedField}
                    placeholder="Field"
                    onChange={({ target: { value } }) =>
                      this.setSelectedField(value)
                    }
                    displayEmpty
                    {...(!this.state.selectedField
                      ? {
                          renderValue: () => (
                            <MenuItem>Select a Field</MenuItem>
                          ),
                        }
                      : {})}
                  >
                    {this.state.userFields.map((field, i) => {
                      return (
                        // <StyledTooltip
                        //   title={`Crop - ${field.crop_name}; Sowing Date - ${field.sowing_date}`}
                        //   placement="left"
                        //   onClick={() =>
                        //     this.setSelectedField("h1v2jjfg02u11iec")
                        //   }
                        // >

                        <MenuItem value={field?.field_id}>
                          Field {i + 1} (Crop - {field.crop_name}; Sowing Date -{" "}
                          {field.sowing_date})
                        </MenuItem>

                        // </StyledTooltip>
                      );
                    })}
                  </Select>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "30px",
                  }}
                >
                  {this.state.selectedField ? (
                    <>
                      <FieldConditionCard
                        variant="leaf"
                        value={
                          this.state.cropHealthMessage || <CircularProgress />
                        }
                      />
                      <FieldConditionCard
                        variant="fertilizer"
                        value={
                          this.state.nitrogenMessage || <CircularProgress />
                        }
                      />
                      <FieldConditionCard
                        variant="water"
                        value={this.state.waterMessage || <CircularProgress />}
                      />
                    </>
                  ) : (
                    <h3
                      style={{
                        marginTop: 20,
                        opacity: 0.7,
                        textAlign: "center",

                        width: "100%",
                      }}
                    >
                      Please select a field!
                    </h3>
                  )}
                </div>
              </DataCard>
              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={
                    "https://doc-0k-78-apps-viewer.googleusercontent.com/viewer/secure/pdf/7hvmbeq24c73v38hqavqolkh3phoes8d/k0cn3t8vmb3lqb97edkk2jqnv236mr7t/1622487075000/drive/04544551742228603175/ACFrOgCzPcJAiAI7DNKDRAdBQT7HNX-c2pUg8zFThEfbri77FztO7q2r-xN_ikHvuaCzKgzIePDDvF8LCV7BhSZCE6JzI5s5LCU012mqfUClhm0HSuWQiLm87dUhxqyCchDs4_YLsULxxj5uQ0De?print=true&nonce=lk1o9thoor8he&user=04544551742228603175&hash=uiev642uk8r5702kqv1kskqomdrjlqsb"
                  }
                  plugins={[defaultLayoutPluginInstance]}
                  defaultScale={SpecialZoomLevel.PageFit}
                />
              </Worker> */}
              <PDFViewer url="https://firebasestorage.googleapis.com/v0/b/greensat-9087a.appspot.com/o/crop_analysis_reports%2FSatya%20Narayan%20Crop%20Analysis%20(13-01-2021)%20(1)%20(2).pdf?alt=media&token=cc2bcef6-1ef2-4ca5-9395-2a6c4606a659" />
              {/* <DataCard
                title="Credit Score"
                value={`${this.state.creditLine?.creditScore || "560"} / 900`}
                style={{
                  marginTop: 25,
                  width: "35%",
                  float: "left",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: 30,
                    top: "50%",
                    transform: "translateY(-50%)",
                    border: "3px solid #47B770",
                    padding: "1.4rem 1rem",
                    borderRadius: "50%",
                  }}
                >
                  <img
                    src="/img/card-outline.svg"
                    style={{
                      width: 35,
                    }}
                    alt="Card Icon"
                  />
                </div>
              </DataCard> */}
              {/* <DataCard
                title="Top Product Bought"
                value={this.state.creditLine.topProduct || "Fertilizer"}
                style={{
                  marginTop: 25,
                  marginLeft: 24,
                  width: "calc(65% - 24px)",
                  float: "left",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: 30,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <img
                    src="/img/cart-outline.svg"
                    style={{
                      width: 63.5,
                    }}
                    alt="Cart Icon"
                  />
                </div>
              </DataCard> */}
              {this.user?.layout?.approved_loans?.transactions ===
              false ? null : (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: "2rem",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Be Vietnam, sans-serif",
                        fontWeight: 500,
                        color: "#212B36",
                        fontSize: "2rem",
                      }}
                    >
                      Transactions
                    </h3>
                    <ToggleButtonGroup
                      value={this.state.viewType}
                      onChange={(_, newViewType) => {
                        this.setState({
                          viewType: newViewType,
                        });
                      }}
                      exclusive
                    >
                      <StyledToggleButton value={0}>
                        <DashboardSharp />
                      </StyledToggleButton>
                      <StyledToggleButton value={1}>
                        <ListSharp />
                      </StyledToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexWrap: "wrap",
                      paddingLeft: "1rem",
                      paddingTop: "1rem",
                    }}
                  >
                    {this.state.viewType === 0 ? (
                      this.state.transactions ? (
                        this.state.transactions.map((transaction) => (
                          <TransactionCard
                            transactionID={transaction.transaction_short_id}
                            key={transaction.transaction_id}
                            transactionAmount={transaction.transaction_amount}
                            date={new Date(transaction.date.seconds * 1000)}
                            name={transaction.buyer.name}
                            category={transaction.category}
                            invoice={transaction.invoice}
                          />
                        ))
                      ) : (
                        <CircularProgress />
                      )
                    ) : (
                      <div style={{ paddingBottom: "4rem", width: "75vw" }}>
                        {this.state.transactions ? (
                          <AdvancedTable
                            columns={[
                              { id: "transaction_id", label: "Transaction ID" },
                              {
                                id: "transaction_amount",
                                label: "Transaction Amount",
                              },
                              { id: "name", label: "Name" },
                              { id: "date", label: "Date" },
                              { id: "category", label: "Category" },
                            ]}
                            data={this.state.transactions.map(
                              (transaction) => ({
                                ...transaction,
                                transaction_id:
                                  transaction.transaction_short_id,
                                name: transaction.buyer.name,
                                date: new Date(
                                  transaction.date.seconds * 1000
                                ).toDateString(),
                                key: transaction.transaction_id,
                              })
                            )}
                          />
                        ) : (
                          <CircularProgress />
                        )}
                      </div>
                    )}
                    {this.state.transactions?.length === 0 && (
                      <h1
                        style={{
                          fontFamily: "Be Vietnam, sans-serif",
                          color: "#212B36",
                          fontWeight: 500,
                        }}
                      >
                        This user has made no transactions yet!
                      </h1>
                    )}
                  </div>
                </>
              )}
              {this.user?.layout?.approved_loans?.cost_allocation ===
              false ? null : (
                <DataCard
                  title="Cost allocation"
                  style={{ height: "38rem", marginTop: "3rem" }}
                >
                  <div
                    style={{
                      width: "50%",
                      height: "100%",
                      paddingTop: "2rem",
                    }}
                  >
                    {this.state.creditLine.no_cost_allocation ? (
                      <h1 style={{ fontSize: "2rem", fontWeight: "400" }}>
                        This user has no cost allocation (no hard limits)!
                      </h1>
                    ) : (
                      <Chart
                        options={{
                          chart: {
                            width: "1000px",
                            type: "pie",
                          },
                          labels,

                          legend: {
                            show: true,
                          },
                          dataLabels: {
                            formatter: function (label) {
                              if (!window.labelIndex) window.labelIndex = 0;
                              if (window.labelIndex >= labels.length)
                                window.labelIndex = 0;

                              const labelText = labels[window.labelIndex];
                              window.labelIndex++;

                              return `${labelText} - ${label}%`;
                            },
                          },
                        }}
                        series={[40, 10, 35, 15].map(
                          function (el) {
                            return (
                              (el / 100) *
                              (this.state.creditLine?.loan_amount || 1)
                            );
                          }.bind(this)
                        )}
                        type="pie"
                        width="500px"
                      />
                    )}
                  </div>
                </DataCard>
              )}
            </TabPanel>
            <TabPanel value={this.state.tab} index={2}>
              <Grid className="Credit-line__grid" cols={2} rows={4}>
                <DataCard title="Aadhaar Card">
                  <h4 className="Credit-line__status">Unverified</h4>
                  <h2 className="card-value">
                    {`${creditLine.aadhar_card_number?.slice(0, 2)}${"X".repeat(
                      8
                    )}${creditLine.aadhar_card_number?.slice(8)}`}
                  </h2>
                  <img
                    src={creditLine.aadhar_scan.aadhar_front}
                    alt=""
                    className="Credit-line__card-img"
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = creditLine.aadhar_scan.aadhar_front;
                      link.target = "_blank";
                      link.rel = "noopener";
                      link.click();
                    }}
                  />
                </DataCard>
                <DataCard title="PAN Card">
                  <h4 className="Credit-line__status Credit-line__status--verified">
                    Verified
                  </h4>
                  <h2 className="card-value">
                    {creditLine.pan_card_number.slice(0, 2) +
                      "X".repeat(4) +
                      creditLine.pan_card_number.slice(6)}
                  </h2>
                  <img
                    src={creditLine.pan_scan}
                    alt=""
                    className="Credit-line__card-img"
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = creditLine.pan_scan;
                      link.target = "_blank";
                      link.rel = "noopener";
                      link.click();
                    }}
                  />
                </DataCard>
                <DataCard title="Annual Income">
                  <h4 className="Credit-line__status Credit-line__status--finance">
                    Financial
                  </h4>
                  <h2 className="card-value">
                    ₹{" "}
                    {creditLine.annual_income
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
                  </h2>
                </DataCard>
                <DataCard title="Land Holding">
                  <h4 className="Credit-line__status Credit-line__status--asset">
                    Asset
                  </h4>
                  <Accordion
                    accordions={[
                      {
                        value: "TOTAL_LAND_HOLDING",
                        heading: `${creditLine.total_land_area
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")} acres`,
                        detail: (
                          <>
                            <h4 className="Credit-line__detail-text">
                              <b>Leased land area</b>:{" "}
                              {creditLine.leased_land_area} acres
                            </h4>
                            <h4 className="Credit-line__detail-text">
                              <b>Shared land area</b>:{" "}
                              {creditLine.shared_land_area} acres
                            </h4>
                            <h4 className="Credit-line__detail-text">
                              <b>Owned land area</b>:{" "}
                              {creditLine.owned_land_area} acres
                            </h4>
                          </>
                        ),
                      },
                    ]}
                  />
                </DataCard>
                <DataCard title="Active Loans" gridColumn="1 / span 2">
                  <h4 className="Credit-line__status Credit-line__status--liabilities">
                    Liabilities
                  </h4>
                  <Accordion
                    accordions={creditLine.active_loans?.map((activeLoan) => {
                      return {
                        value: uuid(),
                        heading: `₹ ${activeLoan.loanAmount
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}`,
                        detail: (
                          <>
                            <h4 className="Credit-line__detail-text">
                              <b>Facilities</b>: {activeLoan.facilities}
                            </h4>
                            <h4 className="Credit-line__detail-text">
                              <b>Lender bank</b>: {activeLoan.lenderBank}
                            </h4>
                            <h4 className="Credit-line__detail-text">
                              <b>Loan type</b>: {activeLoan.loanType}
                            </h4>
                            <h4 className="Credit-line__detail-text">
                              <b>Overdues</b>:{" ₹ "}
                              {activeLoan.overdues
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ", ") || 0}
                            </h4>
                            <h4 className="Credit-line__detail-text">
                              <b>Remaining tenure</b>:{" "}
                              {activeLoan.remainingTenure || 0} years
                            </h4>
                          </>
                        ),
                      };
                    })}
                  />
                </DataCard>
                <DataCard title="Current Crop">
                  <h4 className="Credit-line__status Credit-line__status--crop">
                    CROP
                  </h4>
                  <h2 className="card-value">
                    <Accordion
                      accordions={[
                        {
                          heading: creditLine.current_crop_name,
                          value: "CURRENT_CROP",
                          detail: (
                            <>
                              <h4 className="Credit-line__detail-text">
                                <b>Growth Stage</b>:{" "}
                                {creditLine.current_crop_growth_stage}
                              </h4>
                              <h4 className="Credit-line__detail-text">
                                <b>Sowing Date</b>:{" "}
                                {new Date(
                                  creditLine.current_crop_sowing_date.seconds *
                                    1000
                                ).toDateString()}
                              </h4>
                            </>
                          ),
                        },
                      ]}
                    />
                  </h2>
                </DataCard>
                <DataCard title="Previous Crops (last 5 years)">
                  <h4 className="Credit-line__status Credit-line__status--crop">
                    CROP
                  </h4>
                  <h2 className="card-value">
                    <Accordion
                      accordions={creditLine.previous_crops.map((crop) => {
                        return {
                          heading: crop.cropName,
                          value: uuid(),
                          detail: (
                            <>
                              <h4 className="Credit-line__detail-text">
                                <b>Annual Produce</b>: {crop.annualProduce}{" "}
                                tonnes
                              </h4>
                              <h4 className="Credit-line__detail-text">
                                <b>Duration</b>: {crop.duration}
                              </h4>
                              <h4 className="Credit-line__detail-text">
                                <b>Total Value</b>: ₹{" "}
                                {crop.totalValue
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
                              </h4>
                              <h4 className="Credit-line__detail-text">
                                <b>Total Profit</b>: ₹{" "}
                                {crop.totalProfit
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
                              </h4>
                            </>
                          ),
                        };
                      })}
                    />
                  </h2>
                </DataCard>
                <DataCard title="Farmer Details" gridColumn="1 / span 2">
                  <h4 className="Credit-line__status Credit-line__status--personal">
                    Personal Details
                  </h4>
                  <h4 className="Credit-line__detail-text">
                    <b>Name</b>: {creditLine.farmer_name}
                  </h4>
                  <h4 className="Credit-line__detail-text">
                    <b>Phone Number</b>: {creditLine.phone_number}
                  </h4>
                  <h4 className="Credit-line__detail-text">
                    <b>Residential Address</b>: {creditLine.residential_address}
                  </h4>
                  <h4 className="Credit-line__detail-text">
                    <b>Village ID</b>: {creditLine.village_id}
                  </h4>
                  <h4 className="Credit-line__detail-text">
                    <b>Date of Birth</b>:{" "}
                    {new Date(
                      creditLine.date_of_birth.seconds * 1000
                    ).toLocaleDateString()}
                  </h4>
                  <h4 className="Credit-line__detail-text">
                    <b>Educational Qualification</b>:{" "}
                    {creditLine.educational_qualification}
                  </h4>
                </DataCard>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tab} index={1}>
              <DataCard title="Yield Prediction" style={{ marginTop: 20 }}>
                <Chart
                  options={{
                    chart: {
                      width: "1000px",
                      type: "line",
                    },
                    labels,
                    colors: ["#50E7A6", "#40A0FC", "#FFD700"],
                    legend: {
                      show: true,
                    },
                    xaxis: {
                      type: "datetime",
                    },
                    yaxis: {
                      title: {
                        text: "Expected Yield / tonnes",
                        rotate: -90,
                      },
                    },
                    stroke: {
                      curve: "smooth",
                    },
                  }}
                  series={[
                    {
                      name: "Projected Yield / tonnes",
                      data: DUMMY_YIELD_PREDICTIONS.map((el, i) =>
                        i > DUMMY_YIELD_PREDICTIONS.length - 5
                          ? { ...el, y: 0 }
                          : el
                      ),
                    },
                    {
                      name: "Observed Yield / tonnes",
                      data: DUMMY_YIELD_PREDICTIONS.map((el, i) => ({
                        ...el,
                        y:
                          i > DUMMY_YIELD_PREDICTIONS.length - 1
                            ? 0
                            : roundToDp(
                                el.y + 0.01 * (Math.random() > 0.5 ? 1 : -1),
                                2
                              ),
                      })),
                    },
                    {
                      name: "Ideal Yield / tonnes",
                      data: DUMMY_YIELD_PREDICTIONS.map((el) => ({
                        ...el,
                        y: 200,
                      })),
                    },
                  ]}
                  type="line"
                  width="500px"
                />
              </DataCard>
            </TabPanel>
          </div>
        </ContentWraper>
      </Protect>
    );
  }
}

export default withRouter(ManageCreditLine);
