import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loader from "@material-ui/core/CircularProgress";

import Protect from "../../../auth";

import { db } from "../../../server";

import Sidebar from "../../../layout/sidebar";
import ContentWraper from "../../../layout/ContentWrapper";
import DataCard from "../../../components/DataCard";
import { DataTableCustomisable } from "../../../components/DataTable";

import PrimaryButton from "../../../components/PrimaryButton";

import "../vendors.scss";
import { Loans } from "../../../redux";

class CreditLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditLines: JSON.parse(localStorage.getItem("activeCreditLines")) || [],
      loading: false,
    };
    this.loadCreditLines = this.loadCreditLines.bind(this);
  }

  async loadCreditLines() {
    this.setState({
      loading: true,
    });

    Loans.fetchLoans({
      sort: "-timestamp",
      limit: 10,
      populate: true,
      fields: "user,farmerName,village,timestamp,loanAmount,status",
      status: "approved",
    });

    // const creditLines = [];
    // const creditLinesInReviewUsersRef = db.collection("credit_lines");
    // const creditLinesInReviewUsers = await creditLinesInReviewUsersRef.get();
    // const userIds = [];
    // creditLinesInReviewUsers.forEach((user) => {
    //   console.log(user.data());
    //   userIds.push({ userId: user.data().user_id });
    // });

    // for (const { userId } of userIds) {
    //   const userRef = db.collection("users").doc(userId);
    //   const user = await userRef.get();
    //   console.log(user.data());

    //   const userCreditLinesRef = db
    //     .collection("credit_lines")
    //     .doc(userId)
    //     .collection("credit_lines");
    //   const userCreditLines = await userCreditLinesRef.get();
    //   userCreditLines.forEach((creditLine) => {
    //     if (!creditLine.data().rejected && !creditLine.data().approved)
    //       creditLines.push({
    //         user_id: userId,
    //         ...user.data(),
    //         ...creditLine.data(),
    //       });
    //   });
    // }

    // localStorage.setItem("activeCreditLines", JSON.stringify(creditLines));

    // this.setState({
    //   // creditLines,
    //   loading: false,
    // });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  componentDidMount() {
    this.unsubscribe = Loans.store.subscribe(() => {
      const state = Loans.store.getState().loans;

      this.setState({
        loading: false,
      });

      if (Array.isArray(state.approvedLoans)) {
        this.setState({
          creditLines: state.approvedLoans.filter((loan) => !!loan),
        });
      }
    });

    this.loadCreditLines();
  }

  render() {
    console.log(this.state.creditLines);
    return (
      <Protect>
        <Sidebar />
        <ContentWraper>
          <div className="Vendors">
            <DataCard
              title="Active Credit Lines"
              className="Vendors__table-card"
            >
              <p className="Dashboard__table-text">
                Please review each loan proposal and either approve or deny it.
                Click on an application to view all details.
              </p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PrimaryButton
                  onClick={async () => {
                    await this.loadCreditLines();
                  }}
                  disabled={this.state.loading}
                >
                  {this.state.loading ? (
                    <Loader color="inherit" size="25px" />
                  ) : (
                    <img
                      src={window.getImage("reload-icon.svg")}
                      alt="Reload Icon"
                    />
                  )}
                </PrimaryButton>
              </div>
              {this.state.creditLines ? (
                <DataTableCustomisable
                  tableData={this.state.creditLines}
                  columns={[
                    { label: "Name", key: "farmerName" },
                    { key: "village.name", label: "Village" },
                    { label: "End Date", key: "endDate" },
                  ]}
                  onClick={(creditLineId) =>
                    this.props.history.push(
                      `/manage/creditlines/${creditLineId}`
                    )
                  }
                ></DataTableCustomisable>
              ) : (
                <Loader />
              )}
            </DataCard>
          </div>
        </ContentWraper>
      </Protect>
    );
  }
}

export default withRouter(CreditLines);
