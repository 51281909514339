import React, { Component } from "react";
import styled from "styled-components";

import Alert from "@material-ui/core/Alert";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Snackbar from "@material-ui/core/Snackbar";

import { auth, db } from "../../server";

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import LogoIcon from "../../components/LogoIcon";

import { withRouter } from "react-router-dom";

import "./Login.scss";

const StyledCard = styled.div`
  width: 30vw;
  height: 100%;
  background-color: #fff;
  box-shadow: rgba(255, 255, 255, 0.5) -2rem -2rem 6rem,
    rgba(217, 217, 217, 38) 2rem 2rem 6rem;
  border-radius: 4.4rem;
  padding: 3.5rem 3.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      email: "",
      password: "",
      signInSuccess: false,
      signInError: "",
      open: false,
    };
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const userRef = db.collection("bankers").doc(authUser.uid);
        const user = await userRef.get();
        if (user.exists) this.props.history.push("/");
        else auth.signOut();
      }
    });
  }

  handleChange(evt) {
    this.setState({
      ...this.state,
      [evt.target.id]: evt.target.value,
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        this.setState({ signInSuccess: true, open: true });
        auth.onAuthStateChanged((user) => {
          user.uid && localStorage.setItem("uid", user.uid);
        });
      })
      .catch((e) => {
        this.setState({ signInError: e.message, open: true });
      });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  render() {
    const handleClose = () => {
      this.setState({ ...this.state, open: false });
    };

    let snackbar = this.state.open ? (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={this.state.open}
        onClose={handleClose}
        autoHideDuration={3000}
        message="Hello World!"
        key="LoginStatusSnackbar"
        id="LoginStatusSnackbar"
        className="LoginStatusSnackbar"
      >
        <Alert severity={`${this.state.signInSuccess ? "success" : "error"}`}>
          {this.state.signInSuccess
            ? "Sign in successful!"
            : this.state.signInError}
        </Alert>
      </Snackbar>
    ) : (
      ""
    );

    return (
      <div className="Login">
        {snackbar}
        <StyledCard>
          <div>
            <LogoIcon />
            <h1 className="Login__heading">Hi, Welcome Back!</h1>
          </div>
          <img
            src={window.getImage("login_illustration.svg")}
            alt="Login illustration"
            className="Login__img"
          />
        </StyledCard>
        <div className="Login__form-container">
          <form action="" className="Login__form" onSubmit={this.handleSubmit}>
            <div className="Login__form-header">
              <h1 className="Login__form-title">Sign in to GreenSat Banker</h1>
              <h3 className="Login__form-subtitle">
                Enter your details below.
              </h3>
            </div>
            <Alert severity="info" className="Login__form-alert">
              You must have a banker account
            </Alert>

            <TextField
              id="email"
              label="Email"
              onChange={this.handleChange}
              defaultValue={this.state.email}
              variant="outlined"
              color="primary"
              className="Login__form-input"
              classes={{
                root: "Login__form-input",
              }}
              type="email"
              required
            />

            <TextField
              id="password"
              label="Password"
              variant="outlined"
              onChange={this.handleChange}
              defaultValue={this.state.password}
              color="primary"
              className="Login__form-input"
              classes={{
                root: "Login__form-input",
              }}
              type={this.state.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
            <SecondaryButton className="Login__form-forgot-btn">
              Forgot password?
            </SecondaryButton>
            <PrimaryButton className="Login__form-btn">Login</PrimaryButton>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
