import React, { useRef, useState, useEffect } from "react";
import mapboxgl from "mapbox-gl";

import "./FieldVisit.css";
import ContextMenu from "../../components/ContextMenu";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Loans } from "../../redux";
import { useCallback } from "react";
import SecondaryButton from "../../components/SecondaryButton";
import PrimaryButton from "../../components/PrimaryButton";
import { storage } from "firebase";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3Vubnlyb2NrOCIsImEiOiJja2ZyOHJ6enkxaTR5MnhzNWJhbWdkeWJhIn0.OFi-bi2pObp6prX0yz4glQ";

let gotLocation = false;

const getLocation = () =>
  gotLocation
    ? null
    : new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition((position) =>
          position ? resolve(position) : reject()
        )
      );

const FieldVisit = ({ creditLine }) => {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const [lat, setLat] = useState(
    creditLine?.fieldVisitLocation?.coordinates[1]
  );
  const [lon, setLon] = useState(
    creditLine?.fieldVisitLocation?.coordinates[0]
  );
  const [zoom, setZoom] = useState(17);
  const [modalStatus, setModalStatus] = useState({
    show: false,
    type: null,
  });

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/satellite-v9",
        center: [lon || 78.9629, lat || 20.5937],
        zoom: zoom,
      });

      if (!lon || !lat) {
        getLocation().then((position) => {
          console.log(position);
          setLon(position?.coords?.longitude);
          setLat(position?.coords?.latitude);
        });
      }

      map.current.on("move", () => {
        setLat(map.current.getCenter().lat.toFixed(4));
        setLon(map.current.getCenter().lng.toFixed(4));
        // setLng(map.current.getCenter().lng.toFixed(4));
        // setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });

      map.current.on("idle", () => map.current.resize());
      // map.current.invalidateSize();

      setTimeout(() => map.current.resize(), 10);
    }
  });

  useEffect(() => {
    const [lon, lat] = creditLine.fieldVisitLocation?.coordinates || [];
    if (lat && lon) {
      const markerEl = document.createElement("div");
      markerEl.className = "fieldVisitMarker";
      markerEl.style.backgroundImage = "url('/img/mapMarker.svg')";

      new mapboxgl.Marker(markerEl)
        .setLngLat([lon, lat])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `
              <h2>${lat},${lon}</h2>
              <br />
              <div class="fieldVisitImagesContainer">
                <div class="fieldVisitImageContainer">
                <img src="${creditLine.fieldVisitImages?.field}" class="fieldVisitImage" onClick="window.openLink('${creditLine.fieldVisitImages?.field}')" />
                <h4>Field</h4>
                </div>
                <div class="fieldVisitImageContainer">
                <img src="${creditLine.fieldVisitImages?.boundary}" class="fieldVisitImage" onClick="window.openLink('${creditLine.fieldVisitImages?.boundary}')" />
                <h4>Boundary</h4>
                </div>
                <div class="fieldVisitImageContainer">
                  <img src="${creditLine.fieldVisitImages?.irrigation}" class="fieldVisitImage" onClick="window.openLink('${creditLine.fieldVisitImages?.irrigation}')" />
                  <h4>Irrigation</h4>
                </div>
              </div>
            `
            )
        )
        .addTo(map.current);
    }
  }, [creditLine]);

  useEffect(() => {
    setLat(creditLine?.fieldVisitLocation?.coordinates[1]);
    setLon(creditLine?.fieldVisitLocation?.coordinates[0]);
  }, [creditLine]);

  const onSubmit = useCallback(async () => {
    const data = modalStatus.data;

    setModalStatus({ ...modalStatus, loading: true });

    if (modalStatus.dataType === "image") {
      const ref = storage()
        .ref(creditLine.panCardNumber)
        .child(new Date().toISOString());

      await ref.put(data);
      const downloadURL = await ref.getDownloadURL();

      Loans.updateActiveLoan({
        _id: creditLine._id,
        [modalStatus.schemaField]: downloadURL,
      });
    } else {
      const coordinates = [
        Number(data.split(",")[1]),
        Number(data.split(",")[0]),
      ];

      Loans.updateActiveLoan({
        _id: creditLine._id,
        [modalStatus.schemaField]: coordinates,
      });
    }

    setModalStatus({ ...modalStatus, loading: false });
  }, [creditLine._id, creditLine.panCardNumber, modalStatus]);

  return (
    <div
      style={{
        width: "75vw",
        height: "100vh",
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Dialog
        open={modalStatus.show}
        onClose={() => setModalStatus({ show: false, type: null })}
      >
        <DialogTitle style={{ textTransform: "capitalize" }}>
          Update{" "}
          {modalStatus.type?.startsWith("replace")
            ? `Replace ${modalStatus.type.split("_")[1]} Image`
            : "Change Location"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the required details, then click 'update'.
          </DialogContentText>
          <div style={{ marginTop: "2rem" }}>
            <label
              htmlFor="updateInput"
              style={{
                fontFamily: '"Be Vietnam", sans-serif',
                fontSize: "1.5rem",
              }}
            >
              {modalStatus.type?.startsWith("replace")
                ? "Upload File"
                : "Enter Coordinates"}
            </label>
            <br />
            <input
              type={modalStatus.type?.startsWith("replace") ? "file" : "text"}
              id="updateInput"
              onChange={(event) => {
                setModalStatus({
                  ...modalStatus,
                  data: modalStatus.type?.startsWith("replace")
                    ? event.target.files[0]
                    : event.target.value,
                });
              }}
            />
          </div>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              width: "100%",
            }}
          >
            <SecondaryButton
              onClick={() => setModalStatus({ show: false, type: null })}
            >
              Cancel
            </SecondaryButton>

            <PrimaryButton onClick={onSubmit}>
              {modalStatus.loading ? (
                <CircularProgress color="secondary" />
              ) : (
                "Update"
              )}
            </PrimaryButton>
          </div>
        </DialogContent>
      </Dialog>
      <ContextMenu
        containerStyle={{ width: "75vw", height: "75vh" }}
        items={[
          {
            id: "replace_field_image",
            label: "Replace Field Image",
          },
          {
            id: "replace_irrigation_image",
            label: "Replace Irrigation Image",
          },
          {
            id: "replace_boundary_image",
            label: "Replace Boundary Image",
          },
          {
            id: "change_location",
            label: "Change Location",
          },
        ]}
        onClick={(id) => {
          switch (id) {
            case "replace_field_image":
              setModalStatus({
                show: true,
                type: id,
                title: "Replace Field Image",
                dataType: "image",
                schemaField: "fieldVisitImages.field",
              });
              break;
            case "replace_irrigation_image":
              setModalStatus({
                show: true,
                type: id,
                title: "Replace Irrigation Image",
                dataType: "image",
                schemaField: "fieldVisitImages.irrigation",
              });
              break;
            case "replace_boundary_image":
              setModalStatus({
                show: true,
                type: id,
                title: "Replace Boundary Image",
                dataType: "image",
                schemaField: "fieldVisitImages.boundary",
              });
              break;
            case "change_location":
              setModalStatus({
                show: true,
                type: id,
                title: "Replace Field Image",
                dataType: "text",
                schemaField: "fieldVisitLocation.coordinates",
              });
              break;
            default:
              break;
          }
        }}
      >
        <div ref={mapContainer} style={{ width: "75vw", height: "75vh" }}></div>
      </ContextMenu>
    </div>
  );
};

export default FieldVisit;
