import { ofType } from "redux-observable";
import {
  mergeMap,
  map,
  from,
  catchError,
  concatMap,
  withLatestFrom,
} from "rxjs";
import { ajax } from "rxjs/ajax";
import { combineEpics } from "redux-observable";
import constructRequest from "../../api/constructRequest";
import {
  setActiveLoan,
  setApprovedLoans,
  setLoans,
  setStatistics,
  setShortlistedLoans,
  moveToApproved,
  moveToShortlisted,
  addApprovedLoan,
  addShortlistedLoan,
  addLoan,
  showSuccessMessage,
  setActionSucceeded,
  moveToRejected,
  moveToSent,
  setSentLoans,
} from "./loans.actions";
import ApplicationError from "../../utils/ApplicationError";

const fetchLoansEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_LOANS"),
    mergeMap((action) => {
      const query = { ...action.payload };
      let fetchNewPage = query.fetchNewPage || false;
      delete query["fetchNewPage"];

      return from(
        constructRequest({
          resource: "finance/loans",
          query: query || {},
          authorized: true,
        })
      ).pipe(
        mergeMap((request) => {
          return ajax(request).pipe(
            map((response) => {
              if (response.status !== 200)
                throw new ApplicationError(
                  "There was an error fetching loans",
                  true
                );

              localStorage.setItem(
                action.payload.status === "approved"
                  ? "activeCreditLines"
                  : action.payload.status === "shortlisted"
                  ? "shortlistedCreditLines"
                  : "creditLines",
                JSON.stringify(response.response)
              );

              if (fetchNewPage) {
                return action.payload.status === "approved"
                  ? addApprovedLoan(response.response)
                  : action.payload.status === "shortlisted"
                  ? addShortlistedLoan(response.response)
                  : addLoan(response.response);
              } else {
                return action.payload.status === "approved"
                  ? setApprovedLoans(response.response)
                  : action.payload.status === "shortlisted"
                  ? setShortlistedLoans(response.response)
                  : action.payload.status === "sent"
                  ? setSentLoans(response.repsonse)
                  : setLoans(response.response);
              }
            })
          );
        })
      );
    })
  );

const fetchLoanDetailsEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_LOAN_DETAILS"),
    mergeMap((action) =>
      from(
        constructRequest({
          resource: `finance/loans/${action.payload}`,
          query: { populate: true },
          authorized: true,
        })
      ).pipe(
        mergeMap((request) =>
          ajax(request).pipe(
            map((response) => {
              if (response.status !== 200)
                throw new ApplicationError(
                  "There was an error fetching loan details",
                  true
                );

              return setActiveLoan(response.response);
            })
          )
        )
      )
    )
  );

const fetchStatisticsEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_STATISTICS"),
    mergeMap(() =>
      from(
        constructRequest({
          resource: "finance/loans/statistics",
          authorized: true,
        })
      ).pipe(
        mergeMap((request) =>
          ajax(request).pipe(
            map((response) => {
              if (response.status !== 200)
                throw new ApplicationError(
                  "There was an error fetching loan statistics",
                  true
                );

              localStorage.setItem(
                "statistics",
                JSON.stringify(response.response)
              );
              return setStatistics(response.response);
            })
          )
        )
      )
    )
  );

const handleStatusChangeEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_LOAN", "SHORTLIST_LOAN", "REJECT_LOAN", "SENT_LOAN"),
    mergeMap((action) =>
      from(
        constructRequest({
          resource: `finance/loans/${action.payload.id}`,
          action: "UPDATE",
          authorized: true,
          body: {
            status:
              action.type === "SENT_LOAN"
                ? "sent"
                : action.type === "APPROVE_LOAN"
                ? "approved"
                : action.type === "REJECT_LOAN"
                ? "rejected"
                : "shortlisted",
            sentTo: action.payload.sentToBanks,
          },
        })
      ).pipe(
        mergeMap((request) =>
          ajax(request).pipe(
            concatMap((response) => {
              if (response.status === 200) {
                return from([
                  setActionSucceeded(true),
                  action.type === "SENT_LOAN"
                    ? moveToSent(action.payload)
                    : action.type === "APPROVE_LOAN"
                    ? moveToApproved(action.payload)
                    : action.type === "REJECT_LOAN"
                    ? moveToRejected(action.payload)
                    : moveToShortlisted(action.payload),
                  showSuccessMessage(
                    `Loan ${
                      action.type === "SENT_LOAN"
                        ? "sent"
                        : action.type === "APPROVE_LOAN"
                        ? "approved"
                        : action.type === "REJECT_LOAN"
                        ? "rejected"
                        : "shortlisted"
                    } successfully!`
                  ),
                ]);
                // if (action.type === "APPROVE_LOAN")
                //   return moveToApproved(action.payload);
                // else return moveToShortlisted(action.payload);
              } else {
                throw new ApplicationError(
                  "Error shortlisting application!",
                  true
                );
              }
            }),
            catchError((error, source) => {
              if (error instanceof ApplicationError) {
                alert(error.message);
              } else {
                alert("There was an error");
              }

              return from([]);
            })
          )
        )
      )
    )
  );

const successMessageEpic = (action$) =>
  action$.pipe(
    ofType("SHOW_SUCCESS_MESSAGE"),
    mergeMap((action) => {
      return alert(action.payload);
    })
  );

const addRemarkEpic = (action$) =>
  action$.pipe(
    ofType("ADD_REMARK"),
    mergeMap((action) => {
      console.log(action);
      return from(
        constructRequest({
          resource: `finance/loans/${action.payload.id}/addRemark`,
          authorized: true,
          action: "UPDATE",
          body: {
            remark: action.payload.remark,
          },
        })
      ).pipe(
        mergeMap((request) =>
          ajax(request).pipe(
            mergeMap((_) =>
              from([setActionSucceeded(`addRemark: ${action.payload.remark}`)])
            )
          )
        )
      );
    })
  );

const updateActiveLoan = (action$) => {
  return action$.pipe(
    ofType("UPDATE_ACTIVE_LOAN"),
    mergeMap((action) => {
      const { _id, ...body } = action.payload;
      delete body["aadhaarCardNumber"];
      delete body["aadhaarScan"];
      delete body["creditReport"];
      delete body["currentCrop"];
      delete body["fieldVisitImages"];
      delete body["fieldVisitLocation"];
      delete body["panCardNumber"];
      delete body["panScan"];
      delete body["previousCrops"];
      delete body["referralCode"];
      delete body["residentialAddress"];
      delete body["satbaraScan"];
      delete body["timestamp"];
      delete body["user"];

      console.log(body);

      return from(
        constructRequest({
          resource: `finance/loans/${action.payload._id}`,
          authorized: true,
          action: "UPDATE",
          body,
        })
      ).pipe(
        mergeMap((request) =>
          ajax(request).pipe(
            mergeMap((_) =>
              from([
                setActionSucceeded(true),
                showSuccessMessage("Loan updated successfully!"),
              ])
            )
          )
        )
      );
    })
  );
};

const loansEpic = (action$, store$, dependencies) =>
  combineEpics(
    fetchLoansEpic,
    fetchLoanDetailsEpic,
    fetchStatisticsEpic,
    handleStatusChangeEpic,
    successMessageEpic,
    addRemarkEpic,
    updateActiveLoan
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      if (error instanceof ApplicationError && error.show) {
        alert(error.message);
      } else {
        console.log(error);
      }
      return source;
    })
  );

export default loansEpic;
