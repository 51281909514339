const openLink = (url) => {
  const link = document.createElement("a");
  link.target = "_blank";
  link.rel = "noopener";
  if (typeof url === "string") {
    link.href = url;
    link.click();
  } else {
    for (const href of url) {
      link.href = href;
      link.click();
    }
  }
};

window.openLink = openLink;

export default openLink;
