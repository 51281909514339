import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Tooltip,
  withStyles,
  ToggleButtonGroup,
  ToggleButton,
} from "@material-ui/core";

import { DashboardSharp, ListSharp } from "@material-ui/icons/";
import { useHistory, useParams } from "react-router-dom";

import { db } from "../../server";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import ProductCard from "./ProductCard";
import TransactionCard from "./TransactionCard";
import AdvancedTable from "../../components/AdvancedTable";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2.1rem 4rem;

  width: 100%;
  height: 45vh;
  background-image: linear-gradient(
      180deg,
      rgba(69, 86, 102, 0) 0%,
      rgba(33, 43, 54, 0.49) 100%
    ),
    url(/img/dummy_shop.png);
`;

const ShopName = styled.h2`
  font-family: "Be Vietnam", sans-serif;
  font-size: 4rem;
  color: #ffffff;
  font-weight: 800;
`;

const MainContainer = styled.div`
  padding: 3.5rem 4rem;
`;

const SectionSubtitle = styled.h3`
  font-family: "Be Vietnam", sans-serif;
  font-size: 2.2rem;
  color: #212b36;
  font-weight: 600;
`;

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "1.4rem",
    fontFamily: "Be Vietnam, sans-serif",
  },
}))(Tooltip);

const StyledToggleButton = withStyles(() => ({
  root: {
    fontFamily: "Be Vietnam, sans-serif",
    fontSize: "1.2rem",
  },
}))(ToggleButton);

const TILE_VIEW = 0,
  LIST_VIEW = 1;

function createData(transaction_id, transaction_amount, name, date, category) {
  return { transaction_id, transaction_amount, name, date, category };
}

const GROUPING_TABLE = [
  createData("acd", 120000, "Aarush", "20th May 2021", "Fertilizer"),
];

const COLUMNS = [
  { id: "transaction_id", label: "Transaction ID" },
  { id: "transaction_amount", label: "Transaction Amount" },
  { id: "name", label: "Name" },
  { id: "date", label: "Date" },
  { id: "category", label: "Category" },
];

const Vendors = () => {
  const [transactions, setTransactions] = useState(null);
  const [products, setProducts] = useState([]);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [copied, setCopied] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [viewType, setViewType] = useState(TILE_VIEW);
  const { vendorID } = useParams();

  const history = useHistory();

  useEffect(() => {
    db.collection("vendors")
      .doc(vendorID)
      .collection("transactions")
      .onSnapshot((querySnapshot) => {
        const transactions = querySnapshot.docs.map((doc) => doc.data());
        setTransactions(transactions);
      });

    db.collection("vendors")
      .doc(vendorID)
      .collection("products")
      .get()
      .then((querySnapshot) => {
        const products = querySnapshot.docs.map((doc) => doc.data());
        setProducts(products);
      });

    db.collection("vendors")
      .doc(vendorID)
      .get()
      .then((vendorDoc) => {
        setVendor(vendorDoc.data());
      });
  }, [vendorID]);

  return (
    <>
      <Dialog
        open={showContactDetails}
        onClose={() => setShowContactDetails(false)}
      >
        <DialogTitle>Contact Details - {vendor?.vendor_name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>Phone Number - </b>{" "}
            <StyledTooltip
              title={copied ? "Copied!" : "Copy"}
              placement="right"
              arrow
            >
              <u
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    vendor?.phone_number.slice(3) || ""
                  );
                  setCopied(true);
                }}
                onMouseOut={() => setCopied(false)}
              >
                {vendor?.phone_number.slice(3)}
              </u>
            </StyledTooltip>
            <br />
            <b>Address - </b>{" "}
            <StyledTooltip
              title={copied ? "Copied!" : "Copy"}
              placement="right"
              arrow
            >
              <u
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(vendor?.address || "");
                  setCopied(true);
                }}
                onMouseOut={() => setCopied(false)}
              >
                {vendor?.address}
              </u>
            </StyledTooltip>
            <br />
            <b>GST Number - </b>{" "}
            <StyledTooltip
              title={copied ? "Copied!" : "Copy"}
              placement="right"
              arrow
            >
              <u
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(vendor?.gst_number || "");
                  setCopied(true);
                }}
                onMouseOut={() => setCopied(false)}
              >
                {vendor?.gst_number}
              </u>
            </StyledTooltip>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={() => setShowContactDetails(false)}
            color="inherit"
            bg
          >
            Close
          </SecondaryButton>
        </DialogActions>
      </Dialog>
      <Header>
        <ShopName>{vendor?.vendor_name}</ShopName>
        <PrimaryButton
          style={{
            width: "11rem",
            borderRadius: "3rem",
            padding: "0.5rem 1.6rem",
            marginTop: "1rem",
          }}
          onClick={() => setShowContactDetails(true)}
        >
          Contact
        </PrimaryButton>
      </Header>
      <MainContainer>
        <div
          style={{
            marginTop: "-2rem",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "2rem",
          }}
        >
          <div>
            <SecondaryButton
              onClick={() => {
                const urls = transactions.map((transaction) => ({
                  url: transaction.invoice,
                  name: `${transaction.transaction_short_id}__${new Date(
                    transaction.date.seconds * 1000
                  ).toDateString()}.pdf`,
                }));
                const vendorId = vendor.vendor_short_id;

                if (window.mainProcess?.downloadInvoices) {
                  window.mainProcess.downloadInvoices(urls, vendorId);
                }
              }}
            >
              {vendor?.vendor_name} - Download All Invoices
            </SecondaryButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SectionSubtitle>
            Uncleared Transactions{" "}
            <span style={{ color: "#00AB55" }}>
              (INR{" "}
              {String(
                (() => {
                  let total = 0;
                  transactions?.forEach(
                    (transaction) => (total += transaction.transaction_amount)
                  );
                  return total;
                })()
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
              )
            </span>
          </SectionSubtitle>
          <ToggleButtonGroup
            value={viewType}
            onChange={(_, newViewType) => {
              setViewType(newViewType);
            }}
            exclusive
          >
            <StyledToggleButton value={TILE_VIEW}>
              <DashboardSharp />
            </StyledToggleButton>
            <StyledToggleButton value={LIST_VIEW}>
              <ListSharp />
            </StyledToggleButton>
          </ToggleButtonGroup>
        </div>
        {viewType === TILE_VIEW ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingTop: "1.9rem",
            }}
          >
            {transactions ? (
              transactions.map((transaction) => (
                <TransactionCard
                  transactionID={transaction.transaction_short_id}
                  transactionAmount={transaction.transaction_amount}
                  name={transaction.buyer.name}
                  date={new Date(transaction.date.seconds * 1000)}
                  category={transaction.category}
                  creditLineID={transaction.buyer.credit_line_id}
                  key={transaction.transaction_id}
                  invoice={transaction.invoice}
                />
              ))
            ) : (
              <CircularProgress />
            )}
          </div>
        ) : (
          <div style={{ paddingTop: "1.9rem" }}>
            {transactions ? (
              <AdvancedTable
                columns={COLUMNS}
                data={transactions.map((transaction) => ({
                  ...transaction,
                  transaction_id: transaction.transaction_short_id,
                  name: transaction.buyer.name,
                  date: new Date(
                    transaction.date.seconds * 1000
                  ).toDateString(),
                  key: transaction.transaction_id,
                }))}
                onClick={(transactionId) => {
                  const transaction = transactions.find(
                    (transaction) =>
                      transaction.transaction_id === transactionId
                  );
                  const link = document.createElement("a");
                  link.href = transaction.invoice;
                  link.target = "_blank";
                  link.rel = "noopener";
                  link.click();
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        )}
        <SectionSubtitle style={{ marginTop: "2rem", marginBottom: "1.8rem" }}>
          Products Offered{" "}
          <span style={{ color: "#00AB55" }}>
            ({`${products.length} product${products.length > 1 ? "s" : ""}`})
          </span>
        </SectionSubtitle>
        {products.length ? (
          products.map((product) => (
            <ProductCard
              imageSrc={product.product_image}
              productName={product.product_name}
              pricePerUnit={product.price_per_unit}
              unitName={product.unit_name}
              key={product.product_id}
            />
          ))
        ) : (
          <CircularProgress />
        )}
      </MainContainer>
    </>
  );
};

export default Vendors;
