import React, { Component } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import "./PrimaryButton.css";

const StyledButton = styled.button``;

class PrimaryButton extends Component {
  render() {
    return (
      <Button
        className={this.props.secondary ? "SecondaryButton" : "PrimaryButton"}
        onClick={function () {
          if (this.props.onClick) this.props.onClick();
        }.bind(this)}
        disabled={this.props.disabled || false}
        style={
          { ...this.props.style, opacity: this.props.disabled ? 0.75 : 1 } || {
            opacity: this.props.disabled ? 0.5 : 1,
          }
        }
      >
        {this.props.children}
      </Button>
    );
  }
}

export default PrimaryButton;
