import React, { Component } from "react";

class ContentWrapper extends Component {
  render() {
    return (
      <div
        style={{
          width: "75vw",
          position: "absolute",
          top: "0",
          right: "0",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ContentWrapper;
