import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );

class NavLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active:
        this.props.link !== "/"
          ? this.props.location?.pathname.startsWith(this.props.link)
          : this.props.location?.pathname === this.props.link,
    };
  }

  render() {
    return (
      <Link
        to={this.props.link}
        className={`Sidebar__nav-item ${
          this.state.active ? "Sidebar__nav-item--active" : ""
        }`}
      >
        <svg>
          <use
            xlinkHref={window.getImage(
              `${this.props.icon}${
                this.state.active ? "--active.svg" : ".svg"
              }#${snakeToCamel(this.props.icon)}${
                this.state.active ? "--active" : ""
              }`
            )}
          ></use>
        </svg>
        {this.props.label}
      </Link>
    );
  }
}

export default withRouter(NavLink);
