import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const ContextMenu = ({
  children,
  containerStyle = {},
  items = [],
  onClick,
}) => {
  const [menuStatus, setMenuStatus] = useState({
    show: false,
    x: 0,
    y: 0,
  });

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.oncontextmenu = (e) => {
        console.log(e);
        e.preventDefault();
        setMenuStatus({
          show: true,
          x: e.pageX + 10,
          y: e.pageY + 10,
        });
      };
    }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ ...containerStyle }}
      onClick={(e) => {
        if (
          e.pageX > menuStatus.x + 100 ||
          e.pageX < menuStatus.x - 100 ||
          e.pageY > menuStatus.y + 100 ||
          e.pageY < menuStatus.y - 100
        ) {
          setMenuStatus({
            show: false,
            x: 0,
            y: 0,
          });
        }
      }}
    >
      {children}
      {menuStatus.show && (
        <ContextMenuContainer x={menuStatus.x} y={menuStatus.y}>
          {items.map((item) => (
            <ContextMenuItem
              onClick={() => {
                onClick(item.id);
              }}
              key={item.id}
            >
              {item.label}
            </ContextMenuItem>
          ))}
        </ContextMenuContainer>
      )}
    </div>
  );
};

const ContextMenuContainer = styled.div`
  position: absolute;
  top: ${({ y }) => y}px;
  left: calc(${({ x }) => x}px - 25vw);

  display: flex;
  flex-direction: column;

  padding: 1rem 0;
  border-radius: 5px;
  background-color: #fff;
`;

const ContextMenuItem = styled.div`
  width: 100%;
  text-align: center;

  font-family: "Be Vietnam", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;

  padding: 0.5rem 3rem;

  cursor: pointer;

  &:hover {
    background-color: lightgrey;
  }
`;

export default ContextMenu;
