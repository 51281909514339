import React, { useState, useEffect, createContext } from "react";

const ImageContext = createContext(null);

const ImageProvider = ({ children }) => {
  const [appURL, setAppURL] = useState("");

  return (
    <ImageContext.Provider
      value={{
        appURL,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export default ImageProvider;
