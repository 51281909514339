export default function flattenObject(obj) {
  let flattenedObject = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value !== null && typeof value === "object") {
      flattenedObject = {
        ...flattenedObject,
        ...flattenObject(value),
      };
    } else {
      flattenedObject[key] = value;
    }
  }

  return flattenedObject;
}
