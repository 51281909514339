import React, { Component } from "react";
import styled from "styled-components";

import NavLink from "./NavLink";

import { getUser } from "../../auth";

import LogoIcon from "../../components/LogoIcon";
import PrimaryButton from "../../components/PrimaryButton";
import NavSection from "./NavSection";
import { CircularProgress } from "@material-ui/core";

const StyledNav = styled.nav`
  width: 25vw;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  padding: 3.4rem 2.5rem;
  overflow-y: scroll;

  border-right: 1px solid #e5e5e5;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .Sidebar__bank-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 7.6rem;

    padding: 1rem 8.8rem;

    background-color: #f3f6f8;

    border-radius: 1.2rem;

    margin-top: 6.6rem;
  }

  & .Sidebar__bank-logo {
    height: 100%;
  }

  & .Sidebar__bank-logo-loader {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
  }

  & .Sidebar__contact-section {
    width: 100%;
    background-color: #eff8f1;
    padding: 3.4rem 3.5rem;
    margin-top: 3rem;

    border-radius: 1.2rem;
  }

  & .Sidebar__contact-section-heading {
    font-family: "Be Vietnam", sans-serif;
    font-size: 1.9rem;
    color: #212b36;
    letter-spacing: 0.67px;
  }

  & .Sidebar__contact-section-text {
    font-family: "Be Vietnam", sans-serif;
    font-size: 1.2rem;
    color: #637381;
    letter-spacing: 0.45px;
    font-weight: 400;
    margin-top: 0.7rem;
  }

  & .Sidebar__contact-section button {
    margin-top: 4.5rem;
    width: 100%;
  }
`;

const NavItems = [
  {
    section_id: "general",
    section_title: "General",
    items: [
      {
        id: "dashboard",
        component: <NavLink icon="dashboard_icon" label="Dashboard" link="/" />,
      },
      {
        id: "credit_lines",
        component: (
          <NavLink
            icon="credit_lines_icon"
            label="Loan Proposals"
            link="/creditlines"
          />
        ),
      },
      {
        id: "sent_loans",
        component: (
          <NavLink
            icon="credit_lines_icon"
            label="Sent Proposals"
            link="/creditlines?sent=true"
          />
        ),
      },
      {
        id: "shortlisted_credit_lines",
        component: (
          <NavLink
            icon="shortlisted_icon"
            label="Shortlised Proposals"
            link="/shortlisted/creditlines"
          />
        ),
      },
    ],
    canBeDisabled: true,
  },
  {
    section_id: "management",
    section_title: "Management",
    items: [
      {
        id: "manage_credit_lines",
        component: (
          <NavLink
            icon="credit_lines_icon"
            label="Approved Loans"
            link="/manage/creditlines"
          />
        ),
      },
    ],
    canBeDisabled: true,
  },
  {
    section_id: "cashless_payments",
    section_title: "Cashless Payments",
    items: [
      {
        id: "master_ledger",
        component: (
          <NavLink icon="ledger-icon" label="Master Ledger" link="/ledgers" />
        ),
      },
    ],
    canBeDisabled: true,
  },
  {
    section_id: "account",
    section_title: "Account",
    items: [
      {
        id: "logout",
        component: (
          <NavLink icon="signout_icon" label="Log Out" link="/account/logout" />
        ),
      },
    ],
    canBeDisabled: false,
  },
];

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankLogoUrl: localStorage.getItem("bankLogoUrl"),
      // bankLogoUrl:
      //   "https://logos-world.net/wp-content/uploads/2020/11/HDFC-Bank-Emblem.png",
    };
    this.user =
      JSON.parse(localStorage.getItem("bankerUser") || "null") || null;
  }

  async componentDidMount() {
    this.user = await getUser();
    localStorage.setItem("bankerUser", JSON.stringify(this.user));
    this.setState({ bankLogoUrl: this.user.bank_logo_url });
    localStorage.setItem("bankLogoUrl", this.user.bank_logo_url);
  }

  render() {
    const layout = this.user?.layout;
    const disabled = layout?.sidebar?.disable || [];
    if (!this.user?.loan_forwarder) {
      disabled.push("sent_loans");
    }

    return (
      <StyledNav className="Sidebar">
        <LogoIcon />
        <div className="Sidebar__bank-logo-container">
          <img
            src={this.state.bankLogoUrl || ""}
            className={`Sidebar__bank-logo ${
              (!this.state.bankLogoUrl && "Sidebar__bank-logo-loader") || ""
            }`}
            alt="Bank Logo"
          />
        </div>
        {!this.user && <CircularProgress />}
        {NavItems.map((navSection, i) => {
          if (!layout) return null;

          if (
            disabled.filter(
              (page) =>
                typeof page === "object" &&
                page.section === navSection.section_id
            ).length > 0 &&
            navSection.canBeDisabled
          ) {
            return null;
          }

          return (
            <NavSection key={navSection.section_id}>
              <h3 className="Sidebar__nav-section-heading">
                {navSection.section_title}
              </h3>
              <div className="Sidebar__nav-items-container">
                {navSection.items.map((navItem, i) => {
                  if (
                    disabled.filter(
                      (page) => typeof page === "string" && page === navItem.id
                    ).length > 0
                  )
                    return null;

                  return (
                    <div key={`${navSection.section_id}__${i}`}>
                      {navItem.component}
                    </div>
                  );
                })}
              </div>
            </NavSection>
          );
        })}
        {/* <NavSection>
          <h3 className="Sidebar__nav-section-heading">General</h3>
          <div className="Sidebar__nav-items-container">
            <NavLink icon="dashboard_icon" label="Dashboard" link="/" />
            <NavLink icon="vendor_icon" label="Vendors" link="/vendors" />
            <NavLink
              icon="credit_lines_icon"
              label="Loan Proposals"
              link="/creditlines"
            />
          </div>
        </NavSection>

        <NavSection>
          <h3 className="Sidebar__nav-section-heading">Management</h3>
          <div className="Sidebar__nav-items-container">
            <NavLink
              icon="vendor_icon"
              label="Vendors"
              link="/manage/vendors"
            />
            <NavLink
              icon="farmer_icon"
              label="Farmers"
              link="/manage/farmers"
            />
            <NavLink
              icon="credit_lines_icon"
              label="Approved Loans"
              link="/manage/creditlines"
            />
          </div>
        </NavSection>
        <NavSection>
          <h3 className="Sidebar__nav-section-heading">Cashless Payments</h3>
          <div className="Sidebar__nav-items-container">
            <NavLink icon="ledger-icon" label="Master Ledger" link="/ledgers" />
          </div>
        </NavSection>
        <NavSection>
          <h3 className="Sidebar__nav-section-heading">Account</h3>
          <div className="Sidebar__nav-items-container">
            <NavLink
              icon="signout_icon"
              label="Log Out"
              link="/account/logout"
            />
          </div>
        </NavSection> */}
        <section className="Sidebar__contact-section">
          <h2 className="Sidebar__contact-section-heading">
            Need help? No problem!
          </h2>
          <p className="Sidebar__contact-section-text">
            Feel free to contact us and we will sort out any issues.
          </p>
          <PrimaryButton>Contact</PrimaryButton>
        </section>
      </StyledNav>
    );
  }
}

export default Sidebar;
