import firebase from "firebase";
import { FIREBASE_CONFIG } from "../env";

const firebaseConfig = FIREBASE_CONFIG;

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const functions = firebase.functions();
const auth = firebase.auth();
const storage = firebase.storage();
// const messaging = firebase.messaging();

async function saveToken(token) {
  // console.log("Saving token");
  // auth.onAuthStateChanged(async (user) => {
  //   if (!user?.uid) return;
  //   const bankerRef = db.collection("bankers").doc(user.uid);
  //   await bankerRef.update({
  //     fcm_token: token,
  //   });
  // });
}

export { db, functions, auth, storage, saveToken };
