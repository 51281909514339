import React, { Component } from "react";
import { auth } from "../../server";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedOut: false,
    };
  }

  async componentDidMount() {
    await auth.signOut();
    this.setState({
      signedOut: true,
    });
  }

  render() {
    if (this.state.signedOut) {
      return <Redirect to="/login" />;
    } else {
      return <CircularProgress />;
    }
  }
}

export default Logout;
