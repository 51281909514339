import React, { Component } from "react";
import Routes from "./routes";
import { withRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import store, { Loans } from "./redux";
import theme from "./Theme";
import ImageProvider from "./services/image/ImageProvider";

import "./App.css";

class Images {
  images = {};

  constructor(initialImages, callbackFn) {
    this.callbackFn = callbackFn;

    if (typeof initialImages === "object" && initialImages !== null) {
      this.images = initialImages;
    }
  }

  addImage(name, url) {
    this.images[name] = url;
    this.callbackFn();
  }
}

class App extends Component {
  componentDidMount() {
    if (typeof window.images === "object" && window.images !== null) {
      window.images = new Images(window.images, this.forceUpdate);
      if (window.onWindowImages) {
        window.onWindowImages();
      }
    } else {
      if (window.onWindowImages) {
        window.onWindowImages();
      }
      window.images = new Images({}, this.forceUpdate);
    }

    if (window.mainProcess?.onBack) {
      window.mainProcess.onBack(
        function () {
          if (this.props.history) {
            this.props.history.goBack();
          }
        }.bind(this)
      );
    }
    if (window.mainProcess?.onForward) {
      window.mainProcess.onForward(
        function () {
          if (this.props.history) {
            this.props.history.goForward();
          }
        }.bind(this)
      );
    }
    if (window.mainProcess?.onHome) {
      window.mainProcess.onHome(
        function () {
          if (this.props.history) {
            this.props.history.push("/");
          }
        }.bind(this)
      );
    }

    console.log(process.env);
  }

  render() {
    return (
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <ImageProvider>
            <Routes />
          </ImageProvider>
        </ThemeProvider>
      </ReduxProvider>
    );
  }
}

export default withRouter(App);
