const BASE_URL_PROD =
  "https://greensat-backend-revamped-tp45dve65a-uc.a.run.app";
const BASE_URL_DEV = "http://127.0.0.1:8080";

const constructUrl = (options) => {
  let url = `${BASE_URL_PROD}/${options.version || "v1"}/`;
  url += options.resource;
  if (options.query) {
    url += "?";
    for (const [key, value] of Object.entries(options.query)) {
      url += `${key}=${encodeURIComponent(value)}&`;
    }
    url = url.slice(0, -1);
  }

  return url;
};

export default constructUrl;
