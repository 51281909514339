import React, {
  Component,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { withRouter, Redirect } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import JSZip from "jszip";
import { saveAs } from "save-as";
import JSZipUtils from "jszip-utils";
import { useSelector } from "react-redux";

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import BookmarkIcon from "@material-ui/icons/BookmarkBorder";
import Protect from "../../auth";
import { auth, db, functions, storage } from "../../server";
import Sidebar from "../../layout/sidebar";

import ContentWraper from "../../layout/ContentWrapper";
import Accordion from "../../components/Accordion";
import DataCard from "../../components/DataCard";
import Grid from "../../layout/Grid";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import FinancialDetails from "./FinancialDetails";
import { getUser } from "../../auth";
import {
  Tooltip,
  withStyles,
  Alert,
  Snackbar,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tabs,
  Tab,
  CircularProgress,
} from "@material-ui/core";

import "./creditLine.scss";
import { Loans } from "../../redux";
import FieldVisit from "./FieldVisit";
import openLink from "../../utils/openLink";
import downloadLink from "../../utils/downloadLink";
import constructRequest from "../../api/constructRequest";

const zip = new JSZip();

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "1.4rem",
    fontFamily: "Be Vietnam, sans-serif",
  },
}))(Tooltip);

const TabPanel = ({ children, index, value }) => {
  return (
    <div style={{ display: index !== value ? "none" : "block" }}>
      {children}
    </div>
  );
};

const EditableDetail = ({
  label,
  value: defaultValue,
  loanKey: key,
  suffix,
  prefix,
  onUpdate,
}) => {
  const input = useRef(null);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <h4 className="Credit-line__detail-text">
        <b>{label}</b>:
      </h4>
      {prefix && <p className="Credit-line__detail-text">&nbsp;{prefix}</p>}
      <p
        className="Credit-line__detail-text"
        style={{ marginLeft: "5px" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={() => {
          const value = input.current?.innerText;
          if (value) {
            if (onUpdate) onUpdate(value);
            Loans.updateDetail(key, value);
          }
        }}
        ref={input}
      >
        {defaultValue}
      </p>
      {suffix && <p className="Credit-line__detail-text">&nbsp;{suffix}</p>}
    </div>
  );
};

const MaskedDetail = ({ detail }) => {
  const [showDetail, setShowDetail] = useState(false);

  const toggleShow = useCallback(() => {
    setShowDetail((state) => !state);
  }, [setShowDetail]);

  return (
    <h2
      className="card-value"
      style={{ cursor: "pointer" }}
      onClick={toggleShow}
    >
      {showDetail
        ? detail
        : `${detail.slice(0, 2)}${"X".repeat(6)}${detail.slice(
            -3,
            detail.length
          )}`}
    </h2>
  );
};

// @BUG app crashing (maximum call stack size)
const ApplicationDetails = ({ creditLine, approvedCreditLine }) => {
  const [remark, setRemark] = useState("");
  const [remarks, setRemarks] = useState(creditLine.remarks || []);
  const [loading, setLoading] = useState(false);

  const subscription = useRef(null);

  useState(() => {
    setRemarks(creditLine.remarks);
  }, [creditLine]);

  const handleStateChange = useCallback(() => {
    const state = Loans.store.getState().loans;
    if (
      state.actionSucceeded &&
      String(state.actionSucceeded)?.includes("addRemark")
    ) {
      setRemarks([
        ...remarks,
        { remark: state.actionSucceeded.split(": ")[1], timestamp: new Date() },
      ]);
      setRemark("");
      setLoading(false);
    }
  }, [remarks]);

  useState(() => {
    console.log("State change");
    if (subscription.current) subscription.current();

    subscription.current = Loans.store.subscribe(handleStateChange);

    return () => subscription.current && subscription.current();
  }, [remark]);

  const handleAddRemark = useCallback(() => {
    Loans.addRemark(creditLine._id, remark);

    setLoading(true);
  }, [remark, creditLine]);

  return (
    <div className="Credit-line">
      <DataCard
        title={
          <EditableDetail
            label="Loan Amount"
            value={creditLine.loanAmount}
            prefix="Rs."
            loanKey="loanAmount"
            onUpdate={(amount) => {
              Loans.updateDetail("loanAmount", +amount);
            }}
          />
        }
        className="Credit-line__loan-amount"
      >
        <h4 className="Credit-line__text">
          {!approvedCreditLine
            ? "Please review this credit line and click on either ‘Approve’, ‘Approve as Loan’ or ‘Reject’."
            : "This credit line has already been approved and is currently active."}
        </h4>
      </DataCard>
      <DataCard
        title="Remarks"
        className="Credit-line__loan-amount"
        style={{ marginTop: "2.5rem" }}
      >
        <ol style={{ fontSize: "1.3rem", marginLeft: "1rem" }}>
          {remarks?.map((remark) => (
            <li key={Math.random().toString(36)}>
              {remark.remark} ({new Date(remark.timestamp).toDateString()})
            </li>
          ))}
          <li>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Add a remark"
                class="Credit-line__add-remark"
                value={remark}
                onChange={({ target: { value } }) => setRemark(value)}
              />
              <button
                style={{
                  outline: "none",
                  border: "none",
                  background: "none",
                  textDecoration: "underline",
                  color: "#00ab55",
                  cursor: !remark || loading ? "not-allowed" : "pointer",
                  marginLeft: "0.75rem",
                  opacity: !remark || loading ? 0.5 : 1,
                }}
                onClick={handleAddRemark}
                disabled={!remark || loading}
              >
                Add
              </button>
            </div>
          </li>
        </ol>
      </DataCard>
      <Grid className="Credit-line__grid" cols={2} rows={4}>
        <DataCard title="Aadhaar Card">
          <h4 className="Credit-line__status">Unverified</h4>
          <MaskedDetail detail={creditLine.aadhaarCardNumber} />
          {/* <h2 className="card-value">{`${creditLine.aadhaarCardNumber.slice(
            0,
            2
          )}${"X".repeat(6)}${creditLine.aadhaarCardNumber.slice(
            -3,
            creditLine.aadhaarCardNumber.length
          )}`}</h2> */}
          <img
            src={creditLine.aadhaarScan?.front}
            alt=""
            className="Credit-line__card-img"
            onClick={() => {
              openLink([
                creditLine.aadhaarScan?.front,
                creditLine.aadhaarScan?.back,
              ]);
            }}
          />
        </DataCard>
        <DataCard title="PAN Card">
          <h4 className="Credit-line__status Credit-line__status--verified">
            Verified
          </h4>
          <h2 className="card-value">
            <MaskedDetail detail={creditLine.panCardNumber} />
          </h2>
          <img
            src={creditLine.panScan}
            alt=""
            className="Credit-line__card-img"
            onClick={() => {
              openLink(creditLine.panScan);
            }}
          />
        </DataCard>
        <DataCard title="Annual Income">
          <h4 className="Credit-line__status Credit-line__status--finance">
            Financial
          </h4>
          <h2 className="card-value">
            ₹{" "}
            {creditLine.annualIncome
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
          </h2>
        </DataCard>
        <DataCard title="Land Holding">
          <h4 className="Credit-line__status Credit-line__status--asset">
            Asset
          </h4>
          <h4 className="Credit-line__detail-text">
            <EditableDetail
              label="Survey Number"
              value={creditLine.satbaraNumber || "NA"}
              loanKey="satbaraNumber"
            />
            {/* <b>Survey Number</b>: {creditLine.satbaraNumber || "NA"} */}
          </h4>
          <Accordion
            accordions={[
              {
                value: "TOTAL_LAND_HOLDING",
                heading: `${creditLine.landDetails.total} acres`,
                detail: (
                  <>
                    <h4 className="Credit-line__detail-text">
                      <EditableDetail
                        label="Leased land area"
                        value={creditLine.landDetails.leased}
                        suffix="acres"
                        loanKey="landDetails.leased"
                        onUpdate={(leased) => {
                          Loans.updateDetail(
                            "landDetails.total",
                            +leased +
                              (creditLine.landDetails.total -
                                creditLine.landDetails.leased)
                          );
                        }}
                      />
                    </h4>
                    <h4 className="Credit-line__detail-text">
                      <EditableDetail
                        label="Shared land area"
                        value={creditLine.landDetails.shared}
                        suffix="acres"
                        loanKey="landDetails.shared"
                        onUpdate={(shared) => {
                          Loans.updateDetail(
                            "landDetails.total",
                            +shared +
                              (creditLine.landDetails.total -
                                creditLine.landDetails.shared)
                          );
                        }}
                      />
                    </h4>
                    <h4 className="Credit-line__detail-text">
                      <EditableDetail
                        label="Owned land area"
                        value={creditLine.landDetails.owned}
                        suffix="acres"
                        loanKey="landDetails.owned"
                        onUpdate={(owned) => {
                          Loans.updateDetail(
                            "landDetails.total",
                            +owned +
                              (creditLine.landDetails.total -
                                creditLine.landDetails.owned)
                          );
                        }}
                      />
                    </h4>
                  </>
                ),
              },
            ]}
          />
        </DataCard>
        <DataCard title="Active Loans" gridColumn="1 / span 2">
          <h4 className="Credit-line__status Credit-line__status--liabilities">
            Liabilities
          </h4>
          <Accordion
            accordions={creditLine.activeLoans?.map((activeLoan) => {
              return {
                value: uuid(),
                heading: `₹ ${activeLoan.loanAmount
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}`,
                detail: (
                  <>
                    <h4 className="Credit-line__detail-text">
                      <b>Facilities</b>: {activeLoan.facilities}
                    </h4>
                    <h4 className="Credit-line__detail-text">
                      <b>Lender bank</b>: {activeLoan.lenderBank}
                    </h4>
                    <h4 className="Credit-line__detail-text">
                      <b>Loan type</b>: {activeLoan.type}
                    </h4>
                    <h4 className="Credit-line__detail-text">
                      <b>Overdues</b>:{" ₹ "}
                      {activeLoan.overdues
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ", ") || 0}
                    </h4>
                    <h4 className="Credit-line__detail-text">
                      <b>Remaining tenure</b>: {activeLoan.remainingTenure || 0}{" "}
                      years
                    </h4>
                  </>
                ),
              };
            })}
          />
        </DataCard>
        <DataCard title="Current Crop">
          <h4 className="Credit-line__status Credit-line__status--crop">
            CROP
          </h4>
          <h2 className="card-value">
            <Accordion
              accordions={[
                {
                  heading: creditLine.currentCrop?.crop?.name,
                  value: "CURRENT_CROP",
                  detail: (
                    <>
                      <h4 className="Credit-line__detail-text">
                        <b>Growth Stage</b>:{" "}
                        {creditLine.currentCrop?.growthStage}
                      </h4>
                      <h4 className="Credit-line__detail-text">
                        <b>Sowing Date</b>:{" "}
                        {new Date(
                          creditLine.currentCrop?.sowingDate
                        ).toDateString()}
                      </h4>
                    </>
                  ),
                },
              ]}
            />
          </h2>
        </DataCard>
        <DataCard title="Previous Crops (last 5 years)">
          <h4 className="Credit-line__status Credit-line__status--crop">
            CROP
          </h4>
          <h2 className="card-value">
            <Accordion
              accordions={creditLine.previousCrops?.map((crop) => {
                return {
                  heading: crop.crop?.name,
                  value: uuid(),
                  detail: (
                    <>
                      <h4 className="Credit-line__detail-text">
                        <b>Annual Produce</b>: {crop.annualProduce} tonnes
                      </h4>
                      <h4 className="Credit-line__detail-text">
                        <b>Duration</b>: {crop.duration} months
                      </h4>
                      <h4 className="Credit-line__detail-text">
                        <b>Total Value</b>: ₹{" "}
                        {crop.totalValue
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
                      </h4>
                      <h4 className="Credit-line__detail-text">
                        <b>Total Profit</b>: ₹{" "}
                        {crop.totalProfit
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ", ")}
                      </h4>
                    </>
                  ),
                };
              })}
            />
          </h2>
        </DataCard>
        <DataCard title="Farmer Details" gridColumn="1 / span 2">
          <h4 className="Credit-line__status Credit-line__status--personal">
            Personal Details
          </h4>
          <EditableDetail
            label="Name"
            value={creditLine.farmerName}
            loanKey="farmerName"
            onUpdate={(farmerName) => {
              Loans.updateDetail("farmerName", farmerName);
            }}
          />
          <EditableDetail
            label="Father's Name"
            value={creditLine.fatherName
              ?.split(" ")
              ?.map((el) => el[0]?.toUpperCase() + el.slice(1))
              .join(" ")}
            loanKey="fatherName"
            onUpdate={(fatherName) => {
              Loans.updateDetail("fatherName", fatherName);
            }}
          />
          <EditableDetail
            label="Phone Number"
            value={creditLine.phone || creditLine.user?.phone}
            loanKey="phone"
          />
          <EditableDetail
            label="Residential Address"
            value={creditLine.residentialAddress}
            loanKey="residentialAddress"
            onUpdate={(address) => {
              Loans.updateDetail("residentialAddress", address);
            }}
          />
          <h4 className="Credit-line__detail-text">
            <b>Village</b>:{" "}
            {creditLine.village?.name ||
              creditLine.village ||
              creditLine.user?.village?.name ||
              creditLine.user?.village}
          </h4>
          <EditableDetail
            label="Date of Birth"
            value={creditLine.dateOfBirth}
            loanKey="dateOfBirth"
            onUpdate={(dob) => {
              Loans.updateDetail("dateOfBirth", dob);
            }}
          />
          <EditableDetail
            label="Educational Qualification"
            value={creditLine.educationalQualification}
            loanKey="educationalQualification"
            onUpdate={(eq) => {
              Loans.updateDetail("educationalQualification", eq);
            }}
          />
          {/* <EditableDetail
            label="Name"
            value={creditLine.farmerName}
            loanKey="farmerName"
          />
          <EditableDetail
            label="Father Name"
            value={creditLine.fatherName
              ?.split(" ")
              .map((el) => el[0].toUpperCase() + el.slice(1))
              .join(" ")}
            loanKey="fatherName"
          />
          <EditableDetail
            label="Phone Number"
            value={creditLine.user?.phone}
            loanKey="phone"
          />
          <EditableDetail
            label="Residential Address"
            value={creditLine.residentialAddress}
            loanKey="residentialAddress"
          />
          <EditableDetail
            label="Village"
            value={creditLine.village?.name || creditLine.user?.village}
            loanKey={
              creditLine.village?.name
                ? "creditLine.village.name"
                : "creditLine.user.village"
            }
          />
          <EditableDetail
            label="Date of Birth"
            value={new Date(creditLine.dateOfBirth).toLocaleDateString()}
            loanKey="dateOfBirth"
          />
          <EditableDetail
            label="Educational Qualification"
            value={creditLine.educationalQualification}
            loanKey="educationalQualification"
          /> */}
        </DataCard>
      </Grid>
    </div>
  );
};

const ApplicationReport = ({
  reportURL,
  creditLineID,
  defaultZoom,
  style = {},
}) => {
  const [priorityTokens, setPriorityTokens] = useState(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const storageID = `priorityTokens__${new Date()
      .toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .reverse()
      .join("-")}`;

    let storedPriorityTokens = localStorage.getItem(storageID);

    if (!storedPriorityTokens) {
      localStorage.setItem(storageID, 5);
      storedPriorityTokens = 5;
    }

    setPriorityTokens(storedPriorityTokens);
  }, []);

  useEffect(() => {
    if (priorityTokens) {
      const storageID = `priorityTokens__${new Date()
        .toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .split("/")
        .reverse()
        .join("-")}`;

      localStorage.setItem(storageID, priorityTokens);
    }
  }, [priorityTokens]);

  return (
    <div className="Credit-line" style={style}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <div style={{ fontSize: "1.5rem", fontFamily: "Be Vietnam" }}>
          {reportURL ? (
            <Viewer
              fileUrl={reportURL}
              plugins={[defaultLayoutPluginInstance]}
              defaultScale={defaultZoom || SpecialZoomLevel.PageFit}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "60rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={window.getImage("illustration_coming_soon.svg")}
                alt="Coming Soon"
              />
              <h2
                style={{
                  fontFamily: "Be Vietnam, sans-serif",
                  fontSize: "2rem",
                  textAlign: "center",
                  fontWeight: "500",
                  margin: "4rem 0 2rem 0",
                }}
              >
                We are currently working on generating an application report for
                this loan application. Meanwhile, you may navigate to the other
                tabs such as 'Application Details'
              </h2>
              <StyledTooltip
                title={
                  JSON.parse(
                    localStorage.getItem("prioritizedLoans") || "[]"
                  )?.includes(creditLineID)
                    ? "This loan is already on priority!"
                    : `You have ${priorityTokens} remaining priority tokens for today`
                }
                placement="right"
              >
                <h3
                  className={`Credit-line__priority-button ${
                    JSON.parse(
                      localStorage.getItem("prioritizedLoans") || "[]"
                    )?.includes(creditLineID)
                      ? "Credit-line__priority-button--disabled"
                      : ""
                  }`}
                  onClick={() => {
                    setPriorityTokens(
                      (curPriorityTokens) => curPriorityTokens - 1
                    );
                    const currentPriorityLoans = JSON.parse(
                      localStorage.getItem("prioritizedLoans") || "[]"
                    );
                    currentPriorityLoans.push(creditLineID);
                    localStorage.setItem(
                      "prioritizedLoans",
                      JSON.stringify(currentPriorityLoans)
                    );
                  }}
                >
                  Set as Priority
                </h3>
              </StyledTooltip>
            </div>
          )}
        </div>
      </Worker>
    </div>
  );
};

const UploadSatbara = ({ creditLine, updateSatbaraScan }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    const storageRef = storage.ref();

    const fileRef = storageRef.child(
      `${creditLine.panCardNumber}/${Math.random()
        .toString(36)
        .slice(2)}__${Math.floor(+new Date() / 1000)}`
    );

    setLoading(true);

    await fileRef.put(file);
    const downloadUrl = await fileRef.getDownloadURL();
    console.log(downloadUrl);

    updateSatbaraScan(downloadUrl);

    setLoading(false);
  }, [creditLine.panCardNumber, file, updateSatbaraScan]);

  return (
    <div className="Credit-line" style={{ paddingTop: "3rem" }}>
      <div style={{ width: "100%", height: "6rem" }}></div>
      <label
        htmlFor="satbaraUpload"
        style={{ marginTop: "3rem", fontSize: "2rem" }}
      >
        Upload Digital Satbara
      </label>
      <br />
      <input
        type="file"
        id="satbaraUpload"
        accept=".pdf"
        onChange={(event) => {
          setFile(event.target.files[0]);
        }}
      />
      <br />
      <br />
      <br />
      <br />
      <PrimaryButton disabled={!file} onClick={handleSubmit} secondary>
        {loading ? <CircularProgress /> : "Upload"}
      </PrimaryButton>
    </div>
  );
};

const SatbaraViewer = ({ creditLine }) => {
  const [satbara, setSatbara] = useState({
    num: 0,
    url: creditLine.digitalSatbaras[0],
  });

  useEffect(() => {
    if (!satbara.url)
      setSatbara({ num: 0, url: creditLine.digitalSatbaras[0] });
  }, [satbara, creditLine]);

  const changeSatbara = useCallback(
    (change) => {
      if (change === 1 && satbara.num < creditLine.digitalSatbaras.length - 1) {
        setSatbara({
          num: satbara.num + 1,
          url: creditLine.digitalSatbaras[satbara.num + 1],
        });
      } else if (satbara.num > 0) {
        setSatbara({
          num: satbara.num - 1,
          url: creditLine.digitalSatbaras[satbara.num - 1],
        });
      }
    },
    [satbara, creditLine.digitalSatbaras]
  );

  const deleteCurrentSatbara = useCallback(() => {
    const newSatbaras = creditLine.digitalSatbaras.filter(
      (_, i) => i !== satbara.num
    );

    Loans.updateActiveLoan({
      _id: creditLine._id,
      digitalSatbaras: newSatbaras,
    });
  }, [satbara, creditLine.digitalSatbaras, creditLine._id]);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            width: "95%",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              background: "transparent",
              outline: "none",
              border: "none",
              textDecoration: "underline",
              color: "#00AA55",
              cursor: "pointer",
            }}
            onClick={() => changeSatbara(-1)}
          >
            Previous
          </button>
          <button
            style={{
              background: "transparent",
              outline: "none",
              border: "none",
              textDecoration: "underline",
              color: "#00AA55",
              cursor: "pointer",
            }}
            onClick={deleteCurrentSatbara}
          >
            Delete Satbara
          </button>
          <button
            style={{
              background: "transparent",
              outline: "none",
              border: "none",
              textDecoration: "underline",
              color: "#00AA55",
              cursor: "pointer",
            }}
            onClick={() => changeSatbara(1)}
          >
            Next
          </button>
        </div>
        <ApplicationReport
          reportURL={satbara?.url || null}
          creditLineID={creditLine._id}
          defaultZoom={1.5}
          style={{ marginTop: 0 }}
        />
      </div>
    </>
  );
};

class CreditLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditLine: null,
      creditLineRejected: false,
      creditLineApproved: false,
      creditLineApprovedLoan: false,
      alertMessage: "",
      modalOpen: false,
      additionalDetails: {
        expiryDate: null,
        noCostAllocation: false,
        sentToBanks: [],
      },
      tab: 0,
      sentToModal: false,
      banks: [],
    };
    this.handleReject = this.handleReject.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleApproveAsLoan = this.handleApproveAsLoan.bind(this);
    this.approvedCreditLine = this.props.location.search === "?active=true";
    this.handleShortlist = this.handleShortlist.bind(this);
    this.handleSent = this.handleSent.bind(this);
    this.handleSentSubmit = this.handleSentSubmit.bind(this);
    this.handleSaveDetails = this.handleSaveDetails.bind(this);
    this.generateLoanReport = this.generateLoanReport.bind(this);
  }

  async handleReject() {
    Loans.rejectLoan(this.state.creditLine._id);
    // const creditLineId =
    //   this.props.match.params.creditLineId +
    //   (this.props.location?.search ? this.props.location.search : "");
    // const creditLineRef = db
    //   .collection("credit_lines_in_review")
    //   .doc(this.state.creditLine.user_id)
    //   .collection("applications")
    //   .doc(creditLineId);
    // await creditLineRef.update({
    //   rejected: true,
    // });

    // this.setState({
    //   creditLineRejected: true,
    //   alertMessage: "This credit line has been successfully rejected!",
    // });

    // setTimeout(() => {
    //   this.props.history.push("/creditlines");
    // }, 3000);
  }

  async handleSent() {
    this.setState({
      modalOpen: true,
      sentToModal: true,
    });
    const requestOptions = await constructRequest({
      authorized: true,
      resource: "banks",
      query: {
        fields: "name",
      },
    });

    const response = await fetch(requestOptions.url, requestOptions);
    const result = await response.json();

    console.log(result);

    this.setState({
      banks: result,
    });
    // Loans.sentLoan(this.state.creditLine._id);
  }

  async handleSentSubmit() {
    Loans.sentLoan(
      this.state.creditLine._id,
      this.state.additionalDetails.sentToBanks
    );
  }

  async handleApprove() {
    Loans.approveLoan(this.state.creditLine._id);
    // const idToken = await auth.currentUser.getIdToken(true);
    // const { creditLine } = this.state;
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // const raw = JSON.stringify({
    //   for: creditLine.user_id,
    //   ...creditLine,
    //   active: true,
    //   amount: creditLine.loan_amount,
    //   end_date: this.state.additionalDetails.expiryDate,
    //   token: idToken,
    // });
    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    // console.log(
    //   JSON.stringify(
    //     {
    //       for: creditLine.user_id,
    //       ...creditLine,
    //       active: true,
    //       amount: creditLine.loan_amount,
    //       end_date: this.state.additionalDetails.expiryDate,
    //     },
    //     null,
    //     2
    //   )
    // );
    // fetch(
    //   "https://us-central1-greensat-9087a.cloudfunctions.net/generateCreditLineHTTP",
    //   requestOptions
    // )
    //   .then(
    //     async function (r) {
    //       const result = r.json();
    //       if (String(result.status).startsWith("2"))
    //         throw new Error({
    //           message: result.error.message || "",
    //         });
    //       const creditLineRef = db
    //         .collection("credit_lines_in_review")
    //         .doc(creditLine.user_id)
    //         .collection("applications")
    //         .doc(creditLine.credit_line_id);
    //       await creditLineRef.update({
    //         approved: true,
    //       });
    //       const newCreditLineRef = db
    //         .collection("credit_lines")
    //         .doc(creditLine.user_id)
    //         .collection("credit_lines")
    //         .doc(creditLine.credit_line_id);
    //       await newCreditLineRef.update({
    //         no_cost_allocation: this.state.additionalDetails.noCostAllocation,
    //       });
    //       this.setState((curState) => ({
    //         ...curState,
    //         modalOpen: false,
    //         creditLineApproved: {
    //           success: true,
    //         },
    //         alertMessage: "Credit line approved successfully!",
    //         alertSeverity: "success",
    //       }));
    //     }.bind(this)
    //   )
    //   .catch(
    //     function (err) {
    //       // alert(err.message);
    //       this.setState((curState) => ({
    //         ...curState,
    //         creditLineApproved: {
    //           success: false,
    //         },
    //         alertMessage:
    //           "There was an error approving the credit line! Please contact GreenSat!",
    //         alertSeverity: "error",
    //       }));
    //     }.bind(this)
    //   );
  }

  async handleShortlist() {
    console.log("SHORTLISTING!");

    Loans.shortlistLoan(this.state.creditLine._id);

    // const { creditLine } = this.state;

    // const creditLineRef = db
    //   .collection("credit_lines_in_review")
    //   .doc(creditLine.user_id)
    //   .collection("applications")
    //   .doc(creditLine.credit_line_id);
    // await creditLineRef.update({
    //   shortlisted: true,
    // });

    // const emailRef = db
    //   .collection("alert_emails")
    //   .doc(`${creditLine.credit_line_id}__${Number(new Date())}`);
    // await emailRef.set({
    //   to: [
    //     "aarush.yadav@greensat.in",
    //     "deepak.yadav@greensat.in",
    //     "krishna.ghosh@greensat.in",
    //   ],
    //   message: {
    //     subject: "Credit Line Shortlisted",
    //     html: `
    //       ${this.user?.name} from ${this.user?.bank_name} has shortlisted a credit line application by ${creditLine.farmer_name}.

    //       This is an automated message sent from <b>aarush.yadav@greensat.in</b>
    //     `,
    //   },
    // });

    // this.setState({
    //   alertMessage: "Shortlisted Proposal!",
    //   alertSeverity: "success",
    //   creditLineApproved: {
    //     success: true,
    //   },
    // });
  }

  async handleApproveAsLoan() {
    const { creditLine } = this.state;

    const creditLineRef = db
      .collection("credit_lines_in_review")
      .doc(creditLine.user_id)
      .collection("applications")
      .doc(creditLine.credit_line_id);
    await creditLineRef.update({
      approved: true,
    });

    this.setState((curState) => ({
      ...curState,
      modalOpen: false,
      creditLineApprovedLoan: true,
      alertMessage: "Credit line approved as a loan!",
      alertSeverity: "success",
    }));
  }

  handleSaveDetails() {
    Loans.updateActiveLoan(Loans.store.getState().loans.activeLoan);
  }

  handleInputChange(evt) {
    this.setState((curState) => ({
      ...curState,
      additionalDetails: {
        expiryDate: evt.target.value,
      },
    }));
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  handleModalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  async componentDidMount() {
    const creditLineId = this.props.match.params.creditLineId;

    Loans.fetchLoanDetails(creditLineId);

    this.unsubscribe = Loans.store.subscribe(() => {
      const state = Loans.store.getState();
      if (state.loans?.activeLoan) {
        this.setState({ creditLine: state.loans.activeLoan });
      }
      if (
        state.loans?.actionSucceeded &&
        !String(state.loans?.actionSucceeded)?.includes("addRemark")
      ) {
        Loans.setActionSucceeded(false);
        this.props.history?.push("/creditlines");
      }
    });

    // console.log(this.props.location?.search?.endsWith("shortlisted=true"));

    // const creditLines = JSON.parse(
    //   localStorage.getItem(
    //     this.props.location?.search?.endsWith("shortlisted=true")
    //       ? "shortlistedCreditLines"
    //       : "creditLines"
    //   )
    // );
    // const creditLine = creditLines.find(
    //   (el) => el.credit_line_id === creditLineId
    // );

    // if (Number.isNaN(Number(creditLine.aadhar_card_number))) {
    //   const decryptedAadhar = await functions.httpsCallable(
    //     "decryptBankingData"
    //   )({
    //     encrypted: creditLine.aadhar_card_number,
    //   });
    //   if (decryptedAadhar.data.data?.decrypted) {
    //     creditLine.aadhar_card_number = decryptedAadhar.data.data.decrypted;
    //   }
    //   const decryptedPan = await functions.httpsCallable("decryptBankingData")({
    //     encrypted: creditLine.panCardNumber,
    //   });
    //   if (decryptedPan.data.data?.decrypted) {
    //     creditLine.panCardNumber = decryptedPan.data.data.decrypted;
    //   }
    // }

    // this.setState({ creditLine });

    this.user = await getUser();
    localStorage.setItem("bankerUser", JSON.stringify(this.user));
    this.setState({ bankLogoUrl: this.user.bank_logo_url });
    localStorage.setItem("bankLogoUrl", this.user.bank_logo_url);
  }

  async generateLoanReport() {
    this.setState({ generationLoading: true });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const loan = { ...this.state.creditLine };
    loan.digitalSatbaras = loan.digitalSatbaras.map((satbara) =>
      typeof satbara === "string" ? { url: satbara, number: "" } : satbara
    );

    const raw = JSON.stringify(loan);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://greensat-report-generator-tp45dve65a-uc.a.run.app/loanApplicationReport",
      requestOptions
    );
    const result = await response.blob();

    const blobURL = URL.createObjectURL(result);

    if (result.type === "application/json") {
      return openLink(blobURL);
    }

    downloadLink(
      blobURL,
      `${this.state.creditLine?.farmerName
        .split(" ")
        .map(
          (name) =>
            (name[0]?.toUpperCase() || "-") +
            (name.slice(1)?.toLowerCase() || "")
        )
        .join(" ")} Loan Application Report.pptx`
    );

    const satbaraBlobURLs = [];

    await Promise.all(
      loan.digitalSatbaras.map(async (satbara, i) => {
        const link = typeof satbara === "string" ? satbara : satbara.url;
        const name =
          typeof satbara === "string"
            ? `Satbara${i}.pdf`
            : `${satbara.number || `Satbara${i}`}.pdf`;

        const response = await fetch(link);
        const result = await response.blob();
        const blob = URL.createObjectURL(result);
        satbaraBlobURLs.push({ url: blob, name });

        downloadLink(blob, name);
      })
    );

    if (loan.creditReport) {
      console.log(loan.creditReport);

      const markup = await functions.httpsCallable("generateCreditReport")({
        creditReportXML: loan.creditReport,
      });

      const response = await functions.httpsCallable("htmlToPdf")({
        markup: markup.data.data.creditReport
          .replace("{{", "")
          .replace("}}", ""),
      });

      const url = response.data.data.url;
      console.log(url);

      if (url) {
        downloadLink(url, `${loan.farmerName} Credit Report.pdf`);
      }
    }

    // const urls = [
    //   {
    //     url: blobURL,
    //     name: `${this.state.creditLine?.farmerName
    //       .split(" ")
    //       .map(
    //         (name) =>
    //           (name[0]?.toUpperCase() || "-") +
    //           (name.slice(1)?.toLowerCase() || "")
    //       )
    //       .join(" ")} Loan Application Report.pptx`,
    //   },
    //   ...satbaraBlobURLs,
    // ];

    // let count = 0;
    // urls.forEach(function (url, i) {
    //   // loading a file and add it in a zip file
    //   JSZipUtils.getBinaryContent(url.url, function (err, data) {
    //     if (err) {
    //       throw err; // or handle the error
    //     }
    //     zip.file(url.name, data, {
    //       binary: true,
    //     });
    //     count++;
    //     if (count === urls.length) {
    //       zip.generateAsync({ type: "blob" }).then(function (content) {
    //         saveAs(
    //           content,
    //           loan.farmerName
    //             .split(" ")
    //             .map(
    //               (name) =>
    //                 (name[0]?.toUpperCase() || "-") +
    //                 (name.slice(1)?.toLowerCase() || "")
    //             )
    //             .join(" ")
    //         );
    //       });
    //     }
    //   });
    // });
    this.setState({ generationLoading: false });
  }

  getSelectStyles(name, bankName) {
    return {
      fontWeight: bankName.indexOf(name) === -1 ? 500 : 600,
    };
  }

  render() {
    if (!this.state.creditLine) {
      return (
        <Protect>
          <Sidebar />
          <ContentWraper>
            <CircularProgress />
          </ContentWraper>
        </Protect>
      );
    }

    const { creditLine } = this.state;

    return (
      <Protect>
        <Sidebar />
        <ContentWraper>
          {(this.state.creditLineRejected ||
            (this.state.creditLineApproved &&
              this.state.creditLineApproved.success) ||
            this.state.creditLineApprovedLoan) && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={true}
              autoHideDuration={1000}
              message="Hello World!"
              key="CreditLineSnackbarError"
              id="CreditLineSnackbarError"
              className="LoginStatusSnackbar"
            >
              <Alert
                severity={this.state.alertSeverity || "error"}
                onClose={function () {
                  this.setState({
                    creditLineRejected: false,
                    creditLineApproved: false,
                    creditLineApprovedLoan: false,
                  });
                }.bind(this)}
              >
                {this.state.alertMessage}
              </Alert>
            </Snackbar>
          )}
          <Dialog
            open={this.state.modalOpen || false}
            onClose={this.handleModalClose}
          >
            <DialogTitle>
              {this.state.sentToModal
                ? "Loan - Sent to Bank"
                : "Credit Line - Additional Details"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please fill in these additional details for{" "}
                {this.state.sentToModal
                  ? "updating the MIS"
                  : "approving the credit line"}
                .
              </DialogContentText>
              <form action="">
                {this.state.sentToModal ? (
                  <>
                    <Select
                      multiple
                      value={this.state.additionalDetails.sentToBanks}
                      input={<OutlinedInput label="Banks" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                      onChange={function ({ target: { value } }) {
                        this.setState({
                          additionalDetails: {
                            ...this.state.additionalDetails,
                            sentToBanks:
                              typeof value === "string"
                                ? value.split(",")
                                : value,
                          },
                        });
                      }.bind(this)}
                    >
                      {this.state.banks.map((bank) => (
                        <MenuItem
                          key={bank._id}
                          value={bank._id}
                          style={this.getSelectStyles(
                            bank.name,
                            this.state.additionalDetails.sentToBanks
                          )}
                        >
                          {bank.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  <>
                    <TextField
                      autoFocus
                      fullWidth
                      type="date"
                      margin="dense"
                      variant="outlined"
                      label="Credit Line Expiry Date"
                      required
                      name="expiryDate"
                      onChange={this.handleInputChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.additionalDetails.expiryDate}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            this.state.additionalDetails.noCostAllocation
                          }
                          onChange={({ target: { checked } }) => {
                            this.setState({
                              additionalDetails: {
                                ...this.state.additionalDetails,
                                noCostAllocation: checked,
                              },
                            });
                          }}
                        />
                      }
                      label="Approve without cost allocation"
                    />
                  </>
                )}
              </form>
            </DialogContent>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "0 1.7rem",
              }}
            >
              <DialogActions>
                <SecondaryButton
                  onClick={this.handleModalClose}
                  color="inherit"
                  bg
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={
                    this.state.sentToModal
                      ? this.handleSentSubmit
                      : this.handleApprove
                  }
                  variant="contained"
                  style={{
                    marginLeft: "2rem",
                  }}
                >
                  {this.state.sentToModal
                    ? "Update Loan Status"
                    : "Approve as Credit Line"}
                </PrimaryButton>
              </DialogActions>
            </div>
          </Dialog>
          {!this.approvedCreditLine && (
            <>
              <div className="Credit-line__action-bar">
                <div className="Credit-line__approve-buttons">
                  {this.props.location?.search?.endsWith("shortlisted=true") ? (
                    <>
                      <PrimaryButton
                        style={{
                          marginRight: "2rem",
                          alignItems: "center",
                        }}
                        onClick={this.handleApprove}
                      >
                        Approve
                      </PrimaryButton>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "70vw",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "1rem" }}>
                          <PrimaryButton
                            style={{
                              marginRight: "2rem",
                              alignItems: "center",
                            }}
                            onClick={this.handleShortlist}
                          >
                            Shortlist
                            <BookmarkIcon
                              style={{
                                fontSize: "2rem",
                                marginLeft: "1rem",
                              }}
                            />
                          </PrimaryButton>
                        </div>
                        {this.user?.loan_forwarder ? (
                          <PrimaryButton
                            id="reject"
                            secondary
                            className="Credit-line__reject"
                            onClick={this.handleSent}
                          >
                            Sent to Bank
                          </PrimaryButton>
                        ) : (
                          <PrimaryButton
                            id="reject"
                            secondary
                            className="Credit-line__reject"
                            onClick={this.handleReject}
                          >
                            Reject
                          </PrimaryButton>
                        )}
                      </div>
                      <div>
                        <SecondaryButton
                          className="Credit-line__approve-as-loan"
                          bg
                          onClick={this.generateLoanReport}
                          style={{ marginRight: "1rem" }}
                        >
                          {this.state.generationLoading ? (
                            <CircularProgress />
                          ) : (
                            "Generate Report"
                          )}
                        </SecondaryButton>
                        <SecondaryButton
                          className="Credit-line__approve-as-loan"
                          bg
                          onClick={this.handleSaveDetails}
                        >
                          Save Details
                        </SecondaryButton>
                      </div>
                      {/* <PrimaryButton
                        id="reject"
                        secondary
                        className="Credit-line__reject"
                        onClick={this.handleReject}
                      >
                        Request Re-upload
                      </PrimaryButton> */}
                    </div>
                  )}
                  <div style={{ height: "100%", width: "2rem" }} />
                  {this.user?.layout?.only_shortlist !== true ? (
                    <>
                      <PrimaryButton
                        onClick={this.handleModalOpen}
                        style={{
                          marginRight: "3.5rem",
                        }}
                      >
                        Approve as Credit Line
                      </PrimaryButton>
                      <StyledTooltip
                        title="Approving as a loan does not generate a QR code for the farmer. Transferring of money has to be handled by the bank."
                        placement="right"
                      >
                        <div>
                          <SecondaryButton
                            className="Credit-line__approve-as-loan"
                            bg
                            onClick={this.handleApproveAsLoan}
                          >
                            Approve as Loan
                          </SecondaryButton>
                        </div>
                      </StyledTooltip>
                    </>
                  ) : (
                    this.props.location?.search?.endsWith(
                      "shortlisted=true"
                    ) && (
                      <>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: "1rem" }}>
                            <PrimaryButton
                              id="reject"
                              secondary
                              className="Credit-line__reject"
                              onClick={this.handleReject}
                            >
                              Reject
                            </PrimaryButton>
                          </div>
                          <SecondaryButton
                            className="Credit-line__approve-as-loan"
                            bg
                            onClick={() => {
                              var count = 0;
                              var zipFilename = `${creditLine.farmerName}__Application.zip`;
                              // const urls = .map((transaction) => ({
                              //   url: transaction.invoice,
                              //   name: `${transaction.transaction_short_id}__${new Date(
                              //     transaction.date.seconds * 1000
                              //   ).toDateString()}.pdf`,
                              // }));

                              const urls = [
                                {
                                  url: creditLine.applicationReport,
                                  name: "ApplicationReport.pdf",
                                },
                                {
                                  url: creditLine.aadhaarScan?.front,
                                  name: creditLine.aadhaarScan?.front.includes(
                                    "jpeg"
                                  )
                                    ? "AadhaarFront.jpeg"
                                    : creditLine.aadhaarScan?.front.includes(
                                        "jpg"
                                      )
                                    ? "AadhaarFront.jpg"
                                    : "AadhaarFront.png",
                                },
                                {
                                  url: creditLine.aadhaarScan?.back,
                                  name: creditLine.aadhaarScan?.back.includes(
                                    "jpeg"
                                  )
                                    ? "AadhaarBack.jpeg"
                                    : creditLine.aadhaarScan?.back.includes(
                                        "jpg"
                                      )
                                    ? "AadhaarBack.jpg"
                                    : "AadhaarBack.png",
                                },
                                {
                                  url: creditLine.panScan,
                                  name: creditLine.panScan?.includes("jpeg")
                                    ? "PAN_Scan.jpeg"
                                    : creditLine.panScan?.includes("jpg")
                                    ? "PAN_Scan.jpg"
                                    : "PAN_Scan.png",
                                },
                                {
                                  url: creditLine.satbaraScan,
                                  name: "Satbara.pdf",
                                },
                              ];

                              if (window.mainProcess?.downloadInvoices) {
                                window.mainProcess.downloadInvoices(
                                  urls,
                                  "",
                                  zipFilename
                                );
                              } else {
                                urls.forEach(function (url) {
                                  // loading a file and add it in a zip file
                                  JSZipUtils.getBinaryContent(
                                    url.url,
                                    function (err, data) {
                                      if (err) {
                                        throw err; // or handle the error
                                      }
                                      zip.file(url.name, data, {
                                        binary: true,
                                      });
                                      count++;
                                      if (count === urls.length) {
                                        zip
                                          .generateAsync({ type: "blob" })
                                          .then(function (content) {
                                            saveAs(content, zipFilename);
                                          });
                                      }
                                    }
                                  );
                                });
                              }
                            }}
                          >
                            Download ZIP
                          </SecondaryButton>
                        </div>
                      </>
                    )
                  )}
                </div>
                {this.user?.layout?.only_shortlist !== true && (
                  <PrimaryButton
                    id="reject"
                    secondary
                    className="Credit-line__reject"
                    onClick={this.handleReject}
                  >
                    Reject
                  </PrimaryButton>
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 120,
                  paddingLeft: 50,
                  paddingBottom: 10,
                  zIndex: 5,
                  width: "100%",
                  display: "flex",
                  // justifyContent: "center",
                  backgroundColor: "#fff",
                  boxShadow: "rgba(#d9d9d9, 0.64) 0 4px 1.1rem",
                }}
              >
                <Tabs
                  value={this.state.tab}
                  onChange={(event, newTab) => {
                    this.setState({
                      tab: newTab,
                    });
                  }}
                  aria-label="Credit Line Tabs"
                  style={{ overflow: "scroll", maxWidth: "75vw" }}
                >
                  <Tab
                    label="Application Details"
                    style={{
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  />
                  <Tab
                    label="Application Report"
                    style={{
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  />
                  <Tab
                    label="Land Certificate"
                    style={{
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  />
                  <Tab
                    label="Field Visit"
                    style={{
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  />
                  <Tab
                    label="Financial Details"
                    style={{
                      fontSize: "14px",
                      marginLeft: "30px",
                    }}
                  />
                </Tabs>
              </div>
            </>
          )}
          {/* <TabPanel value={this.state.tab} index={0}>
            <ApplicationReport
              reportURL={creditLine.applicationReport || null}
              creditLineID={creditLine._id}
            />
          </TabPanel> */}
          <TabPanel value={this.state.tab} index={0}>
            <ApplicationDetails
              creditLine={creditLine}
              approvedCrditLine={this.approvedCreditLine}
            />
          </TabPanel>
          <TabPanel value={this.state.tab} index={1}>
            {creditLine.applicationReport && (
              <ApplicationReport
                reportURL={creditLine.applicationReport || null}
                creditLineID={creditLine._id}
                defaultZoom={1}
              />
            )}
          </TabPanel>
          <TabPanel value={this.state.tab} index={2}>
            <UploadSatbara
              creditLine={creditLine}
              updateSatbaraScan={(satbaraScan) => {
                Loans.updateActiveLoan({
                  _id: creditLine._id,
                  $push: { digitalSatbaras: satbaraScan },
                });
              }}
            />
            {creditLine.digitalSatbaras?.length ? (
              <SatbaraViewer creditLine={creditLine} />
            ) : (
              <h2 style={{ fontSize: "1.5rem" }}>No Satbaras uploaded!</h2>
            )}
          </TabPanel>
          <TabPanel value={this.state.tab} index={3}>
            <FieldVisit creditLine={creditLine} />
          </TabPanel>
          <TabPanel value={this.state.tab} index={4}>
            <FinancialDetails
              aadhaarNumber={`${creditLine.aadhaarCardNumber?.slice(
                0,
                2
              )}${"X".repeat(6)}${creditLine.aadhaarCardNumber?.slice(6)}`}
              creditReportXML={creditLine.creditReport}
            />
          </TabPanel>
        </ContentWraper>
      </Protect>
    );
  }
}

export default withRouter(CreditLine);
