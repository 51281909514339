import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { db, auth } from "../server/index";

class Protect extends Component {
  componentDidMount() {
    this.user = JSON.parse(localStorage.getItem("bankerUser")) || null;
    auth.onAuthStateChanged(async (authUser) => {
      // authUser = { uid: "2loCMNoYP1UcVaFRnCxd7dNyQEi2" }; // Only for testing purposes;

      if (authUser) {
        const bankerExists = !!this.user;
        if (bankerExists) {
          return;
        }

        const bankerRef = db.collection("bankers").doc(authUser.uid);
        const banker = await bankerRef.get();
        if (!banker.exists) {
          auth.signOut();
          this.props.history.push("/login");
          return;
        } else {
          localStorage.setItem("bankerUser", JSON.stringify(banker.data()));
          this.user = banker.data();
        }
      } else {
        this.props.history.push("/login");
        localStorage.removeItem("bankerUser");
        this.user = null;
        return;
      }
    });
  }

  render() {
    return this.props.children;
  }
}

async function getUser() {
  const uid = localStorage.getItem("uid");

  if (!uid) return -1;

  const userRef = db.collection("bankers").doc(uid);
  const user = await userRef.get();

  return user.data();
}

async function getAuthToken() {
  if (!auth.currentUser) {
    return new Promise((res, rej) => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then(res);
        } else {
          res(null);
        }
      });
    });
  }

  const token = await auth.currentUser.getIdToken();

  return token;
}

export default withRouter(Protect);
export { getUser, getAuthToken };
