import { getAuthToken } from "../auth";
import constructUrl from "./constructUrl";

const constructRequest = async (options) => {
  let method;
  switch (options.action) {
    case "FETCH":
      method = "GET";
      break;
    case "CREATE":
      method = "POST";
      break;
    case "UPDATE":
      method = "PATCH";
      break;
    case "DELETE":
      method = "DELETE";
      break;
    default:
      method = options.action || "GET";
      break;
  }

  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const requestOptions = {
    method,
    headers: {},
    redirect: "follow",
    url: constructUrl(options),
  };

  if (options.authorized) {
    const token = await getAuthToken();

    if (!token) return {};

    // headers.append("Authorization", "Bearer " + token);
    // headers.append("Authorization-Type", "firebase-auth");
    requestOptions.headers["Authorization"] = `Bearer ${token}`;
    requestOptions.headers["Authorization-Type"] = "firebase-auth";
  }

  if (options.body) {
    requestOptions.body = options.body;
  }

  return requestOptions;
};

export default constructRequest;
