import React, { Component } from "react";
import styled from "styled-components";

const StyledNavSection = styled.section`
  width: 100%;
  margin-top: 3.3rem;

  & .Sidebar__nav-section-heading {
    font-size: 1.4rem;
    font-family: "Be Vietnam", sans-serif;
    text-transform: uppercase;
    color: rgba(33, 43, 54, 0.85);
    margin-left: 2rem;
    margin-bottom: 2.1rem;
  }

  & .Sidebar__nav-items-container {
    position: relative;
  }

  & .Sidebar__nav-item {
    display: flex;
    align-items: center;
    height: 5.8rem;
    padding: 2.1rem 4.4rem;

    width: 25vw;
    transform: translateX(-2.5rem);

    font-family: "Be Vietnam", sans-serif;
    font-size: 1.4rem;
    text-decoration: none;
    color: #637381;
    font-weight: 500;

    transition: all 0.3s;
  }

  & .Sidebar__nav-item:hover {
    background-color: #f7f7f8;
  }

  & .Sidebar__nav-item--active {
    background-color: rgba(0, 171, 85, 0.08);
    color: #00ab55;
    border-right: 5px solid #00ab55;
  }

  & a.Sidebar__nav-item--active:hover {
    background-color: rgba(0, 171, 85, 0.08);
  }

  & svg {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 1.6rem;
    width: 3rem;
    margin-right: 2.1rem;
  }
`;

class NavSection extends Component {
  render() {
    return <StyledNavSection>{this.props.children}</StyledNavSection>;
  }
}

export default NavSection;
