/*
  loans: Loan[];
  activeLoan: Loan | null;
  shortlistedLoans: Loan[];
  approvedLoans: Loan[];
*/

import _ from "lodash";

const defaultState = {
  loans: JSON.parse(localStorage.getItem("creditLines") || "[]"),
  activeLoan: null,
  shortlistedLoans: JSON.parse(
    localStorage.getItem("shortlistedLoans") || "[]"
  ),
  approvedLoans: JSON.parse(localStorage.getItem("approvedLoans") || "[]"),
  statistics: JSON.parse(localStorage.getItem("loanStatistics") || "{}"),
  actionSucceeded: false,
  rejectedLoans: [],
  sentLoans: [],
  loading: true,
} || { loans: [], activeLoan: null };

const loansReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_LOANS":
      return {
        ...state,
        loans: Array.isArray(action.payload) ? action.payload : state.loans,
      };
    case "SET_SHORTLISTED_LOANS":
      return {
        ...state,
        shortlistedLoans: Array.isArray(action.payload)
          ? action.payload
          : state.shortlistedLoans,
      };
    case "SET_APPROVED_LOANS":
      return {
        ...state,
        approvedLoans: Array.isArray(action.payload)
          ? action.payload
          : state.shortlistedLoans,
      };
    case "SET_SENT_LOANS":
      return {
        ...state,
        sentLoans: Array.isArray(action.payload)
          ? action.payload
          : state.sentLoans,
      };
    case "MOVE_TO_SHORTLISTED":
      return moveLoan(action.payload, state, "loans", "shortlistedLoans");
    case "MOVE_TO_APPROVED":
      return moveLoan(action.payload, state, "loans", "approvedLoans");
    case "MOVE_TO_REJECTED":
      return moveLoan(action.payload, state, "loans", "rejectedLoans");
    case "MOVE_TO_SENT":
      return moveLoan(action.payload, state, "loans", "sentLoans");
    case "REMOVE_LOAN_BY_INDEX":
      let loansCopy = [...state.loans];
      loansCopy.splice(action.payload);

      return { ...state, loans: loansCopy };
    case "REMOVE_LOAN_BY_ID":
      let loans = [...state.loans];
      loans.splice(loans.findIndex((loan) => loan._id === action.payload));

      return { ...state, loans };
    case "SET_ACTIVE_LOAN":
      return { ...state, activeLoan: action.payload };
    case "SET_STATISTICS":
      return { ...state, statistics: action.payload };
    case "ADD_APPROVED_LOAN":
      return {
        ...state,
        approvedLoans: [
          ...state.approvedLoans,
          ...(Array.isArray(action.payload)
            ? action.payload
            : [action.payload]),
        ],
      };
    case "ADD_SHORTLISTED_LOAN":
      return {
        ...state,
        shortlistedLoans: [
          ...state.shortlistedLoans,
          ...(Array.isArray(action.payload)
            ? action.payload
            : [action.payload]),
        ],
      };
    case "ADD_LOAN":
      return {
        ...state,
        loans: [
          ...state.loans,
          ...(Array.isArray(action.payload)
            ? action.payload
            : [action.payload]),
        ],
      };
    case "SET_ACTION_SUCCEEDED":
      return {
        ...state,
        actionSucceeded: action.payload,
      };
    case "UPDATE_DETAIL":
      const activeLoan = { ...state.activeLoan };
      _.set(activeLoan, action.payload.key, action.payload.value);
      // activeLoan[action.payload.key] = action.payload.value;

      return { ...state, activeLoan };
    default:
      return state;
  }
};

function moveLoan(id, state, from, to) {
  const indexToRemove = state[from].findIndex((loan) => loan._id === id);
  const loanToMove = state[from][indexToRemove];

  const newFrom = [...state[from]];
  newFrom.splice(indexToRemove, 1);

  return {
    ...state,
    [to]: [...(state[to] || []), loanToMove],
    [from]: newFrom,
  };
}

export default loansReducer;
