import React, { Component } from "react";
import "./LogoIcon.css";

class LogoIcon extends Component {
  render() {
    return (
      <img
        src={window.getImage("logo_icon.svg")}
        className="Logo-icon"
        alt="GreenSat Logo Icon"
      />
    );
  }
}

export default LogoIcon;
